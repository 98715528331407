import React, { PureComponent } from 'react';
import {MetaTags} from "react-meta-tags";

import '../../sass/Header.css';
import '../../sass/ArchiveError.scss';

class NotFound extends PureComponent {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Архив уже загружается</title>
                    <meta name="robots" content="noindex"/>
                </MetaTags>
                <div className="content-wrapper">
                    <div className="archive-error-page">
                        <h3> Архив загружается. <br/> Это может занять некоторое время.</h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;