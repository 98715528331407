import React from 'react'

/**
 * Календарь архива
 *
 * @param id камеры
 * @param item данные камеры
 * @param current Дата по-умолчанию
 * @param handleCalendar обработка события календаря
 * @returns {[]}
 */
export const archiveCalendar = (id, item, current, handleCalendar) => {
  const calendar = []
  const weekDays = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']

  let rotate = item.rotate
  rotate = isNaN(rotate) ? 7 : item.rotate

  // if (id) {
  //   if (item.rotate <= 31) {
  //     rotate = item.rotate
  //   }
  // }

  // изменения на случай отсутствия данных data (-1)
  for (let i = 0; i < rotate; i++) {
    let date = new Date()

    date.setDate(date.getDate() - 1 * i)

    let day = date.getDate()

    if (day < 10) {
      day = '0' + day
    }

    let month = date.getMonth() + 1

    if (month < 10) {
      month = '0' + month
    }

    const weekDay = weekDays[date.getDay()]
    const year = date.getFullYear()
    const _current = '' + year + month + day
    const currentClass = (current === 0 && i === 0) || (current !== 0 && _current === current) ? ' current' : ''

    calendar.push(
      <div
        key={i}
        data-id={i}
        id={'calendar_' + i}
        onClick={handleCalendar}
        className={'calendar-item' + currentClass + ' '}
        data-search={'' + year + month + day + ''}
        data-display={day + '.' + month + '.' + year}
      >
        {day + '.' + month} <span>{weekDay}</span>
      </div>
    )
  }

  calendar.reverse()

  return calendar
}
