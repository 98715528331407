import React, {Component} from "react";
import {mdApi} from "../index";
import "../sass/Background.css"
import loadingAnimation from "../images/loading.gif"

export default class BackgroundTheme extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shown: false
        }

        mdApi.onRequestStarted = () => {
            this.setState({shown: true})
        };

        mdApi.onRequestFinished = () => {
            this.setState({shown: false})
        };
    }

    render() {
        return (
            <>
                {
                    this.state.shown &&
                    <div className="simple_background">
                        <img src={loadingAnimation}/>
                    </div>
                }
                {
                    this.props.children
                }
            </>
        )
    }
}