import React from 'react'

/**
 * Блок успешного оповещения
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const NotificationSuccessBlock = (props) => {
    return (
        <div className="success-block">
            <div className="alert alert-success">{props.text}</div>
        </div>
    )
}

export default NotificationSuccessBlock
