/**
 * Редюсер для значения поисковой строки списка публичных камер
 */
import { PUBLIC_CAMS_SEARCH_FETCH_DATA, PUBLIC_CAMS_SEARCH_UPDATE_DATA } from '../actions/publicCamsSearch'

export function publicCamsSearch (state = [], action) {
  switch (action.type) {
    case PUBLIC_CAMS_SEARCH_FETCH_DATA:
      return { value: action.publicCamsSearch ? action.publicCamsSearch : '' }

    case PUBLIC_CAMS_SEARCH_UPDATE_DATA:
      return Object.assign({}, state, { value: action.searchValue })

    default:
      return state
  }
}
