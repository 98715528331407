import React from 'react'
import MetaTags from "react-meta-tags";

const MyCamerasTags = (props) => {
    const  {titleProvider} = props;

    let title = `Мои камеры - смотреть онлайн видеокамеры в своем доме ${titleProvider}.`;
    let canonical = `https://moidom.citylink.pro/cameras`;

    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content="Смотрите камеры онлайн в своём доме. Скачивайте архив записей с камер видеонаблюдения бесплатно."/>
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default MyCamerasTags;


