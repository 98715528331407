import React, {Component} from 'react'

/**
 * Получить код: Инструкция
 */
export class RequestSmsInstruction extends Component {
    render() {
        return (
            <div className="get-code-instruction">
                <div className="main-items">
                    <div className="main-item">
                        <div className="circle-number-wrapper">
                            <div className="circle-number">1</div>
                        </div>
                        Заполните пустые поля:
                        <div className="sub-items">
                            <div className="sub-item">
                                адрес
                                <div>
                                    (укажите до 3-х интересующих вас домов);
                                </div>
                            </div>
                            <div className="sub-item">
                                номер телефона;
                            </div>
                            <div className="sub-item">
                                комментарий.
                            </div>
                        </div>
                    </div>

                    <div className="main-item">
                        <div className="circle-number-wrapper">
                            <div className="circle-number">2</div>
                        </div>
                        Нажмите «Получить код». Код придет по SMS.
                    </div>
                    <div className="main-item">
                        <div className="circle-number-wrapper">
                            <div className="circle-number">3</div>
                        </div>
                        Введите код и нажмите «Готово».
                    </div>
                </div>

                <div className="wrapper-info">
                    <div className="header-info">
                        Важно!
                    </div>
                    <div className="sub-items">
                        <div className="sub-item">
                            Код действителен 3 дня с момента запроса.
                        </div>
                        <div className="sub-item">
                            Архив с камерами по коду доступен за 3 дня.
                        </div>
                        <div className="sub-item">
                            За сутки можно получить записи с камер
                            <div>по 3-м адресам.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RequestSmsInstruction
