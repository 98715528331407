import React, { PureComponent } from 'react'
import {connectionUrl, MOBILE} from '../helpers/constants/HeaderConstants'

import '../sass/Header.css'
import AboutTags from "./Header/Metatags/AboutTags";
import {Link} from "react-router-dom";
import {ABOUT_PATH, FAQ_PATH} from "../routes";

class About extends PureComponent {
  render () {
    if (MOBILE)
      this.props.history.push('/')

    return (
      <div>
        <AboutTags/>
            <div className="about-top-list citylink">
                <div>
                    <p>
                        <b>«Мой Дом»</b>  — это социальный проект «Ситилинка» по установке платных и бесплатных камер наблюдения во дворах, на общественных территориях, в домах и офисах.
                    </p>
                </div>
            </div>
        <div className="content-wrapper">
            <div className="about-page">

                <div>
                    <p>В 2013 году мы решили сделать Петрозаводск безопаснее и поставили первые камеры у
                        железнодорожного вокзала. Сегодня наше видеонаблюдение работает в 7 регионах России: Карелия и
                        Мордовия, Ямало-Ненецкий автономный округ, Санкт-Петербург и Ленинградская область, Мурманская
                        и Архангельская область.
                    </p>
                </div>

                <div>
                <h3>Чем полезны наши камеры?</h3>
                    <ul>
                        <li>вы попали в ДТП и хотите доказать, что не виноваты;</li>
                        <li>виновник аварии скрылся, и вы хотите найти его;</li>
                        <li>неизвестный повредил машину у подъезда, и вам нужно установить его личность;</li>
                        <li>хулиганы разломали детскую площадку у дома, и вы хотите привлечь их к ответу.</li>
                    </ul>
                    <p>А еще по камерам можно следить за играющими во дворе детьми, проверять, есть ли пробки на
                        дорогах, и просто виртуально гулять по городу.
                    </p>
                </div>

                <div>
                    <h3>Какие преимущества есть у проекта «Мой Дом»?</h3>
                    <ul>
                        <li>Камеры работают круглосуточно, вы можете смотреть их онлайн и пользоваться архивом за 7 дней.</li>
                        <li>Трансляции и записи можно смотреть с любого устройства и из любой точки мира.</li>
                        <li>Видео с камер послужит доказательством в правоохранительных органах.</li>
                    </ul>
                </div>

                <h3>Как присоединиться к проекту «Мой Дом»?</h3>

                <p>Для установки камер оставьте заявку на сайте
                    <a href={connectionUrl}
                       target="_blank"
                       rel="noopener noreferrer"> camera.citylink.pro</a>.
                </p>

                <div>
                    <br/><br/>
                    <div className="about-sensors">
                        <div>
                            Ответы на интересующие вас вопросы смотрите <Link to={FAQ_PATH}
                                                                              className="links">здесь</Link>.
                        </div>
                    </div>
                    <br/><br/>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default About
