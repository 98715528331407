import React from 'react';
import {useState, useEffect, useRef} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {IMaskInput} from 'react-imask';
import {ReactComponent as LogoIcon} from "../images/domofon/logo.svg";
import {ReactComponent as CallIcon} from "../images/domofon/call.svg";
import {ReactComponent as ChatIcon} from "../images/domofon/chat.svg";
import '../sass/bootstrap-grid.scss'
import '../sass/domofon.css';

function getWindowSize() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function Domofon() {
    const [phoneRef, setPhoneRef] = useState(null);
    const nameRef= useRef(null);
    const noteRef = useRef(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [noteStatus, setNoteStatus] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [form, setForm] = useState({
        name: "",
        addr: "petrozavodsk",
        phone: "",
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const script = document.createElement('script')

        script.src = '//code.jivosite.com/widget.js'
        script.setAttribute('jv-id', 'UUyvHk6HbE')
        script.async = true

        document.body.appendChild(script)
    }, []);

    const onJivoClick = (e) => {
        e.preventDefault();
        jivo_api.open();
        return false;
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();

        if(isSending) return;

        if(!form.name.trim()) {
            nameRef.current.classList.add('input-error');
            return;
        } else {
            nameRef.current.classList.remove('input-error');
        }

        if(!form.phone.trim() || form.phone.length < 11) {
            phoneRef.classList.add('input-error');
            return;
        } else {
            phoneRef.classList.remove('input-error');
        }

        setIsSending(true);

        const data =  {
            method: "POST",
            body: JSON.stringify(form),
            headers: {
                "Content-type": "application/json"
            }
        }

        try {
            const res = await fetch("https://moidom.citylink.pro/api/?mode=domophone_ticket", data);
            if(res.ok) {
                setModalStatus(true);
            } else {
                throw new Error(e);
            }
        } catch(e) {
            alert("Произошла ошибка, попробуйте повторить позже."); 
        } finally {
            setIsSending(false);
        }
    }

    const onModalOverlayClick = ({target}) => {
        if(target.classList.contains('modal') || target.classList.contains('modal__content-closer')) setModalStatus(false);
    }

    const renderSlider = () => {
        const items = [
            (<div className="about__item" key={0}>
                <div className="about__item-img">
                    <img alt="slide-1" src={require("../images/domofon/slide-1.png").default}/>
                </div>
                <div className="about__item-description">
                    Видеозвонки с домофона приходят в мобильное приложение
                </div>
            </div>),
            (<div className="about__item" key={1}>
                <div className="about__item-img">
                    <img alt="slide-2" src={require("../images/domofon/slide-2.png").default}/>
                </div>
                <div className="about__item-description">
                    Двери в подъезд открываются дистанционно: ключ в телефоне
                </div>
            </div>),
            (<div className="about__item" key={2}>
                <div className="about__item-img">
                    <img alt="slide-3" src={require("../images/domofon/slide-3.png").default}/>
                </div>
                <div className="about__item-description">
                    Принятые и пропущенные звонки в квартиру хранятся в архиве
                </div>
            </div>),
        ];
        
        return (windowSize.width < 994)
            ?
                (<Carousel
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false} 
                    preventMovementUntilSwipeScrollTolerance 
                >
                    {items}
                </Carousel>)
            :
                (<div className="about__row">
                    {items}
                </div>);
    }

	return (
        <div className="domofon">
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12 order-2 order-lg-1 header__title">
                            <h1>Заменим ваш домофон на умный</h1>
                        </div>
                        <div className="col-lg-5 col-sm-12 order-1 order-lg-2 header__logo">
                            <a target="_blank" rel="noreferrer" href="https://citylink.pro/">
                                <LogoIcon/> 
                            </a>
                        </div>
                        <div className="col-lg-8 col-sm-12 order-3 header__description">
                            Умный домофон «Ситилинка» – это современный домофон с управлением в мобильном приложении и круглосуточным видеонаблюдением.
                        </div>
                    </div>
                </div>
            </header>

            <section className="about">
                <div className="container">
                   {renderSlider()}
                </div>
            </section>
            
            <section className="application">
                <div className="container">
                    <div className="application__row">
                        <div className="application__form">
                            <div className="application__form-title">Подайте заявку на бесплатную консультацию</div>
                            <form onSubmit={onSubmitForm}>
                                <input ref={nameRef} type="text" placeholder="Имя" value={form.name} onChange={({target}) => setForm({...form, name: target.value})}/>
                                <select value={form.city} onChange={({target}) => setForm({...form, city: target.value})}>
                                    <option value="petrozavodsk">Петрозаводск</option>
                                    <option value="apatity">Апатиты</option>
                                    <option value="kondopoga">Кондопога</option>
                                    <option value="sortavala">Сортавала</option>
                                    <option value="kostomuksha">Костомукша</option>
                                    <option value="segezha">Сегежа</option>
                                    <option value="nadvoicy">Надвоицы</option>
                                    <option value="medvezhegorsk">Медвежьегорск</option>
                                    <option value="pitkyaranta">Питкяранта</option>
                                    <option value="suojarvi">Суоярви</option>
                                    <option value="polyarnye-zori">Полярные зори</option>
                                    <option value="lahdenpohja">Лахденпохья</option>
                                    <option value="saint-petersburg">Санкт-Петербург</option>  
                                    <option value="kandalaksha">Кандалакша</option> 
                                    <option value="murmansk">Мурманск</option>
                                    <option value="severodvinsk">Северодвинск</option>
                                    <option value="arkhangelsk">Архангельск</option>
                                    <option value="domophone">Саранск</option>
                                </select>
                                <IMaskInput
                                    inputRef={el => setPhoneRef(el)} 
                                    type="tel"
                                    mask={"{8} (000) 000-00-00"}
                                    radix="."
                                    value={form.phone}
                                    unmask={true}
                                    onAccept={(value) => setForm({...form, phone: value})}
                                    placeholder='Телефон'
                                />
                                <div className="application__form-subtitle">
                                    Нажимая на кнопку «Подать заявку», я соглашаюсь на обработку моих персональных данных в соответствии с целями обработки
                                </div>
                                <button className="application__form-submit">Подать заявку</button>
                            </form>
                        </div>
                        <div className="application__img">
                            <img className="application__img-first" alt="form-img-1" src={require("../images/domofon/form-img-1.png").default}/>
                            <img className="application__img-second" alt="form-img-2" src={require("../images/domofon/form-img-2.png").default}/>
                        </div>
                    </div>
                    <div className="application__note" style={noteStatus ? {height: 25 + noteRef.current.offsetHeight} : {height: 25}}>
                        <button className="application__note-btn" onClick={() => setNoteStatus(!noteStatus)}>Примечание</button>
                        <div className="application__note-content" ref={noteRef}>
                            Не оферта. Подключение при наличии технической возможности. Стоимость и существенные условия уточняйте по телефону. Актуально до 31.12.2022.
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer">
                <div className="container">
                   <div className="footer__row">
                        <div className="footer__item">
                            <CallIcon/>
                            <a className="footer__item-call" href="tel:89214555777">8 (921) 4-555-777</a>
                        </div>
                        <div className="footer__item">
                            <ChatIcon/>
                            <a className="footer__item-chat" href="#" onClick={onJivoClick}>Написать в чат</a>
                        </div>
                   </div>
                </div>
            </footer>

            <div className={`modal ${modalStatus ? 'active' : ''}`} onClick={onModalOverlayClick}>
                <div className="modal__content">
                    <div className="modal__content-closer"></div>
                    <div>Спасибо за заявку!</div>
                    <div>Наш специалист свяжется с вами в ближайшее время.</div>
                </div>
            </div>
        </div>
	);
}