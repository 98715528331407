import React, { Component } from 'react'
import NotificationBlock from '../Notifications/NotificationBlock';

import '../../sass/ShareVideo.css';

/**
 * Ошибка
 */
class ErrorBlock extends Component {
    render() {
        return <div className="error-block">
            <NotificationBlock
                showBlock={true}
                errorMessage={'Некорректно указан код, либо истёк срок его действия (1 месяц).'}
            />
        </div>
    }
}

export default ErrorBlock
