import React from 'react'
import MetaTags from "react-meta-tags";

const SosedTags = (props) => {
    const  {titleProvider} = props;

    let title = `Ищу свидетелей происшествия ${titleProvider}.`;
    let canonical = `https://moidom.citylink.pro/sosedi`;

    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content="Здесь вы можете подать обращение на поиск свидетелей происшествия, если оно попало под объективы камер Ситилинка."/>
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default SosedTags;