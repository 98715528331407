import React, {PureComponent} from 'react';
import {BROWSER, MOBILE, currentProviderTitleFormatted} from '../helpers/constants/HeaderConstants'
import MobileContainer from "./Faq/MobileContainer";
import {CitylinkItems} from "./Faq/CitylinkItems";

import '../sass/Faq.css';
import FaqTags from "./Header/Metatags/FaqTags";
import DesktopContainer from "./Faq/DesktopContainer";

/**
 * Страница (Часто задаваемые вопросы) FAQ
 */
export default class Faq extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            itemsList: CitylinkItems,
            anchor:undefined
        }
    }

    componentDidMount() {
        let uri = location.href

        let anchorValue;
        let anchorPos = uri.indexOf("#");

        if(anchorPos !== -1){
            anchorValue = uri.substr(anchorPos+1)
        }

        if(anchorValue !== undefined){
            this.setState({
                anchor:anchorValue.trim()
            })
        }
    }

    render() {
        return (
            <div>
                <FaqTags titleProvider={currentProviderTitleFormatted()}/>
                {
                    BROWSER &&
                    <DesktopContainer
                        anchor={this.state.anchor}
                        items={this.state.itemsList}
                    />
                }
                {
                    MOBILE &&
                    <MobileContainer
                        items={this.state.itemsList}
                    />
                }
            </div>
        );
    }
}

