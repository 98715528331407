import React from 'react'
import {MOBILE} from '../constants/HeaderConstants'
import {mdApi} from "../../index";

/**
 * Список городов провайдера
 *
 * @param provider
 */
export const getPublicCamerasCities = (provider, handleButtonChange) => {
    return mdApi.cities()
        .then(cities => {
            return cities.map((city) => {
                return <li
                    key={city.id}
                    className={MOBILE ? 'mobile-navigate__city-item' : 'content-navigate__city-item'}
                    onClick={() => handleButtonChange(city.key)
                    }>{city.name}</li>
            })
        })
}