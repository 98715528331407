import axios from "axios";

/**
 * Экшен списка публичных камер
 */
export const PUBLIC_CAMS_LIST_FETCH_DATA = 'PUBLIC_CAMS_LIST_FETCH_DATA'
export const PUBLIC_CAMS_LIST_UPDATE_DATA = 'PUBLIC_CAMS_LIST_UPDATE_DATA'
export const PUBLIC_CAMS_LIST_HAS_ERRORED = 'PUBLIC_CAMS_LIST_HAS_ERRORED';
export const PUBLIC_CAMS_LIST_IS_LOADING = 'PUBLIC_CAMS_LIST_IS_LOADING';

export function itemsHasErrored(bool) {
    return {
        type: PUBLIC_CAMS_LIST_HAS_ERRORED,
        hasErrored: bool
    };
}

export function itemsIsLoading(bool) {
    return {
        type: PUBLIC_CAMS_LIST_IS_LOADING,
        isLoading: bool
    };
}

export function itemsFetchDataSuccess(items) {
    return {
        type: PUBLIC_CAMS_LIST_FETCH_DATA,
        items
    };
}


export function itemsUpdateDataSuccess(items) {
    return {
        type: PUBLIC_CAMS_LIST_UPDATE_DATA,
        items
    };
}

export function itemsFetchData(url) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(itemsIsLoading(false));

                return response;
            })
            .then((response) => response.data)
            .then((items) => dispatch(itemsFetchDataSuccess(items)))
            .catch(() => dispatch(itemsHasErrored(true)));
    };
}


export function itemsUpdateData(url) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(itemsIsLoading(false));

                return response;
            })
            .then((response) => response.data)
            .then((items) => dispatch(itemsUpdateDataSuccess(items)))
            .catch(() => dispatch(itemsHasErrored(true)));
    };
}
