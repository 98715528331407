import {ITEMS_FETCH_DATA_SUCCESS} from '../actions/items';
import {ITEMS_UPDATE_DATA_SUCCESS} from '../actions/items';
import {ITEMS_HAS_ERRORED} from '../actions/items';
import {ITEMS_IS_LOADING} from '../actions/items';

export function itemsHasErrored(state = false, action) {
    switch (action.type) {
        case ITEMS_HAS_ERRORED:
            return action.hasErrored;

        default:
            return state;
    }
}

export function itemsIsLoading(state = false, action) {
    switch (action.type) {
        case ITEMS_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function items(state = [], action) {
    let res = [];

    switch (action.type) {
        case ITEMS_FETCH_DATA_SUCCESS:
            if (action.items && action.items !== -1) {
                res = action.items
            }

            return res;
        case ITEMS_UPDATE_DATA_SUCCESS:
            if (action.items && action.items !== -1) {
                res = action.items
            }

            return Object.assign({}, state, res);

        default:
            return state;
    }
}
