import React, {Component} from "react";
import {AppContext} from "./routes";

export default class MenuProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openedWindows: {},
        }

        MenuProvider.contextType = AppContext
    }

    addWindow = (Menu,callback) => {
        let id = Math.round(Math.random() * Number.MAX_SAFE_INTEGER)

        let preparedCallback = callback === undefined ? () => {
            this.closeWindow(id)
        } : () => {
            callback()
            this.closeWindow(id)
        }

        this.setState({
            openedWindows: {
                ...this.state.openedWindows,
                [id]: Menu
            }
        })

        return id
    }

    closeWindow = windowId => {
        let openedWindows = this.state.openedWindows
        delete openedWindows[windowId]

        this.setState({
            openedWindows
        })
    }

    closeAllWindows = () => {
        let windows = this.state.openedWindows
        Object.keys(windows).forEach(windowId => {
            this.closeWindow(windowId)
        })
    }

    componentDidMount() {
        let ctx = this.context
        ctx.addCallback('getMenuProvider', () => {
            return this
        })
    }

    render() {
        return (
            <div>
                {
                    Object.entries(this.state.openedWindows).map(([id, openedWindow]) => {
                        return React.cloneElement(openedWindow, {key: id})
                    })
                }
            </div>
        )
    }
}