import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {BROWSER, connectionUrl, MOBILE} from '../../helpers/constants/HeaderConstants';

import '../../sass/UrCameras.css'
import {mdApi} from "../../index";
import "../../sass/SearchBar.css";
import Select from "../CustomComponents/Select";

export default class AuthorizedUserCameras extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cameras: [],
            sortBy: "default"
        }
    }

    updateCameras = () => {
        this.fetchCameras()
            .then(cameras => {
                this.setState({cameras})
            })
            .catch(() => {
            })
    }

    fetchCameras = () => {
        let sortBy = this.state.sortBy;
        if (sortBy === "default") {
            sortBy = null;
        }
        return mdApi.userCamerasAll(sortBy)
    }

    handleSortBy = (sortBy) => {
        this.setState({
            sortBy: sortBy
        }, this.updateCameras)
    }

    componentDidMount() {
        this.updateCameras()
    }

    render() {
        let cameras = this.state.cameras.map((camera) => {
            return <li className="items-grid__item" key={camera.id}>
                <Link className="items-grid__item_link" to={`/cameras/${camera.id}`}>
                    <img src={camera.img} alt={camera.name}/>
                    <span className="items-name">{camera.name}</span>
                </Link>
                <Link className="items-grid__item_arcLink" to={`/cameras/${camera.id}/archive`}>
                    Архив
                </Link>
                {
                    !MOBILE &&
                    <Link className="items-grid__item_arcLink screen"
                          to={`/cameras/${camera.id}/screen`}>
                        Снимки
                    </Link>
                }
            </li>
        });

        const sortOptions = [
            {
                value: "default",
                label: 'По умолчанию'
            },
            {
                value: "name",
                label: 'По названию'
            }
        ];

        return (
            <div>
                {
                    BROWSER &&
                    <div>
                        <div className="content-wrapper wide">
                            <div className="search-bar">
                                <Select
                                    label={"Сортировка:"}
                                    onChange={this.handleSortBy}
                                    initValue={this.state.sortBy}
                                    values={sortOptions}
                                />
                            </div>
                            <ul className="items-grid">
                                {cameras}
                                <li className="items-grid__item">
                                    <a target="_blank" rel="noopener noreferrer" href={connectionUrl}
                                       className="items-grid__item_outerLink">
                                        <span className="informer cameras">Установить дополнительные камеры видеонаблюдения</span>
                                        <span className="button cameras">Установить</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {
                    MOBILE &&
                    <div>
                        <div className="mobile-wrapper wide ur">
                            <div className="search-bar search-bar_mobile">
                                <Select
                                    label={"Сортировка:"}
                                    onChange={this.handleSortBy}
                                    initValue={this.state.sortBy}
                                    values={sortOptions}
                                />
                            </div>
                            <ul className="items-grid">
                                {cameras}
                                <li className="items-grid__item">
                                    <a target="_blank" rel="noopener noreferrer" href={connectionUrl}
                                       className="items-grid__item_outerLink">
                                    <span
                                        className="informer cameras">Установить дополнительные камеры видеонаблюдения</span>
                                        <span className="button cameras">Установить</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
