import React from 'react'
import MetaTags from "react-meta-tags";

const ArchiveTags = (props) => {
    const  {cameraTitle} = props;

    let content = `${cameraTitle}. Скачивайте архив записей с камер видеонаблюдения за последние 10 дней.`;
    let title = `${cameraTitle} - Скачать архив записей с веб камеры видеонаблюдения`;
    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content={content}/>
            <meta property="og:title" content="Мой Дом"/>
        </MetaTags>
    )
}

export default ArchiveTags;