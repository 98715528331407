import React, {Component} from 'react'
import FaqItem from "../FaqItem";
import * as PropTypes from "prop-types";

/**
 * Контейнер страницы Faq Ситилинк, версии для ПК
 */
export default class DesktopContainer extends Component {
    render() {
        let anchor = this.props.anchor

        return (
            <div className="content-wrapper">
                <div className="faq-page">
                    <div className="faq-wrapper">
                        {this.props.items.map((item, i) =>{
                            let itemId = item.id
                            let opened = itemId !== undefined && anchor !== undefined && itemId === anchor

                            return <FaqItem id={itemId} key={`${i}_${opened}`} title={item.title} descr={item.descr} opened={opened}/>
                        }

                        )}
                    </div>
                </div>
            </div>
        )
    }
}

DesktopContainer.propTypes = {
    anchor: PropTypes.string
}
