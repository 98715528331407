/**
 * Редюсер генерации ссылок быстрого доступа для юрлиц
 */
import {
    QUICK_ACCESS_FOR_UR_FETCH_DATA_SUCCESS as fetch,
    QUICK_ACCESS_FOR_UR_IS_LOADING as loading,
    QUICK_ACCESS_FOR_UR_HAS_ERRORED as errored
} from '../actions/quickAccessForUr'

export function quickAccessUrlIsLoading(state = false, action) {
    switch (action.type) {
        case loading:
            return action.isLoading;

        default:
            return state;
    }
}

export function quickAccessUrlHasErrored(state = false, action) {
    switch (action.type) {
        case errored:
            return action.hasErrored;

        default:
            return state;
    }
}

export function quickAccessUrl(state = [], action) {
    switch (action.type) {
        case fetch:
            let res = {}

            if (action.items.data) {
                res = action.items.data
            }

            return res

        default:
            return state
    }
}
