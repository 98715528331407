import React from 'react'
import MetaTags from "react-meta-tags";

import {CURRENT_PROVIDER} from "../../../helpers/constants/HeaderConstants";

const PublicListTags = (props) => {
    const  {cityUrl, city1, city2} = props;

    let title = `Веб камеры города ${city1} смотреть онлайн в реальном времени | Мой Дом ${CURRENT_PROVIDER}`;
    let content = `Выберите вебкамеру из списка, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2}.`;
    let canonical = cityUrl ? `https://moidom.citylink.pro/publist/${cityUrl}` : `https://moidom.citylink.pro/publist`;

    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content={content}
            />
            <meta property="og:title" content="Мой дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default PublicListTags;


