import { combineReducers } from 'redux';
import { routerReducer } from "react-router-redux"
import { items, itemsHasErrored, itemsIsLoading } from './items';
import { counters } from './counters';
import { region } from './region';
import { points, pointsHasErrored, pointsIsLoading } from './points';
import { publicCamsSearch } from './publicCamsSearch';
import { publicCamsList, itemsIsLoading as publicCamsListIsLoading, itemsHasErrored as publicCamsListHasErrored } from './publicCamsList';
import { quickAccessUrl, quickAccessUrlHasErrored, quickAccessUrlIsLoading } from './quickAccessForUr';

const moidomApp = combineReducers({
    items,
    itemsHasErrored,
    itemsIsLoading,
    counters,
    region,
    points,
    pointsHasErrored,
    pointsIsLoading,
    publicCamsSearch,

    // start списка публичных камер
    publicCamsList,
    publicCamsListIsLoading,
    publicCamsListHasErrored,
    // end списка публичных камер

    quickAccessUrl,
    quickAccessUrlHasErrored,
    quickAccessUrlIsLoading,
    router: routerReducer
})

export default moidomApp
