import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { MOBILE } from "../helpers/constants/HeaderConstants";
import "../sass/Header.css";
import "../sass/Rules.css";

class Privacy extends PureComponent {
    render() {
        return (
            <div>
                <div className={MOBILE ? "mobile-wrapper" : "content-wrapper"}>
                    <div className="about-page">
                        <div className="page">
                            <h2>
                                Политика конфиденциальности Приложения
                                <br />
                                «Ситилинк. Дом в телефоне».
                            </h2>
                            <p className="clearfix">
                                <strong>Общие положения.</strong>
                            </p>
                            <p>
                                Настоящая политика конфиденциальности приложения
                                «Ситилинк. Дом в телефоне» (далее - Политика)
                                действует в отношении информации, передаваемых
                                лицом, использующим приложение «Ситилинк. Дом в
                                телефоне» (далее — «Пользователь» и «Приложение»
                                соответственно). При этом под Пользователем
                                понимается любое лицо, установившее Приложение и
                                и прошедшее авторизацию по номеру телефона.
                            </p>
                            <p>
                                Администратором приложения «Ситилинк. Дом в
                                телефоне» является ООО «Инлаб.Про».
                                Администратор уделяет большое внимание защите и
                                конфиденциальности информации Пользователей.
                                Любая информация о Пользователе, которую
                                получает Администратор, используется
                                исключительно в целях оказания услуг в
                                Приложении и повышения их качества, а также для
                                того, чтобы сделать услуги и содержимое
                                Приложения более простыми и удобными в
                                использовании.
                            </p>
                            <p>
                                Факт авторизации Пользователя в Приложении
                                означает выражение им безоговорочного согласия с
                                Правилами предоставления доступа к видеокамерам
                                и домофонам (
                                <Link to="/rules">
                                    https://moidom.citylink.pro/rules
                                </Link>
                                ), а также настоящей Политикой и указанными в
                                ней условиями обработки его информации. В случае
                                несогласия Пользователь обязан воздержаться от
                                авторизации в Приложении.
                            </p>
                            <p>
                                Пожалуйста, ознакомьтесь с нашей Политикой перед
                                пользованием Приложения «Ситилинк. Дом в
                                телефоне» или передачей Вашей информации
                                владельцу и администратору Приложения.
                            </p>

                            <p className="clearfix">
                                <strong>
                                    Описание информации Пользователей,
                                    получаемой и обрабатываемой Администратором
                                    Приложения.
                                </strong>
                            </p>
                            <p>
                                При авторизации Пользователя в Приложении
                                Администратор может запросить у Пользователя
                                информацию о логине и пароле от Личного кабинета
                                или полученных в рамках проекта “Мой Дом",а
                                также информацию, которая автоматически
                                передается Администратору в процессе
                                использования Приложения, в том числе IP-адрес и
                                информацию из cookies.
                            </p>

                            <p className="clearfix">
                                <strong>Цели обработки информации.</strong>
                            </p>
                            <p>
                                Администратор использует информацию
                                исключительно в целях оказания предлагаемых
                                Приложением услуг и повышения их качества, а
                                также для того, чтобы сделать услуги и
                                содержимое Приложения более простыми и удобными
                                в использовании, в том числе для:
                                <ul>
                                    <li>
                                        идентификации Пользователя в Приложении;
                                    </li>
                                    <li>
                                        для обработки запросов Пользователей
                                        Администратором Приложения;
                                    </li>
                                    <li>
                                        для рассылки служебных сообщений
                                        (например, для восстановления пароля
                                        доступа к учетной записи Пользователя);
                                    </li>
                                    <li>
                                        для предотвращения и выявления
                                        мошенничества и незаконного
                                        использования Приложения.
                                    </li>
                                </ul>
                            </p>

                            <p className="clearfix">
                                <strong>Условия обработки информации</strong>
                            </p>
                            <p>
                                Администратор использует информацию
                                Пользователей только для целей и в соответствии
                                с настоящей Политикой. В отношении информации
                                Пользователя сохраняется ее конфиденциальность.
                                Администратор не будет раскрывать третьим лицам,
                                распространять, продавать, отчуждать или иным
                                образом распоряжаться полученной информацией,
                                кроме как для целей и способами,
                                предусмотренными настоящей Политикой. При
                                обработке информации Пользователей Администратор
                                руководствуется действующим законодательством
                                РФ.
                            </p>

                            <p className="clearfix">
                                <strong>Сбор обезличенной статистики</strong>
                            </p>
                            <p>
                                С помощью стороннего сервиса "Яндекс.Метрика” (
                                <a
                                    target="_blank"
                                    href="https://metrika.yandex.ru"
                                >
                                    https://metrika.yandex.ru
                                </a>
                                ), AppMetrica (
                                <a
                                    target="_blank"
                                    href="https://appmetrica.yandex.ru/"
                                >
                                    https://appmetrica.yandex.ru/
                                </a>
                                ) и с целью улучшения пользовательского опыта
                                собирается и хранится обезличенная статистика.
                                Для получения дополнительных сведений о сборе
                                данных сервисами "Яндекс.Метрика" и AppMetrica
                                ознакомьтесь с документом "Условия использования
                                сервиса Яндекс.Метрика и AppMetrica" (
                                <a
                                    target="_blank"
                                    href="http://legal.yandex.ru/metrica_termsofuse/"
                                >
                                    http://legal.yandex.ru/metrica_termsofuse/
                                </a>
                                ), и с "Политикой конфиденциальности
                                персональной информации" (
                                <a
                                    target="_blank"
                                    href="http://legal.yandex.ru/confidential/"
                                >
                                    http://legal.yandex.ru/confidential/
                                </a>
                                ).
                            </p>

                            <p className="clearfix">
                                <strong>
                                    Защита информации Пользователей.
                                </strong>
                            </p>
                            <p>
                                Администратор обеспечивает принятие необходимых
                                и достаточных организационных и технических мер
                                для защиты информации Пользователей от
                                неправомерного или случайного доступа,
                                уничтожения, изменения, блокирования,
                                копирования, распространения, а также от иных
                                неправомерных действий с ней третьих лиц.
                            </p>

                            <p className="clearfix">
                                <strong>Cookies</strong>
                            </p>
                            <p>
                                Файл "cookie" - это небольшой текстовый файл,
                                отправляемый на устройство Пользователя с
                                сервера Администратора. Cookies содержат
                                информацию, которая позже может быть
                                использована Администратором. Никакие сведения,
                                собранные Администратором посредством Cookies,
                                не могут быть использованы для определения
                                (идентификации) Пользователей.
                            </p>
                            <p>
                                Администратор может использовать Cookies в целях
                                контроля использования Приложения, сбора
                                информации неличного характера о Пользователях,
                                сохранения предпочтений и другой информации на
                                компьютере Пользователя для того, чтобы
                                сэкономить время Пользователей, необходимое для
                                многократного введения в форме авторизации
                                Приложения одной и той же информации, а также в
                                целях отображения содержания в ходе последующих
                                посещений Пользователем Приложения.
                            </p>

                            <p className="clearfix">
                                <strong>
                                    Согласие Пользователя с Политикой.
                                </strong>
                            </p>
                            <p>
                                Пользователь признает и соглашается, что
                                авторизация Пользователя в Приложении «Ситилинк.
                                Дом в телефоне» означает безоговорочное согласие
                                Пользователя со всеми пунктами настоящей
                                Политики, а также Правилами предоставления
                                доступа к видеокамерам и безоговорочное принятие
                                их условий.
                            </p>
                            <p>
                                Продолжение Пользователем пользования
                                Приложением после любых изменений Политики
                                означает его согласие с такими изменениями и/или
                                дополнениями.
                            </p>
                            <p>
                                Пользователь обязуется регулярно знакомиться с
                                содержанием настоящей Политики в целях
                                своевременного ознакомления с ее изменениями.
                                Администратор оставляет за собой право по своему
                                личному усмотрению изменять и (или) дополнять
                                Политику в любое время без предварительного и
                                (или) последующего уведомления Пользователя.
                                Пользователи могут инициировать удаление своей
                                учетной записи, путем обращения с
                                соответствующим заявлением в Службу заботы о
                                Клиентах. Новая редакция Политики вступает в
                                силу с момента ее размещения по адресу:{" "}
                                <Link to="/privacy">
                                    https://moidom.citylink.pro/privacy
                                </Link>
                                , если иное не предусмотрено новой редакцией
                                Политики. Если Вы не согласны с положениями
                                Политики, откажитесь от использования
                                Приложения.
                            </p>

                            <p className="clearfix">
                                <strong>Заключительные положения.</strong>
                            </p>

                            <p>
                                К настоящей Политике и отношениям между
                                Пользователями и Администратором, возникающими в
                                связи с применением Политики, подлежит
                                применению право Российской Федерации. К
                                настоящей Политике имеют доступ все Пользователи
                                Приложения без исключения.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Privacy;
