import React from 'react';
import {NavLink} from "react-router-dom";

import '../../../sass/ArchiveModal.scss';
import '../../../sass/BuyAccessModal.scss';
import PropTypes from "prop-types";
import {CAMERA_TYPE_NEARBY_CAMERAS, CAMERA_TYPE_PUBLIC, CAMERA_TYPE_USER_CAMERAS} from "../../UserCameras/UserCamera";
import {mdApi} from "../../../index";

export default class BuyAccessModal extends React.Component {
    onClose = () => {
        if (this.props.onClose !== undefined) {
            this.props.onClose()
        }
    }

    onPurchaseRequest = () => {
        let cameraId = this.props.cameraId
        let cameraType = this.props.cameraType

        let paymentRequest;

        switch (cameraType) {
            case CAMERA_TYPE_NEARBY_CAMERAS:
                paymentRequest = () => {
                    return mdApi.nearbyCamerasRequestPaymentUrl(cameraId, location.href)
                }
                break;
            case CAMERA_TYPE_USER_CAMERAS:
                paymentRequest = () => {
                    return mdApi.userCamerasRequestPaymentUrl(cameraId, location.href)
                }
                break;
            case CAMERA_TYPE_PUBLIC:
                paymentRequest = () => {
                    return mdApi.publicCamerasRequestPaymentUrl(cameraId, location.href)
                }
                break;
            default:
                alert("Оплата не поддерживается")
                return;
        }

        paymentRequest()
            .then((data) => {
                window.location.href = data.url
            })
            .catch(async (response) => {
                let data = await response.json()
                let message = data.message

                alert(message)
            })
    }

    render() {
        let cameraName = this.props.cameraName ?? '';
        return (
            <div className='archive-modal buy-access-modal'>
                <div className="archive-modal__overlay" onClick={this.onClose}>
                    <div className="archive-modal__content" onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <div className="archive-modal__close-button" onClick={this.onClose}/>
                        <div className="archive-modal__column">
                            <h2 className='buy-access-modal__title'>Приобретите доступ</h2>
                            <p className="archive-modal__text buy-access-modal__text">
                                <span className='js-camera-address'/>
                                {cameraName}
                                <br />
                                Доступ к скачиванию действует 24 часа и распространяется <br/> на любой отрезок видео с данной
                                камеры. Стоимость всего — 99 руб.
                            </p>
                            {/*<p className='buy-access-modal__price-text'>Стоимость всего — 99 руб.</p>*/}
                            <button className="archive-modal__button" onClick={this.onPurchaseRequest}>Продолжить
                            </button>
                        </div>
                        <NavLink to="/faq#mrsk_cameras" onClick={this.onClose} className="archive-modal__need-help-button blue-text">Нужна
                            помощь?</NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

BuyAccessModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    cameraId: PropTypes.number.isRequired,
    cameraType: PropTypes.string.isRequired,
    cameraName: PropTypes.string.isRequired
}