import React from 'react';
import {Link} from "react-router-dom";

import '../../../sass/ArchiveModal.scss';
import '../../../sass/SignInModal.scss';
import PropTypes from "prop-types";
import {AppContext, FAQ_PATH} from "../../../routes";
import {PASSPORT_AUTH_URL} from "../../Header/BrowserContainer";

export default class SignInModal extends React.Component {
    constructor(props) {
        super(props);

        SignInModal.contextType = AppContext
    }

    onClose = () => {
        if (this.props.onClose !== undefined) {
            this.props.onClose()
        }
    }

    onAuthRequest = () => {
        let redirectUri = this.props.redirectUrl

        if (redirectUri !== undefined) {
            window.location.href = PASSPORT_AUTH_URL
        }
    }

    render() {
        return (
            <div className='archive-modal sign-in-modal'>
                <div className="archive-modal__overlay" onClick={this.onClose}>
                    <div className="archive-modal__content" onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <div className="archive-modal__close-button" onClick={this.onClose}/>
                        <div className="archive-modal__column">
                            <p className="archive-modal__text sign-in-modal__text">
                                <a onClick={this.onAuthRequest} style={{cursor: 'pointer'}} className="blue-text">Войдите в учетную запись</a> и приобретите доступ
                                к скачиванию архива с данной камеры за 99 руб.
                            </p>
                            <a onClick={this.onAuthRequest} className="archive-modal__button">Войти</a>
                        </div>
                        <Link to={`${FAQ_PATH}#mrsk_cameras`} onClick={this.onClose} className="archive-modal__need-help-button blue-text">Нужна
                            помощь?</Link>
                    </div>
                </div>
            </div>
        );
    }
}

SignInModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    redirectUrl: PropTypes.string
}
