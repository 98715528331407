import React, {PureComponent} from 'react'
import {Link} from 'react-router-dom'
import {YMaps, Map, Clusterer, Placemark} from 'react-yandex-maps'
import {MOBILE, CITYLINK} from '../helpers/constants/HeaderConstants'

import myIcon from '../images/camera.png'
import myIconActive from '../images/cameraActive.png'
import myIconCluster from '../images/cluster.png'

import '../sass/Public.css'
import '../sass/Sensors.css'
import PublicCamerasCitiesList from './PublicCameras/PublicCamerasCitiesList'
import SensorsTags from "./Header/Metatags/SensorsTags";
import {mdApi} from "../index";
import {Component} from "react";

const getPointData = point => {
    return {
        iconContent: point.id,
        id: point.id,
        balloonContent: '<div id="ya-ballon"></div>',
        hasHint: 1,
        hintContent: point.name,
    }
}

const getClusterOptions = () => {
    return {
        clusterIcons: [
            {
                href: myIconCluster,
                size: [40, 40],
                offset: [-20, -20]
            },
            {
                href: myIconCluster,
                size: [60, 60],
                offset: [-30, -30]
            }
        ],
        groupByCoordinates: false,
        hasBalloon: false,
        hasHint: false,
        maxZoom: 15
    }
}

const getPointOptions = () => {
    return {
        balloonLayout: 'default#imageWithContent',
        balloonShadow: false,
        balloonCloseButton: false,
        hideIconOnBalloonOpen: false,
        iconLayout: 'default#imageWithContent',
        iconImageHref: myIcon,
        iconImageSize: [28, 36],
        iconImageOffset: [-12, -36]
    }
}

window.timeout = null

class PublicSensors extends Component{

    componentDidMount() {
        let cityKey = this.props.match.params.city

        mdApi.cities()
            .then(cities => {
                this.setState({cities}, () => {
                    let selectedCity

                    if (cityKey === undefined) {
                        selectedCity = cities[0] ?? undefined
                        if (selectedCity !== undefined) {
                            let path = `/sensors/${selectedCity.key}`;
                            if(this.props.location) {
                                path += this.props.location.search;
                            }
                            this.props.history.push(path);
                        }
                    } else {
                        selectedCity = cities.find(city => city.key === cityKey)
                    }

                    if (selectedCity !== undefined) {
                        this.setState({selectedCity}, () => {
                            mdApi.smarthomeDevicesAll()
                                .then(devices => {
                                    this.setState({devices})
                                })
                        })
                    } else {
                        this.props.history.push("/404")
                    }
                })
            })
    }

    constructor(props) {
        super(props)

        this.state = {
            selectedCity: undefined,
            cities: [],
            devices: [],
            visible: false,
            isLoading: true,
            citySwitch: false,
            mapState: {
                center: [61.7858, 34.361],
                zoom: 13,
                controls: []
            },
            mapOptions: {
                yandexMapDisablePoiInteractivity: true,
                suppressMapOpenBlock: true,
                autoFitToViewport: 'always',
                yandexMapAutoSwitch: false
            },
            isSuccessSearch: false,
            balloon: false,
            currentPoint: {
                id: null,
                img: null,
                incident: null,
                lat: null,
                lon: null,
                name: null,
                src: null,
            }
        }
    }

    toggle = () => {
        this.setState({visible: !this.state.visible});
    }

    generateKey = (pre) => {
        return `point_${pre}`
    }

    handleCityList = () => {
        this.setState(prevState => ({
            citySwitch: !prevState.citySwitch
        }))
    }

    handleButtonChange = (cityKey) => {
        let city = this.state.cities.find(city => city.key === cityKey)
        if (city === undefined) {
            return;
        }

        if (this.state.selectedCity === city) {
            return;
        }

        this.setState({
            citySwitch: false,
            mapState: {
                center: [city.center.latitude, city.center.longitude],
                zoom: city.ymap_zoom,
                controls: []
            },
            selectedCity: city
        })
    }

    handleApiAvaliable(ymaps) {
        let _state = this

        ymaps.ready(() => {

            if (!this)
                return

            _state.setState({
                isLoading: false,
            })

            let _this = this

            document.addEventListener(
                'click',
                function (event) {
                    if (event.target.matches('#balloon_close')) {
                        if (_this.map)
                            _this.map.balloon.close()
                    } else if (event.target.matches('#point_rakurs')) {
                        let id = 0

                        if (event.target.dataset !== undefined) {
                            id = event.target.dataset.id
                        }

                        let lat = event.target.dataset.lat
                        let lon = event.target.dataset.lon

                        if (_this.map) {
                            _this.map.setCenter([lat, lon], 16)
                        } else {
                            return
                        }

                    } else if (event.target.matches('#zoom-in')) {
                        if (_this.map) {
                            let zoom = _this.map.getZoom()
                            _this.map.setZoom(zoom + 1)
                        }
                    } else if (event.target.matches('#zoom-out')) {
                        if (_this.map) {
                            let zoom = _this.map.getZoom()
                            _this.map.setZoom(zoom - 1)
                        }
                    } else if (event.target.matches('.items-search__item-link')) {
                        let id = false

                        if (event.target.dataset !== undefined) {
                            id = event.target.dataset.id
                        }

                        let center = [event.target.dataset.lat, event.target.dataset.lon]

                        if (_this.map) {
                            _this.map.setCenter(center, 16)
                        } else {
                            return false
                        }

                        _state.setState({
                            isSuccessSearch: false,
                        })

                        setTimeout(function () {
                            _this.map.geoObjects.each(function (e) {
                                let geo = e.getGeoObjects()
                                for (let i = 0, l = geo.length; i < l; i++) {
                                    geo[i].options.set('iconImageHref', myIcon)

                                    if (geo[i].properties.get('id') === id) {
                                        let currentPoint = _state.props.points[id]

                                        _state.setState({
                                            currentPoint: currentPoint,
                                            balloon: true,
                                        })

                                        geo[i].balloon.open()
                                        geo[i].options.set('iconImageHref', myIconActive)

                                    }
                                }
                            })
                        }, 300)

                    } else {
                        return null
                    }
                },
                false
            )

            if (this.map) {
                this.map.options.set('minZoom', 10)
                this.map.options.set('maxZoom', 18)

                this.map.geoObjects.events.add('click', function (e) {
                    _state.setState({
                        citySwitch: false,
                    })
                    _this.map.balloon.close()

                    setTimeout(
                        function () {
                            let targetId = e.get('target').properties.get('id')

                            if (targetId) {

                                let currentPoint = _state.state.devices.find(device => device.id === targetId)

                                _state.setState({
                                    currentPoint: currentPoint,
                                    balloon: true
                                })

                                e.get('target').options.set('iconImageHref', myIconActive)
                            }

                        },
                        200
                    )
                })

                this.map.events
                    .add('click', function (e) {
                        _state.setState({
                            citySwitch: false,
                        })
                    })
                    .add('balloonclose', function (e) {
                        // скрываем shareLinks
                        _state.setState({
                            isShare: false,
                        })

                        _this.map.geoObjects.each(function (e) {
                            let geo = e.getGeoObjects()
                            for (let i = 0, l = geo.length; i < l; i++) {
                                geo[i].options.set('iconImageHref', myIcon)
                            }


                            _state.setState({
                                balloon: false,
                            })
                        })
                    })
                    .add('boundschange', function (e) {
                        let newZoom = e.get('newZoom'), oldZoom = e.get('oldZoom')

                    })
            }
        })
    }

    render() {
        if (MOBILE) {
            this.props.history.push('/')
        }


        let points = this.state.devices.reverse().map(device => {
            let coordinates = [device.latitude, device.longitude]

            return <Placemark
                key={device.id}
                geometry={{coordinates}}
                properties={getPointData(device)}
                options={getPointOptions()}
            />
        })

        return (
            <div>
                <SensorsTags/>
                <div className="content-wrapper wide map">
                    <div className="top-info-banner citylink">
                        <div className="top-info-banner_inner">
                            <div>
                                <p>
                                    <b>Умные датчики</b> – это устройства, которые делают ваш дом или квартиру
                                    безопаснее и комфортнее.
                                    Они отслеживают изменения температуры, влажности и других показателей в помещении и
                                    в режиме
                                    реального времени передают информацию вам на смартфон или компьютер.

                                    {this.state.visible && <div><br/>
                                        <div className="secondText">
                                            На карте представлены <b>«умные устройства» «Ситилинка»</b>:
                                            датчики температуры и влажности. Их показатели помогут вовремя
                                            отрегулировать отопительную
                                            систему или проветривать помещение, что напрямую влияет на здоровье и
                                            самочувствие человека.
                                        </div>
                                    </div>}

                                </p>
                            </div>
                            <div className={(this.state.visible ? "open " : "") + "colapse"} onClick={this.toggle}/>
                        </div>
                    </div>
                    {
                        !this.state.isLoading &&
                        <div>
                            <div className="content-navigate">
                                <div className="content-navigate__city" onClick={() => this.handleCityList()}>
                                    {this.state.selectedCity ? this.state.selectedCity.name : ''}
                                </div>

                                {
                                    this.state.citySwitch &&
                                    <div className="content-navigate__hiddenList">

                                        <PublicCamerasCitiesList
                                            handleButtonChange={this.handleButtonChange}
                                        />

                                    </div>
                                }


                                {
                                    this.state.balloon && !this.state.citySwitch &&
                                    <div
                                        className={`player-balloon sensorFix ${(!CITYLINK && this.props.isCitylinkRegion) ? 'citylink_client' : ''}`}
                                        key={this.generateKey(this.state.currentPoint.id)}>
                                        <span id="balloon_close"/>
                                        <div className="sensors">
                                            <div>
                                                <div className="temperature">

                                                </div>
                                                <div
                                                    className="temperature__text">{Number(this.state.currentPoint.temp.value) | 0} &#8451;
                                                    <br/>
                                                    <small>{this.state.currentPoint.temp.created}</small></div>
                                            </div>


                                            <div>
                                                <div className="humidity">

                                                </div>
                                                <div
                                                    className="humidity__text">{Number(this.state.currentPoint.humidity.value) | 0} %<br/>
                                                    <small>{this.state.currentPoint.humidity.created}</small></div>
                                            </div>

                                        </div>


                                    </div>
                                }

                                <div className='content-navigate__tools sensorFix'>
                                    <div className="tool" id='zoom-in'/>
                                    <div className="tool" id="zoom-out"/>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="yandex-map">
                        <YMaps onApiAvaliable={(ymaps) => this.handleApiAvaliable(ymaps)} version="2.1">
                            <Map
                                width={document.body.clientWidth}
                                height={'800px'}
                                state={this.state.mapState}
                                instanceRef={this.setMapControlInstanceRef}
                                options={this.state.mapOptions}>
                                <Clusterer
                                    options={getClusterOptions()}>
                                    {points}
                                </Clusterer>
                            </Map>
                        </YMaps>
                    </div>

                    {
                        this.state.isLoading &&
                        <div className="loading map-loading"/>
                    }

                </div>
            </div>
        )
    }

    setMapControlInstanceRef = (ref) => {
        this.map = ref
    }

}

export default PublicSensors
