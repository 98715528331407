import React, {Component} from 'react'
import PropTypes from "prop-types";
import "../../../sass/SmarthomeAppPromoMobile.css"
import mobile1 from "../../../images/index_popup/1-mobile-popup-image-promo-app.png"
import mobile2 from "../../../images/index_popup/2-mobile-popup-image-promo-app.png"

/**
 * Мобильная версия Промо
 */
export default class PopupMobile extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const images = {
            "mobile1": mobile1,
            "mobile2": mobile2
        };

        const mobileImage = images['mobile' + this.props.popupImageIndex];
        const bgClasses = "popup-mobile-blur popup-mobile-blur_" + this.props.popupImageIndex;

        return (
            <div>
                <div className="popup-mobile">
                    <div className={bgClasses}></div>
                    <div className="popup-mobile-body">
                        <img className="popup-mobile-img" src={mobileImage} alt="Всплывающее окно"/>
                        {
                            this.props.buttons && this.props.buttons.map(button => {
                                return button
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

PopupMobile.propTypes = {
    popupImageIndex: PropTypes.any.isRequired,
    buttons: PropTypes.array
}


