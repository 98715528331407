import Cookies from "universal-cookie";
import {isBrowser, isConsole, isMobile} from "react-device-detect";

export const cookies = new Cookies()

export const LOCALHOST = window.location.hostname.indexOf('localhost') !== -1 && false;
export const TESTHOST = window.location.hostname.indexOf('test') !== -1 && false;
export const CITYLINK = window.location.hostname.indexOf('citylink.pro') !== -1
export const RELANT = window.location.hostname.indexOf('relant.ru') !== -1

export const BROWSER = isBrowser || cookies.get('pc') || isConsole ? true : false;
export const MOBILE = isMobile && !cookies.get('pc') ? true : false;
export const ANDROID = navigator.userAgent.toLowerCase().indexOf("android") > -1;
export const HIDE_PROMO =  window.location.pathname.indexOf("privacy") > -1;

export const smarthomeAppUrl = "https://smarthome.citylink.pro/get_app?q=md_banner"
export const code = cookies.get('code')

let currentTitlePageProvider = 'Ситилинк';

export const HOST = 'https://moidom.citylink.pro';
export const HOST_TO_OLD_API ='https://moidom.karelia.pro'

export const connectionUrl = 'https://camera.citylink.pro'

export const CURRENT_PROVIDER = 'Ситилинк';
export const CURRENT_META_CITY = 'Петрозаводск';


const ABOUT_PATH = "/about"
const FAQ_PATH = "/faq"
const NOT_FOUND_PATH = `/404`
const PUBLIC_MAP_PATH = ``
const PUBLIC_LIST_PATH = `/publist`
const USER_CAMERAS_PATH = `/cameras`
const NEARBY_CAMERAS_PATH = `/nearby-cameras`
const FRAME_PATH = `/frame`

export const menuItems = {
    citylink: [
        {
            name: 'Мои камеры',
            url: USER_CAMERAS_PATH,
            key: 'cameras',
            mainPage: false,
            urlForAdmin: USER_CAMERAS_PATH
        },
        {
            name: 'Дворовые',
            url: NEARBY_CAMERAS_PATH,
            key: 'nearby-cameras',
            mainPage: false,
            urlForAdmin: NEARBY_CAMERAS_PATH
        },
        {
            name: 'Публичные',
            url: PUBLIC_LIST_PATH,
            key: 'publist',
            mainPage: false,
            urlForAdmin: PUBLIC_LIST_PATH
        }
    ]
}
/**
 * Отформатированные названия городов
 *
 * @returns string
 */
export const cityNameFormatted = (cityName) => {
    const names = {
        'Петрозаводск': ['Петрозаводска', 'Петрозаводскe'],
        'Кондопога': ['Кондопоги', 'Кондопогe'],
        'Медвежьегорск': ['Медвежьегорска', 'Медвежьегорскe'],
        'Сортавала': ['Сортавалы', 'Сортавале'],
        'Костомукша': ['Костомукши', 'Костомукше'],
        'Питкяранта': ['Питкяранты', 'Питкяранте'],
        'Суоярви': ['Суоярви', 'Суоярви'],
        'Лахденпохья': ['Лахденпохье', 'Лахденпохье'],
        'Апатиты': ['Апатитов', 'Апатитах'],
        'Сегежа': ['Сегежи', 'Сегеже'],
        'Надвоицы': ['Надвоиц', 'Надвоицах'],
        'Мурманск': ['Мурманска', 'Мурманске'],
        'Северодвинск': ['Северодвинска', 'Северодвинске'],
        'Полярные зори': ['Полярных Зорей', 'Полярных Зорях'],
        'Оленегорск': ['Оленегорска', 'Оленегорске'],
        'Архангельск': ['Архангельска','Архангельске'],
        'Санкт-Петербург': ['Санкт-Петербурга','Санкт-Петербурге'],
        'Саранск':['Саранска','Саранске'],
        'Кандалакша':['Кандалакши','Кандалакше'],
        'Красное Село':['Красного села','Красном селе'],
        'Мга':['Мги', 'Мге'],
        'Тихвин':['Тихвина', 'Тихвине'],
        'Надым':['Надыма', 'Надыме'],
        'Пангоды':['Пангоды', 'Пангоды'], // :)
        'Заполярный':['Заполярного','Заполярном']
    }

    if (!(cityName in names)) { //если нет такого
        return names[Object.keys(names)[0]]; //вернуть первый - Петрозаводск
    }

    return names[cityName]
}

/**
 * Добавляем профайдера в тэг title
 *
 * @returns string
 */
export const currentProviderTitleFormatted = (addCity = false) => {
    let prefix = '| Мой Дом ';
    if (addCity) {
        //???
    }
    return prefix + currentTitlePageProvider
}
