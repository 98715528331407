import React, {Component} from 'react'
import FaqItem from "../FaqItem";

/**
 * Контейнер мобильной версии страницы Faq Ситилинк
 */
export class MobileContainer extends Component {
    render() {
        return (
            <div className="mobile-wrapper">
                <div className="faq-page mobile">
                    <div className="faq-wrapper">
                        {this.props.items.map((item, i) =>
                            <FaqItem key={i} title={item.title} descr={item.descr}/>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileContainer;


