import React, {Component} from 'react';
import {AppContext} from "./routes";

export default class EventDispatcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dispatchedEvents: {},
            listeners: {}
        }

        EventDispatcher.contextType = AppContext
    }

    dispatchEvent = (eventName, data) => {
        let listeners = {...this.state.listeners}
        let eventListeners = listeners[eventName] ?? []

        this.processListeners(eventListeners,data)

        delete listeners[eventName]

        let dispatchedEvents = this.state.dispatchedEvents
        this.setState({
            listeners,
            dispatchedEvents: {
                ...dispatchedEvents,
                [eventName]: data
            }
        })
    }

    listenToEvent = (eventName, action) => {
        let listeners = this.state.listeners

        let eventListeners = listeners[eventName] ?? []

        this.setState({
            listeners: {
                ...listeners,
                [eventName]: [
                    ...eventListeners,
                    action
                ]
            }
        }, () => this.processListenersForAlreadyDispatchedEvents(eventName))
    }

    processListeners = (listeners, data) => {
        listeners.forEach(listener => this.processListener(listener, data))
    }

    processListener = (listener, data) => {
        if (data === undefined) {
            listener()
        } else {
            listener(data)
        }
    }

    processListenersForAlreadyDispatchedEvents = (eventName) => {
        let listeners = {...this.state.listeners}
        let eventListeners = listeners[eventName]

        let isEventDispatched = Object.keys(this.state.dispatchedEvents).includes(eventName)

        if (!isEventDispatched) {
            return
        }

        const eventData = this.state.dispatchedEvents[eventName]

        this.processListeners(eventListeners,eventData)

        delete listeners[eventName]

        this.setState({
            listeners
        })
    }

    componentDidMount() {
        this.context.addCallback('dispatchEvent', this.dispatchEvent)
        this.context.addCallback('listenToEvent', this.listenToEvent)
    }

    render() {
        return null
    }
}