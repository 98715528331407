import React, {Component} from 'react';
import {mdApi} from "../../index";
import AuthorizedUserCameras from "./AuthorizedUserCameras";
import UnauthorizedUserCameras from "./UnauthorizedUserCameras";
import LegalEntityCameras from "./LegalEntityCameras";
import {AppContext} from "../../routes";

export const ROUTER_READY_EVENT = 'router.ready'
export default class UserCameras extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLegalEntity: false,
            loaded: false
        }

        UserCameras.contextType = AppContext
    }

    componentDidMount() {
        mdApi.billingStatus()
            .then(() => {
                this.setState({
                    loaded: true,
                    isLegalEntity: true
                })
            })
            .catch(() => {
                this.setState({
                    loaded: true,
                    isLegalEntity: false
                })
            })
    }

    render() {
        return (
            <>
                {
                    this.state.loaded &&
                    <>
                        {
                            mdApi.isAuthorized ? (
                                this.state.isLegalEntity ? <LegalEntityCameras/> : <AuthorizedUserCameras/>
                            ) : <UnauthorizedUserCameras/>
                        }
                    </>
                }
            </>
        )
    }
}