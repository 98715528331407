import React from 'react'

/**
 * Блок вывода ошибки
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const NotificationErrorsBlock = (props) => {
  return (
    <div className="errors-block">
      <div className="alert alert-danger">{props.text}</div>
    </div>
  )
}

export default NotificationErrorsBlock
