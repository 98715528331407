import React, {Component} from 'react'
import Cookies from "universal-cookie/es6";

import {BROWSER} from "../../../helpers/constants/HeaderConstants"

import PropTypes from "prop-types";
import PopupBrowser from "./PopupBrowser";
import PopupMobile from "./PopupMobile";

const COOKIE_NAME = "md_popup_data"
const COOKIE_DELIMITER = "_"

export const STRATEGY_RANDOM = "random"
export const STRATEGY_SERIAL = "serial"

const PLATFORM_PC = "pc"
const PLATFORM_MOBILE = "mobile"

export default class Popup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showPopup: false,
            nextStorageArgs: undefined,
            details: undefined
        }
    }

    // reading data from cookies by parsing string in it with delimiter
    getPopupStorage = () => {
        // max cookie size is 4096 bytes(key size + value size + attributes size), so no json here :)
        // instead json lets save string like "currentStage_previousIndex_timesShown_lastShownTime"
        const cookies = new Cookies();

        const popupData = cookies.get(COOKIE_NAME) ?? null;
        if (popupData === null) {
            return null
        }

        const args = popupData.split(COOKIE_DELIMITER).map(v => parseInt(v))
        if (args.length !== 4) {
            return null
        }

        const [
            currentStage, // какая сейчас итерация
            previousIndex, // индекс последнего показанного баннера
            timesShown, // сколько баннеров уже показали в одной итерации
            lastShownTime // последний раз когда показали баннер (timestamp)
        ] = args

        return {
            currentStage,
            previousIndex,
            timesShown,
            lastShownTime
        }
    }

    // saves data to cookies by converting params in string with delimiter
    setPopupStorage = (currentStage, previousIndex, timesShown, lastShownTime) => {
        const cookies = new Cookies();
        const cookieName = COOKIE_NAME

        const args = [
            currentStage ?? 0,
            previousIndex ?? -1,
            timesShown ?? 0,
            lastShownTime ?? 0
        ]

        const date = new Date();
        date.setTime(date.getTime() + (365*24*60*60*1000)); // 365 дней
        cookies.set(cookieName, args.join(COOKIE_DELIMITER), {expires: date, path: "/"});
    }

    preparePopupDetails = () => {
        // read popup storage
        let popupStorage = this.getPopupStorage()
        if (popupStorage === null) {
            this.setPopupStorage(0, null, 0, 0)
            popupStorage = this.getPopupStorage()

            if (popupStorage === null) {
                return null
            }
        }

        const {
            currentStage,
            previousIndex,
            timesShown,
            lastShownTime
        } = popupStorage

        // goes next if shows count less than max stages count
        if (currentStage >= this.props.stages) {
            return null
        }

        const timeDiff = new Date().getTime() - lastShownTime

        // if previous index (banner index) was set to -1 that means it just started new stage
        if (previousIndex === -1 && timeDiff < this.props.stageInterval * 60000) {
            return null
        }

        // if previous index (banner index) more than -1 that means it not starting new stage
        if (previousIndex > -1 && timeDiff < this.props.stagePopupInterval * 60000) {
            return null
        }

        const platform = BROWSER ? PLATFORM_PC : PLATFORM_MOBILE

        const platformPopupCount = (this.getPopups()[platform] ?? []).length ?? 0
        if (platformPopupCount < 1) {
            return null
        }

        // prepare data for saving in storage (cookies)
        let nextIndex;
        const isGoingNextStage = timesShown >= platformPopupCount - 1

        switch (this.props.strategy) {
            case STRATEGY_SERIAL:
                nextIndex = isGoingNextStage ? 0 : previousIndex + 1
                break;
            case STRATEGY_RANDOM:
                let randomIndex = 0;

                if (platformPopupCount < 3) {
                    // fair if banners count is exactly 2, for bigger amount you have to use something else
                    if (previousIndex === -1) {
                        randomIndex = Math.floor(Math.random() * 2)
                    } else {
                        randomIndex = previousIndex === 0 ? 1 : 0
                    }

                    nextIndex = randomIndex
                } else {
                    nextIndex = Math.floor(Math.random() * platformPopupCount)
                }
                break;
            default:
                return null;
        }

        // saving data to local state (not in storage - cookies). saving in cookies will be only in ackPopup method
        this.setState({
            nextStorageArgs: [
                isGoingNextStage ? currentStage + 1 : currentStage,
                isGoingNextStage ? -1 : nextIndex,
                isGoingNextStage ? 0 : timesShown + 1,
                new Date().getTime()
            ]
        })

        return this.getPopupDetails(platform, nextIndex)
    }

    getPopupDetails = (platform, index) => {
        return this.getPopups()[platform][index]
    }

    getPopups = () => {
        return {
            pc: [
                [
                    "1",
                    [
                        <input type="button" className="popup-pc__btn-download popup-pc__btn-download_1"
                               onClick={() => this.processDownloadButtonClick("https://smarthome.citylink.pro/get_app?q=md_banner2")}
                               value="Скачать"/>,
                    ]
                ],
                [
                    "2",
                    [
                        <input type="button" className="popup-pc__btn-download popup-pc__btn-download_2"
                               onClick={() => this.processDownloadButtonClick("https://smarthome.citylink.pro/get_app?q=md_avto")}
                               value="Скачать"/>,
                    ]
                ]
            ],
            mobile: [
                [
                    "1",
                    [
                        <input type="button" className="popup-mobile__btn-close"
                               onClick={this.processCloseButtonClick}/>,
                        <input type="button" className="popup-mobile__btn-download popup-mobile__btn-download_1"
                               onClick={() => this.processDownloadButtonClick("https://smarthome.citylink.pro/get_app?q=md_banner2")}
                               value="Скачать"/>
                    ]
                ],
                [
                    "2",
                    [
                        <input type="button" className="popup-mobile__btn-close"
                               onClick={this.processCloseButtonClick}/>,
                        <input type="button" className="popup-mobile__btn-download popup-mobile__btn-download_2"
                               onClick={() => this.processDownloadButtonClick("https://smarthome.citylink.pro/get_app?q=md_avto")}
                               value="Скачать"/>
                    ]
                ]
            ]
        }
    }

    // закрывает баннер
    closePopup = () => {
        this.setState({
            showPopup: false
        })
    }

    // засчитывает просмотр баннера
    ackPopup = () => {
        // saving prepared data to storage (cookies)
        const args = this.state.nextStorageArgs
        this.setPopupStorage(...args)
    }

    processDownloadButtonClick = (url) => {
        if (typeof url === 'string' && url.startsWith("http")) {
            window.open(url, "_blank")
        }

        this.closePopup()
        this.ackPopup()
    }

    processCloseButtonClick = () => {
        this.closePopup()
        this.ackPopup()
    }

    componentDidMount() {
        const details = this.preparePopupDetails() ?? null

        this.setState({
            details,
            showPopup: details !== null
        })
    }

    render() {
        const details = this.state.details ?? null

        if (details === null) {
            return null
        }

        const imageIndex = details[0]
        const popupButtons = details[1]

        return (
            this.state.showPopup ?
                <>
                    {
                        BROWSER ?
                            <PopupBrowser popupImageIndex={imageIndex} buttons={popupButtons} closeCallback={this.processCloseButtonClick}/> :
                            <PopupMobile popupImageIndex={imageIndex} buttons={popupButtons}/>
                    }
                </>
                : null
        )
    }
}

Popup.propTypes = {
    //сколько раз показываем пользователю коллекцию баннеров, за всё время(итерации)
    stages: PropTypes.number.isRequired,
    // стратегия показа баннеров из коллекции на текущей итерации
    strategy: PropTypes.string.isRequired,
    // время в минутах между показами баннеров из коллекции в пределах текущей итерации
    stagePopupInterval: PropTypes.number.isRequired,
    // время между итерациями, в минутах
    stageInterval: PropTypes.number.isRequired
}
