import {memo} from 'react';

/*
	173 - Большой - 370*433
	174 - Мобил - 350*100
	175 - Карта - 470*100
*/
const PROMO_IDS = {
	large: 173,
	mobile: 174,
	map: 175,
};

const Promo = memo(({type = 'large', timer = 8}) => {
	const promo_id = PROMO_IDS[type]; 
	
	if(typeof cl_partner !== 'undefined' && promo_id && +timer) {
		const promo_option = {};
		const element_id = `cl_promo_${promo_id}`;

		promo_option[promo_id] = {element: element_id, timer: +timer};
		cl_partner.init(promo_option);

		return (<div id={element_id}/>)
	}

	return null;
});

export default Promo;