import React, {Component} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {MOBILE} from '../../helpers/constants/HeaderConstants'

import '../../sass/GetCode.css'
import RequestSmsInstruction from "./RequestSmsInstruction";
import {mdApi} from "../../index";
import {history} from "../../routes";
import SaintPetersburgRequestSmsInstruction from "./SaintPetersburgRequestSmsInstruction";

export class RequestSms extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cities: [],
            selectedCity: undefined,
            streets: [],
            selectedAddresses: [],
            phone: '+7',
            isPhoneValid: false,
            comment: '',
            isCommentValid: false,
            showAddressAddBtn: true,
            showGetCodeBtn: true,
            showConfirmBySmsBlock: false,
            timer: undefined,
            seconds: 60
        }
    }

    fetchCities = () => {
        return mdApi.accessibleCamerasCities()
    }

    fetchStreets = (city) => {
        return mdApi.accessibleCamerasStreets(city.id)
    }

    fetchHouses = (street) => {
        return mdApi.accessibleCamerasHouses(street.id)
    }

    updateCities = () => {
        return this.fetchCities()
            .then(cities => {
                this.setState({
                    cities,
                    selectedCity: cities.length === 0 ? undefined : cities[0]
                }, this.updateStreets)
            })
            .catch(() => {
            })
    }

    updateStreets = () => {
        let city = this.state.selectedCity
        if (city === undefined) {
            return
        }

        return this.fetchStreets(city)
            .then(streets => {
                this.setState({
                    streets:streets.sort((a,b) => a.name > b.name ? 1 : -1)
                })
            })
            .catch(() => {
            })
    }

    handleCityChange = (cityId) => {
        cityId = Number(cityId)

        if (cityId === null) {
            return
        }

        let city = this.state.cities.find(city => city.id === cityId)
        if (city === undefined) {
            return
        }

        this.setState({
            selectedCity: city,
            showAddressAddBtn: true,
            selectedAddresses: []
        }, this.updateStreets);
    }

    handleStreetChange = (addressIndex, streetId) => {
        streetId = Number(streetId)

        if (isNaN(streetId)) {
            return
        }

        let street = this.state.streets.find(street => street.id === streetId)

        if (street === undefined) {
            this.setState({
                addresses: this.state.selectedAddresses.map((address, index) => {
                    if (index === addressIndex) {
                        address.houses = []
                        address.selectedHouse = undefined
                    }
                    return address
                })
            })
            return
        }

        return this.fetchHouses(street)
            .then(houses => {
                this.setState({
                    addresses: this.state.selectedAddresses.map((address, index) => {
                        if (index === addressIndex) {
                            address.houses = houses.sort((a,b) => a.number > b.number ? 1 : -1)
                            address.selectedHouse = houses.length === 0 ? undefined : houses[0]
                        }
                        return address
                    })
                })
            })
    }

    handleHomeChange = (addressKey, houseId) => {
        houseId = Number(houseId)

        if (isNaN(houseId)) {
            return
        }

        let address = this.state.selectedAddresses.find(address => address.key === addressKey)

        if (address === undefined || address === null) {
            return
        }

        let house = address.houses.find(house => house.id === houseId)

        if (house === undefined) {
            return;
        }

        this.setState({
            selectedAddresses: this.state.selectedAddresses.map((address) => {
                if (address.key === addressKey) {
                    address.selectedHouse = house
                }

                return address
            })
        })
    }

    handleAddressAdd = () => {
        if (!this.state.showAddressAddBtn) {
            return;
        }

        let addresses = this.state.selectedAddresses

        this.setState({
            selectedAddresses: addresses.concat({
                key: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
                city: this.state.selectedCity,
                street: undefined,
                selectedHouse: undefined,
                houses: []
            }),
            showAddressAddBtn: addresses.length < 2         // не более 3 адресов // скрываем кнопку добавления адреса
        })
    }

    handleAddressDelete = (addressIndex) => {
        addressIndex = Number(addressIndex)

        if (isNaN(addressIndex)) {
            return
        }

        this.setState({
            selectedAddresses: this.state.selectedAddresses.filter((address, index) => index !== addressIndex),
            showAddressAddBtn: this.state.selectedAddresses.length < 4
        })
    }

    handlePhoneChange = (phone) => {
        this.setState({
            phone,
            isPhoneValid: this.isPhoneValid(phone)
        })
    }

    requestSmsCode = () => {
        let phone = this.state.phone
        let addresses = this.state.selectedAddresses
        let comment = this.state.comment

        let houses = [];

        if (!this.state.isPhoneValid || !this.state.isCommentValid) {
            return
        }

        /**
         * Если в итерируемом адресе присутствует дом - добавляем ID дома, иначе проверяем улицу.
         * Если присутствует улица, но не присутствует дом - добавляем ID улицы
         */
        addresses.forEach(address => {
            if (address.selectedHouse !== undefined) {
                houses.push(address.selectedHouse.id)
            }
        })

        /** Делаем значения уникальными **/
        houses = [...new Set(houses)]

        if(houses.length < 1){
            alert("Необходимо указать адрес")
            return ;
        }

        return mdApi.requestSmsCode(phone, houses,comment)
            .then(() => {
                this.setState({
                    showGetCodeBtn: false,
                    showConfirmBySmsBlock: true,
                }, () => {
                    this.startTimer((seconds) => {
                        this.setState({seconds}, () => {
                            if (seconds < 1) {
                                this.setState({
                                    showGetCodeBtn: true,
                                    showConfirmBySmsBlock: false,
                                })
                            }
                        })
                    })
                })
            })
            .catch(async (response) => {
                let data = await response.json()
                alert(data.message)
            })
    }

    handleCommentChange = (comment) => {
        this.setState(
            {
                comment
            }, () => {
                this.setState({
                    isCommentValid: this.isCommentValid(this.state.comment)
                })
            }
        )
    }

    isPhoneValid = (phone) => {
        // проверить поле на формат
        const exp = /^\+7\d{10}$/;

        // проверяем формат +7...
        return exp.test(phone);
    }

    isCommentValid = (comment) => {
        comment = this.state.comment.trim()

        return comment !== '' && comment.length > 3;
    }

    handleCode = (code) => {
        let codeString = `${code}`

        if (codeString.length > 6) {
            return
        }

        this.setState({
            code
        }, this.submitCode)
    }

    submitCode = async () => {
        let code = this.state.code
        let codeString = `${code}`

        if (codeString.length > 5) {
            mdApi.makeLoginByCode(code)
                .then(() => {
                    history.push('/cameras')
                })
                .catch(async (response) => {
                    let data = await response.json()
                    alert(data.message)
                })
        }
    }

    /**
     *
     * @param onChange {function(seconds:Number)}
     */
    startTimer = (onChange) => {
        this.stopTimer()

        let seconds = 60
        let timer = setInterval(() => {
                seconds--
                if (onChange !== undefined) {
                    onChange(seconds)
                }
                if (seconds < 1) {
                    this.stopTimer()
                }
            },
            1000
        );

        this.setState({
            timer
        })
    }

    stopTimer = () => {
        if (this.state.timer !== undefined) {
            clearInterval(this.state.timer)
        }
    }

    componentDidMount() {
        this.updateCities()
            .then(this.handleAddressAdd)
            .catch()
    }

    render() {
        let selectedCity = this.state.selectedCity

        if (this.state.selectedCity === undefined) {
            return null
        }

        let cityKey = selectedCity.key

        const maxSelectedAddress = 3
        let selectedAddressesCount = this.state.selectedAddresses.length

        return (
            <div className={`get-code-block ${MOBILE ? 'mobile' : ''}`}>
                <ReactCSSTransitionGroup
                    component="div"
                    className="get-code-transition-wrapper"
                    transitionName="get-code--transition"
                    transitionAppear={true}
                    transitionEnter={true}
                    transitionLeave={true}
                    transitionAppearTimeout={300}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}>
                    <div className="get-code-wrapper">
                        <div className="get-code-header">
                            Следуйте простой инструкции, чтобы посмотреть записи с дворовых камер
                        </div>
                        <div className="get-code-content">
                            {
                                cityKey === 'spb' ?
                                    <SaintPetersburgRequestSmsInstruction /> :
                                    <RequestSmsInstruction/>
                            }
                            <div className="get-code-form">
                                <div className="address-wrapper">
                                    <label htmlFor="city">Город</label>
                                    <select
                                        className="city"
                                        onChange={({target: {value}}) => this.handleCityChange(value)}
                                        value={this.state.selectedCity.id}>
                                        {
                                            this.state.cities.map((city, index) => {
                                                return <option key={index} value={city.id}>{city.name}</option>
                                            })
                                        }
                                    </select>
                                    {
                                        this.state.selectedAddresses.map((address, index) => {
                                            return (
                                                <div className="address" key={`address-${address.key}`}>
                                                    <div className="address-item">
                                                        <label htmlFor="street">Улица</label>
                                                        <select
                                                            className="street"
                                                            key={`street-${address.street === undefined ? '' : address.street.id}`}
                                                            onChange={({target: {value}}) => this.handleStreetChange(index, value)}
                                                            defaultValue={0}>
                                                            <option key={0} value={0}/>
                                                            {
                                                                this.state.streets.map((street, i) => {
                                                                        return <option key={i}
                                                                                       value={street.id}>{street.name}</option>
                                                                    }
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="address-item home-item">
                                                        <label htmlFor="home">Дом</label>
                                                        <select
                                                            className={`home`}
                                                            key={`home-${address.selectedHouse === undefined ? '' : address.selectedHouse.id}`}
                                                            id={`home-${address.selectedHouse === undefined ? '' : address.selectedHouse.id}`}
                                                            onChange={({target: {value}}) => this.handleHomeChange(address.key, value)}
                                                            value={address.selectedHouse === undefined ? 0 : address.selectedHouse.id}>
                                                            <option key={0} value={0}/>
                                                            {
                                                                address.houses.map((house, index) => {
                                                                    return <option key={index}
                                                                                   value={house.id}>{house.number}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    {
                                                        index !== 0 &&
                                                        <div className="delete-item"
                                                             onClick={() => this.handleAddressDelete(index)}/>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        selectedAddressesCount < maxSelectedAddress &&
                                        <div
                                            className={`address-add ${!this.state.showAddressAddBtn ? 'unactive' : ''}`}
                                            onClick={this.handleAddressAdd}>
                                            Добавить адрес
                                        </div>
                                    }
                                    <div className="phone-number-wrapper">
                                        <label htmlFor="phone-number">Номер телефона</label>
                                        <input
                                            maxLength="12"
                                            className={`phone-number ${this.state.isPhoneValid ? '' : 'get-code-error'}`}
                                            value={this.state.phone}
                                            onChange={({target: {value}}) => this.handlePhoneChange(value)}/>
                                    </div>
                                    <div className="comment-wrapper">
                                        <label htmlFor="comment">Комментарий (причина запроса)</label>
                                        <input
                                            className={`comment ${this.state.isCommentValid ? '' : 'get-code-error'}`}
                                            value={this.state.comment}
                                            onChange={({target: {value}}) => this.handleCommentChange(value)}
                                        />
                                    </div>
                                    {
                                        this.state.showGetCodeBtn &&
                                        <div style={{width: "272px"}}>
                                            <button className="get-code" onClick={this.requestSmsCode}>
                                                Получить код
                                            </button>
                                        </div>
                                    }
                                    {
                                        this.state.showConfirmBySmsBlock &&
                                        <div style={{width: "272px"}}>
                                            <div className="confirm-block">
                                                <div>
                                                    <div className="confirm-header-wrapper">
                                                        <div className="confirm-header">Код успешно отправлен на ваш
                                                            номер.
                                                        </div>
                                                        <div className="confirm-header">Отправить повторно можно
                                                            через {this.state.seconds} секунд.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sms-confirm-wrapper">
                                                    <label htmlFor="confirm">Введите код доступа</label>
                                                    <input
                                                        className={`confirm`}
                                                        value={this.state.code}
                                                        maxLength="6"
                                                        onChange={({target: {value}}) => this.handleCode(value)}
                                                    />
                                                </div>
                                                <button className="confirm-code" onClick={this.submitCode}>
                                                    Готово
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </div>
        )
    }
}

export default RequestSms
