import React from 'react'
import MetaTags from "react-meta-tags";

const AboutTags = (props) => {
    let title = "О проекте «Мой дом Ситилинк»"
    let canonical = "https://moidom.citylink.pro/about";
    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content="«Мой Дом» — это система видеонаблюдения за городом. В рамках этого проекта устанавливаются камеры видеонаблюдения во дворах и на улицах города."/>
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default AboutTags;