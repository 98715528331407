import React from 'react'
import MetaTags from "react-meta-tags";
import {CURRENT_PROVIDER} from "../../../helpers/constants/HeaderConstants";

const NearbyCamerasTags = (props) => {
    let title = `Камеры рядом смотреть онлайн - Мой дом ${CURRENT_PROVIDER}.`;
    let canonical = `https://moidom.citylink.pro/nearby-cameras`;

    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content="Смотрите камеры онлайн в своём районе города бесплатно. Скачивайте архив записей с камер видеонаблюдения."/>
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default NearbyCamerasTags;


