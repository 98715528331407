import React, {Component} from 'react'
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '../sass/VideoPlayer.css';

window.videojs = videojs;
require('videojs-hotkeys/build/videojs.hotkeys.min.js');

class PublicPlayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            player: undefined
        }
    }

    startVideo = video => {
        if (video) {
            const ctx = this
            videojs(video, {
                playbackRates: [],//!MOBILE ? [0.5, 1, 2, 5, 10, 16] : [],
                // html5: {
                //     vhs: {
                //         withCredentials: true
                //     }
                // },
            }).ready(function () {
                this.hotkeys({
                    volumeStep: 0.1,
                    seekStep: 5,
                    enableModifiersForNumbers: false
                });

                const player = this
                ctx.setState({
                    player
                })
            });
        }
    }

    componentWillUnmount() {
        const player = this.state.player

        if (player !== undefined) {
            player.dispose()
        }
    }

    render() {
        let previewUrl = this.props.poster;
        let source = this.props.source;

        return (
            <video id="camera_pub" tabIndex="1"
                   ref={this.startVideo}
                   width={this.props.width}
                   height={this.props.height}
                   className={"video-js vjs-moidom-skin vjs-big-play-centered noAudio"}
                   autoPlay controls poster={previewUrl}>
                <source src={source} type={this.props.type}/>
            </video>
        );
    }
}

PublicPlayer.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default PublicPlayer;
