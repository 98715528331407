/**
 * Экшен генерации ссылок быстрого доступа для юрлиц
 */
import axios from 'axios';

export const PROTOCOL = 'https://';
export const DOMAIN = 'moidom.karelia.pro';
export const GET_QUICK_ACCESS_CODE_FOR_UR_DATA = PROTOCOL + DOMAIN + '/api/?mode=quickAccessCodeForUrData';
export const QUICK_ACCESS_FOR_UR_FETCH_DATA_SUCCESS = 'QUICK_ACCESS_FOR_UR_FETCH_DATA_SUCCESS';
export const QUICK_ACCESS_FOR_UR_IS_LOADING = 'QUICK_ACCESS_FOR_UR_IS_LOADING';
export const QUICK_ACCESS_FOR_UR_HAS_ERRORED = 'QUICK_ACCESS_FOR_UR_HAS_ERRORED';

export function hasErrored(bool) {
    return {
        type: QUICK_ACCESS_FOR_UR_HAS_ERRORED,
        hasErrored: bool
    };
}

export function isLoading(bool) {
    return {
        type: QUICK_ACCESS_FOR_UR_IS_LOADING,
        isLoading: bool
    };
}

export function fetchDataSuccess(items) {
    return {
        type: QUICK_ACCESS_FOR_UR_FETCH_DATA_SUCCESS,
        items
    };
}

export function fetchData(url) {
    return (dispatch) => {
        dispatch(isLoading(true));
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(isLoading(false));

                return response;
            })
            .then((response) => response.data)
            .then((items) => dispatch(fetchDataSuccess(items)))
            .catch(() => dispatch(hasErrored(true)));
    };
}
