import {POINTS_FETCH_DATA_SUCCESS} from '../actions/points';
import {POINTS_UPDATE_DATA_SUCCESS} from '../actions/points';
import {POINTS_HAS_ERRORED} from '../actions/points';
import {POINTS_IS_LOADING} from '../actions/points';

export function pointsHasErrored(state = false, action) {
    switch (action.type) {
        case POINTS_HAS_ERRORED:
            return action.hasErrored;

        default:
            return state;
    }
}

export function pointsIsLoading(state = false, action) {
    switch (action.type) {
        case POINTS_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function points(state = [], action) {
    switch (action.type) {
        case POINTS_FETCH_DATA_SUCCESS:
            return action.points;

        case POINTS_UPDATE_DATA_SUCCESS:
            return Object.assign({}, state, action.points);

        default:
            return state;
    }
}
