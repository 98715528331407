import React, {Component} from 'react'
import {BROWSER, MOBILE} from '../../helpers/constants/HeaderConstants'
import CodeTags from "../Header/Metatags/CodeTags";

import '../../sass/Code.css'
import '../../sass/Archive.css'
import {mdApi} from "../../index";
import {history} from "../../routes";
import RequestSms from "./RequestSms";

class SmsLogin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            code: '',
            showInstruction: false
        }
    }

    /**
     * Скрыть блок ввода кода при нажатии "как получить код?"
     */
    handleHideBlock = () => {
        this.setState({
            showCodeBlock: false
        })
    }

    handlePhone = (phone) => {
        this.setState({phone})
    }

    handleCode = (code) => {
        this.setState({code})
    }

    submitCode = () => {
        let code = this.state.code

        mdApi.makeLoginByCode(code)
            .then(() => {
                history.push('/cameras')
            })
            .catch(async (response) => {
                let data = await response.json()

                alert(data.message)
            })
    }

    toggleInstruction = () => {
        this.setState({
            showInstruction: !this.state.showInstruction
        })
    }

    render() {
        return (
            <>
                <CodeTags/>
                {
                    BROWSER &&
                    <div className="content-wrapper wide">
                        <div className="code-page">
                            {
                                !this.state.showInstruction &&
                                <>
                                    <div>Введите код доступа</div>
                                    <input id="code"
                                           autoComplete="off"
                                           placeholder="Код"
                                           onChange={({target: {value}}) => this.handleCode(value)}/>
                                    <button className="search"
                                            onClick={this.submitCode}>
                                        Готово
                                    </button>
                                    <div className={`get-code-block`} onClick={this.toggleInstruction}>
                                        <div className="get-code-btn">
                                            Как получить код доступа?
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                this.state.showInstruction &&
                                <RequestSms/>
                            }
                        </div>
                    </div>
                }
                {
                    MOBILE &&
                    <div className="mobile-wrapper">
                        <div className="code-page">
                            {
                                !this.state.showInstruction &&
                                <>
                                    <div>Введите номер телефона и код доступа</div>
                                    <input id="code"
                                           autoComplete="off"
                                           placeholder=""
                                           onChange={({target: {value}}) => this.handlePhone(value)}/>
                                    <br/>
                                    <input id="code"
                                           autoComplete="off"
                                           placeholder=""
                                           onChange={({target: {value}}) => this.handleCode(value)}/>
                                    <button className="search"
                                            onClick={this.submitCode}>
                                        Готово
                                    </button>
                                    <div className={`get-code-block mobile`} onClick={this.toggleInstruction}>
                                        <div className="get-code-btn">
                                            Как получить код доступа?
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                this.state.showInstruction &&
                                <RequestSms/>
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default SmsLogin
