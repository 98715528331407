import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {BROWSER, connectionUrl, MOBILE} from '../helpers/constants/HeaderConstants';

import '../sass/MyCameras.css'
import NearbyCamerasTags from "./Header/Metatags/NearbyCamerasTags";
import {mdApi} from "../index";
import {NEARBY_CAMERAS_PATH} from "../routes";

export default class NearbyCameras extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cameras: []
        }
    }

    componentDidMount() {
        mdApi.nearbyCamerasAll()
            .then(cameras => this.setState({cameras}))
    }

    render() {
        let items = this.state.cameras.map(camera => {
            let cameraId = camera.id
            return <li className="items-grid__item" key={cameraId}>
                <Link className="items-grid__item_link" to={`${NEARBY_CAMERAS_PATH}/${cameraId}`}>
                    <img src={camera.img} alt={camera.name}/>
                    <span className="items-name">{camera.name}</span>
                </Link>
                <Link className="items-grid__item_arcLink" to={`${NEARBY_CAMERAS_PATH}/${cameraId}/archive`}>Архив </Link>
                {
                    !MOBILE &&
                    <Link className="items-grid__item_arcLink screen" to={`${NEARBY_CAMERAS_PATH}/${cameraId}/screen`}>
                        Снимки
                    </Link>
                }
            </li>
        })

        return (
            <div>
                <NearbyCamerasTags/>
                {
                    BROWSER &&
                    <div>
                        <div className="content-wrapper nearby_cameras">
                            {
                                items.length !== 0 &&
                                <ul className="items-grid">
                                    {items}
                                    <li className="items-grid__item">
                                        <a href={connectionUrl} className="items-grid__item_outerLink">
                                            <span className="informer cameras">Установить дополнительные камеры видеонаблюдения в ваш двор</span>
                                            <span className="button cameras">Установить</span>
                                        </a>
                                    </li>
                                </ul>
                            }
                            {
                                items.length === 0 &&
                                <ul className="items-grid">
                                    <li className="items-grid__item">
                                        <div className="items-grid__item_outerLink">
                                          <span className="informer_no_cameras">
                                            Камеры появятся здесь после подключения соседних домов к проекту «Мой Дом»
                                          </span>
                                        </div>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                }
                {
                    MOBILE &&
                    <div>
                        <div className="mobile-wrapper nearby_cameras">
                            {
                                items.length !== 0 &&
                                <ul className="items-grid">
                                    {items}
                                    <li className="items-grid__item">
                                        <a href={connectionUrl} className="items-grid__item_outerLink">
                                            <span className="informer cameras">Установить дополнительные камеры видеонаблюдения в ваш двор</span>
                                            <span className="button cameras">Установить</span>
                                        </a>
                                    </li>
                                </ul>
                            }
                            {
                                items.length === 0 &&
                                <ul className="items-grid">
                                    <li className="items-grid__item">
                                        <div className="items-grid__item_outerLink">
                                          <span className="informer_no_cameras">
                                            Камеры появятся здесь после подключения соседних домов к проекту «Мой Дом»
                                          </span>
                                        </div>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}
