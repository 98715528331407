import React, {Component} from 'react';
import {mdApi} from "../index";
import VideoPlayer from "./VideoPlayer";

export default class FramePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cameras: [],
            frameAvailable: true,
            notificationMessage: undefined
        }
    }

    loginFrame = (credential) => {
        let frameIdentity = this.props.match.params.frameIdentity
        return mdApi.frameLogin(frameIdentity, credential)
    }

    makeLogin = () => {
        let credential = window.prompt("Введите код доступа или пароль")
        this.loginFrame(credential)
            .then(this.updateFrame)
            .catch(async response => {
                let data = await response.json()
                alert(data.message)
            })
    }

    fetchFrame = (frameIdentity) => {
        return mdApi.frameByIdentity(frameIdentity)
    }

    updateFrame = () => {
        let frameIdentity = this.props.match.params.frameIdentity
        this.fetchFrame(frameIdentity)
            .then(cameras => {
                this.setState({
                    cameras,
                    frameAvailable: true,
                    notificationMessage: undefined
                })
            })
            .catch(async response => {
                let responseCode = response.status
                let notificationMessage = ""

                switch (responseCode) {
                    case 404:
                        notificationMessage = "Запрашиваемая трансляция не существует или удалена";
                        break;
                    case 401:
                        notificationMessage = "Требуется авторизация"
                        break;
                    case 403:
                        notificationMessage = "Доступ к трансляции временно недоступен";
                        break;
                    case 418:
                        notificationMessage = "Требуется авторизация на основном сайте";
                        break;
                    default:
                        const contentType = response.headers?.get("content-type");
                        if (!contentType || contentType.indexOf("application/json") === -1) {
                            notificationMessage = "Запрашиваемая трансляция недоступна на основном сайте"
                        }
                        break;
                }

                this.setState({
                    frameAvailable: false,
                    notificationMessage
                }, () => {
                    if (responseCode === 401) {
                        this.makeLogin()
                    }
                })
            })
    }

    componentDidMount() {
        this.updateFrame()
    }

    render() {
        let cameras = this.state.cameras

        return (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                {
                    this.state.frameAvailable ?
                        <div>
                            {
                                cameras.map((camera,index) => {
                                    return (
                                        <div key={index} className={cameras.length === 0 ? "video-player single" : "video-player"}>
                                            <VideoPlayer width={'770'}
                                                         height={'433'}
                                                         source={camera.source}
                                                         audio={true}
                                                         poster={camera.preview}
                                                         type={'application/x-mpegURL'}
                                                         isArchive={false}
                                                         notAutoPlay={!camera.autoplay}/>
                                            <div className="video-player-info">
                                                <span className="video-player_name">{camera.title}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div> :
                        <div>
                            <h2>
                                {this.state.notificationMessage}
                            </h2>
                        </div>
                }
            </div>
        )
    }
}
