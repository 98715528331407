import React, { PureComponent } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { itemsFetchData, GET_SOSED_URL, GET_SOSED_COMMENT_URL, SET_VIEW_TRUBA_URL } from '../actions/items';
import VideoPlayer from '../components/VideoPlayer';
import { MOBILE } from '../helpers/constants/HeaderConstants';
import axios from 'axios';

class SosedVideo extends PureComponent {
    componentDidMount() {
        let _items = this.props.items;
        let sosedId = this.props.match.params.sosedId;
          if(_items.length === 0 || _items[sosedId] === undefined)
              this.props.fetchData(GET_SOSED_URL + '&single=' + sosedId);

      axios.get(SET_VIEW_TRUBA_URL + '&sosed=1&id=' + sosedId)
      .then((response) => {
          if (response.status !== 200) {
              throw Error(response.statusText);
          }
      });
    }

    constructor(props) {
        super(props);
          this.state = {
              commentName: '',
              commentText: '',
              commentError: null,
              myComments: [],
        }
    }

    handleCommentName = (value) => {
        let _value = value === undefined ? document.getElementById('commentName').value : value;
        let _this = this;
        document.getElementById('commentName').classList.remove('empty');
        clearTimeout(window.timeout);

        window.timeout = setTimeout(function(){
          _this.setState({
              commentName: _value,
          });

        }, 300);
    }

    handleCommentText = value => {
        let _value = value === undefined ? document.getElementById('commentText').value : value;
        let _this = this;
        document.getElementById('commentText').classList.remove('empty');
        clearTimeout(window.timeout);

        window.timeout = setTimeout(function(){
            _this.setState({
                commentText: _value,
            });

        }, 300);
    }

    handleComment = () => {
        if(this.state.commentName.length < 3)
        {
            document.getElementById('commentName').classList.add('empty');
            return;
        }
        if(this.state.commentText.length < 3)
        {
            document.getElementById('commentText').classList.add('empty');
            return;
        }

        let params = "id="+ this.props.match.params.sosedId
             +"&name="+encodeURIComponent(this.state.commentName)
             +"&text="+encodeURIComponent(this.state.commentText)

        axios.post(GET_SOSED_COMMENT_URL, params)
            .then((response) => {
             if (response.status !== 200) {
                 throw Error(response.statusText);
             }
             return response;
            })
            .then((response) => {
             if(response.data === 'error')
             {
                   this.setState({
                       commentError: 'Произошла ошибка при добавлении комментария, пожалуйста, обновите страницу и попробуйте позже',
                   });
                   return;
             }
             else if(response.data === 'flood')
             {
                   this.setState({
                       commentError: 'Вы слишком часто отправляете комментарии, попробуйте позже!',
                   });
                   return;
             }
             else
             {
                 let _myComments = this.state.myComments;
                 let now = new Date();
                 let year = "" + now.getFullYear();
                 let month = "" + (now.getMonth() + 1); if (month.length === 1) { month = "0" + month; }
                 let day = "" + now.getDate(); if (day.length === 1) { day = "0" + day; }
                 let hour = "" + now.getHours(); if (hour.length === 1) { hour = "0" + hour; }
                 let minute = "" + now.getMinutes(); if (minute.length === 1) { minute = "0" + minute; }

                  _myComments.push(
                    <div className="comment">
                         <span className="comment-name">{this.state.commentName}</span>
                         <span className="comment-date">{year + '.' + month + '.' + day + '  ' + hour + ':' + minute}</span>
                         <span className="comment-text">{this.state.commentText}</span>
                     </div>
                  );

                 this.setState({
                   commentError: null,
                   commentText: '',
                   myComments: _myComments,
                 })
             }
            });
        return;
    }

render() {
    if(MOBILE)
        this.props.history.push('/');

    const id = this.props.match.params.sosedId;
    const _items = this.props.items;

    let randomProperty = (obj) => {
        let keys = Object.keys(obj);
        return keys[ keys.length * Math.random() << 0];
    };

    let randomItems = [];
    let length = Object.keys(_items).length;

    if(length)
    {
        let i = 0;
        while( i < length - 1)
        {
            let _randomItem = randomProperty(_items);

            if(_randomItem !== id && randomItems.indexOf(_randomItem) < 0)
            {
                randomItems.push(_randomItem);
                i++;
            }
        }
    }

    let _randomItems = [];

    randomItems.forEach((item) =>
    {
      _randomItems.push(<li className="items-grid__item" key={item}>
            <Link className="items-grid__item_link" to={`/sosed/${item}`}>
                <img src={this.props.items[item].img} alt={this.props.items[item].name}/>
                <span className="items-name truba-mode">{this.props.items[item].name.replace('&#171;', '"').replace('&#187;','"')}</span>
            </Link>
        </li>);
    });

    return (
      <div>
          <div className="content-wrapper" key={id}>
          <div className={_randomItems.length === 0 ? "video-player single": "video-player"}>
            {id && _items[id] !== undefined &&
                <VideoPlayer width={'770'}
                  height={'433'}
                  source={_items[id].src}
                  audio={false}
                  poster={_items[id].img}
                  type={_items[id].type}
                  isArchive={true}/>
              }
              {id && _items[id] !== undefined && (
                  <div className="video-player-info">
                      <span className="video-player_name">{_items[id].name}</span>
                      <div className="video-player-counters">
                          <span className="video-player_date">{_items[id].date}</span>
                          <span className="video-player_views">{_items[id].views}</span>
                      </div>
                      <div className="video-player_descr">
                            {_items[id].descr.split('\n').map((item, key) => {
                              return <span key={key}>{item}<br/></span>
                            })}
                      </div>
                  </div>
              )}
              {id && _items[id] !== undefined &&
                  <div className="video-comments-wrapper">
                      <div className="video-comments-header">Комментарии</div>
                      <div className="comments-list" key={this.state.myComments.length}>
                       {Object.keys(_items[id].comments).map((item, key) =>
                           <div className="comment" key={key}>
                               <span className="comment-name">{_items[id].comments[item].name}</span>
                               <span className="comment-date">{_items[id].comments[item].date}</span>
                               <span className="comment-text">{_items[id].comments[item].text}</span>
                           </div>
                      )}
                      {this.state.myComments}
                      </div>
                      <div className="comment-add-wrapper">
                          <input type="text" placeholder="Ваше имя" id="commentName" defaultValue={this.state.commentName} onChange={({target: {value}}) => this.handleCommentName(value)}/>
                          <input type="text" placeholder="Добавить комментарий" id="commentText" defaultValue={this.state.commentText} onChange={({target: {value}}) => this.handleCommentText(value)}/>
                          { this.state.commentError && <span className="error-comment">{this.state.commentError}</span>}
                          <button className="comment" onClick={() => this.handleComment() }>Добавить</button>
                      </div>
                  </div>
              }
            </div>
            {this.props.items.length !== 0 && _randomItems.length !== 0 &&
            <ul className="items-grid right-column">
              {this.props.items.length !== 0 &&
              <ul className="items-grid right-column">
                {_randomItems}
              </ul>}
            </ul>}
              {(Object.keys(_items).length > 0 && _items[id] === undefined) && <Redirect push to='/sosedi'/>}
              </div>
        </div>
    );
  }
}

SosedVideo.propTypes = {
    fetchData: PropTypes.func.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        items: state.items,
        hasErrored: state.itemsHasErrored,
        isLoading: state.itemsIsLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (url) => { dispatch(itemsFetchData(url))}
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SosedVideo));
