import '../sass/relantPlug.css';


export default function RelantPlug() {
    return (
        <div className='relantPlug'>  
            <header className='relantPlug__header'>
                <div className='relantPlug__header__logo'></div>
                <div className='relantPlug__header__text'>«Релант» станет частью «Ситилинка»</div>
            </header>

            <section className='relantPlug__info'>
                <span>Уважаемые Клиенты, хотим поделиться радостной новостью!</span>
                <span>1 июня 2022 года «Релант» станет частью «Ситилинка», российской компании родом из Карелии, и теперь:</span>
            </section>

            <section className='relantPlug__card'>
                <ul>
                    <li>качество работы телевидения и Интернета улучшится благодаря модернизациям оборудования на уже подключенных домах;</li>
                    <li>сеть связи будет обновляться и расширяться за счет нового современного и надежного оборудования;</li>
                    <li>количество бесплатных уличных камер будет увеличиваться.</li>
                </ul>
            </section>
            
            <section className='relantPlug__link'>
                <div className='relantPlug__link__text'>
                    <span>Смотреть публичные, дворовое и личные камеры вы можете на сайте </span>
                    <a href='https://moidom.citylink.pro/ap'>moidom.citylink.pro</a>
                </div>
                <a className='relantPlug__link__button' href='https://moidom.citylink.pro/ap'>Перейти на сайт</a>
            </section>

            <footer className='relantPlug__footer'>
                <div className='relantPlug__footer__text'>Подробная информация по телефону:</div>
                <a className='relantPlug__footer__phone' href='tel:+78152567555'>+7 (8152) 56-75-55</a>
            </footer>
        </div>
    );
}