import React, {PureComponent} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {itemsFetchData, GET_TRUBA_URL, GET_PDD_URL, SET_VIEW_TRUBA_URL} from '../actions/items';
import VideoPlayer from '../components/VideoPlayer';
import {
    FacebookShareButton,
    GooglePlusShareButton,
    TwitterShareButton,
    VKShareButton,
    OKShareButton,
    MailruShareButton
} from 'react-share';
import {FacebookIcon, GooglePlusIcon, TwitterIcon, VKIcon, OKIcon, MailruIcon} from 'react-share';
import {BROWSER, MOBILE} from '../helpers/constants/HeaderConstants';
import axios from 'axios';

import '../sass/Pdd.css';
import FaqTags from "./Header/Metatags/FaqTags";
import TrubaListTags from "./Header/Metatags/TrubaListTags";
import TrubaVideoTags from "./Header/Metatags/TrubaVideoTags";

class TrubaVideo extends PureComponent {
    componentDidMount() {
        let _items = this.props.items;
        let trubaId = this.props.match.params.trubaId;
        if (_items.length === 0 || _items.length < 5 || _items[trubaId] === undefined) {
            if (this.props.match.path.match('pdd'))
                this.props.fetchData(GET_PDD_URL + '&single=' + trubaId);
            else
                this.props.fetchData(GET_TRUBA_URL + '&single=' + trubaId);
        } else
            this.generateRandom(trubaId);

        axios.get(SET_VIEW_TRUBA_URL + '&id=' + trubaId)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.items !== prevProps.items)
            this.generateRandom(this.props.match.params.trubaId);

        if (this.props.match.params.trubaId !== prevProps.match.params.trubaId)
            this.generateRandom(this.props.match.params.trubaId);
    }

    constructor(props) {
        super(props);

        this.state = {
            isShare: false,
            randomItems: [],
        }
    }

    handleShare = () => {
        this.setState({isShare: !this.state.isShare});
    }

    generateKey = (pre) => {
        return `truba_${pre}`;
    }

    generateRandom = (id) => {
        let _items = this.props.items

        let randomProperty = (obj) => {
            let keys = Object.keys(obj);
            return keys[keys.length * Math.random() << 0];
        };

        let randomItems = [];
        let length = Object.keys(_items).length;

        if (length) {
            length = length > 6 ? 6 : length - 1;

            let i = 0;
            while (i < length) {
                let _randomItem = randomProperty(_items);

                if (_randomItem !== id && randomItems.indexOf(_randomItem) < 0) {
                    randomItems.push(_randomItem);
                    i++;
                }
            }
        }

        let _randomItems = [];

        randomItems.forEach((item) => {
            _randomItems.push(<li className="items-grid__item" key={item}>
                <Link onClick={this.handleOther} className="items-grid__item_link"
                      to={this.props.match.path.match('pdd') ? `/pdd/${item}` : `/video/${item}`}>
                    <img src={this.props.items[item].img} alt={this.props.items[item].name}/>
                    <span
                        className="items-name truba-mode">{this.props.items[item].name.replace(/&#171;/g, '"').replace(/&#187;/g, '"')}</span>
                </Link>
            </li>);
        });

        this.setState({randomItems: _randomItems});
    }

    render() {
        const id = this.props.match.params.trubaId;
        const _items = this.props.items;
        let shareUrl = 'https://' + window.location.hostname + (this.props.match.path.match('pdd') ? '/pdd/' : '/video/') + id;
        const item = _items[id]

        return (
            <div>
                { id && item !== undefined && <TrubaVideoTags videoName={item.name}/> }
                {
                    BROWSER &&
                    <div className="content-wrapper" key={this.generateKey(id)}>
                        <div className={this.state.randomItems.length === 0 ? "video-player single" : "video-player"}>
                            {
                                id && item !== undefined &&
                                <VideoPlayer width={'770'}
                                             height={'433'}
                                             source={item.src}
                                             audio={false}
                                             poster={item.img}
                                             type={'video/mp4'}
                                             isArchive={true}/>
                            }
                            {
                                id && item !== undefined && (
                                    <div className="video-player-info">
                                        <span className="video-player_name">{item.name}</span>
                                        <div className="video-player-counters">
                                            <span className="video-player_date">{item.date}</span>
                                            <span className="video-player_views">{item.views}</span>
                                        </div>
                                        <div className="video-player-links">
                                            <a className="video-player_link" href={item.download}>Скачать</a>
                                            <a className="video-player_link" href="# "
                                               onClick={() => this.handleShare()}>Поделиться</a>

                                            {this.state.isShare &&
                                            <div className="social-wrapper">
                                                <VKShareButton
                                                    url={shareUrl}
                                                    title={item.name}
                                                    image={item.img}
                                                    windowWidth={660}
                                                    windowHeight={460}
                                                    className="social-wrapper__item">
                                                    <VKIcon
                                                        size={32}
                                                        round/>
                                                </VKShareButton>
                                                <FacebookShareButton
                                                    url={shareUrl}
                                                    quote={item.name}
                                                    className="social-wrapper__item">
                                                    <FacebookIcon
                                                        size={32}
                                                        round/>
                                                </FacebookShareButton>
                                                <TwitterShareButton
                                                    url={shareUrl}
                                                    title={item.name}
                                                    className="social-wrapper__item">
                                                    <TwitterIcon
                                                        size={32}
                                                        round/>
                                                </TwitterShareButton>
                                                <GooglePlusShareButton
                                                    url={shareUrl}
                                                    className="social-wrapper__item">
                                                    <GooglePlusIcon
                                                        size={32}
                                                        round/>
                                                </GooglePlusShareButton>
                                                <OKShareButton
                                                    url={shareUrl}
                                                    title={item.name}
                                                    image={item.img}
                                                    windowWidth={660}
                                                    windowHeight={460}
                                                    className="social-wrapper__item">
                                                    <OKIcon
                                                        size={32}
                                                        round/>
                                                </OKShareButton>
                                                <MailruShareButton
                                                    url={shareUrl}
                                                    title={item.name}
                                                    className="social-wrapper__item">
                                                    <MailruIcon
                                                        size={32}
                                                        round/>
                                                </MailruShareButton>
                                            </div>}
                                        </div>
                                        <div
                                            className={this.props.match.path.match('pdd') ? "video-player_descr pdd" : "video-player_descr"}>
                                            {item.camera !== undefined && item.tags === 'dtpout' &&
                                            <div>
                                                Снято с камеры: <Link className="public-link"
                                                                      to={`/pub/${item.camera}/noredirect`}>{item.descr}</Link><br/><br/>
                                                Смотри за городом онлайн на <Link className="public-link"
                                                                                  to='/public'>https://citylink.camera/public</Link>
                                            </div>
                                            }
                                            {this.props.match.path.match('pdd') && item.pdd.length !== 0 &&
                                            <div className="pdd">
                                                <div className="pdd-ava">
                                            <span
                                                style={{backgroundImage: "url(" + item.pdd.auto_photo + ")"}}></span>
                                                </div>
                                                <div className="pdd-name">Комментирует:
                                                    <span>{item.pdd.auto_comment}</span>
                                                    <a href={item.pdd.auto_link} target="_blank"
                                                       rel="noopener noreferrer"
                                                       className={item.pdd.auto_back + ' auto'}>{item.pdd.auto_name}</a>
                                                </div>
                                            </div>
                                            }
                                            {item.camera === undefined &&
                                            item.descr.replace(/&#171;/g, '"').replace(/&#187;/g, '"').split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </div>
                                    </div>
                                )}
                        </div>
                        {this.props.items.length !== 0 &&
                        <ul className="items-grid right-column">
                            {this.state.randomItems}
                        </ul>}
                        {/*{(Object.keys(_items).length > 0 && item === undefined) && <Redirect push to='/videos'/>}*/}
                    </div>
                }
                {
                    MOBILE &&
                    <div className="mobile-wrapper">
                        <div className="mobile-video-player">
                            {id && item !== undefined &&
                            <VideoPlayer width={'350'}
                                         height={'197'}
                                         source={item.src}
                                         audio={item.audio}
                                         poster={item.img}
                                         type={'video/mp4'}
                                         isArchive={false}/>
                            }
                            {id && item !== undefined && (
                                <div className="video-player-info">
                                    <span className="video-player_name">{item.name}</span>
                                    <div className="video-player-counters">
                                        <span className="video-player_date">{item.date}</span>
                                        <span className="video-player_views">{item.views}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>}
            </div>
        );
    }
}


TrubaVideo.propTypes = {
    fetchData: PropTypes.func.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    region: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        items: state.items,
        hasErrored: state.itemsHasErrored,
        isLoading: state.itemsIsLoading,
        region: state.region
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (url) => {
            dispatch(itemsFetchData(url))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrubaVideo));
