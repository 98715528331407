import axios from 'axios';

export const GET_REGION_FETCH_SUCCESS = 'GET_REGION_FETCH_SUCCESS';

/**
 * Успешно получен
 *
 * @param region
 * @returns {{type: string, region: *}}
 */
export function regionFetchSuccess(region) {
    return {
        type: GET_REGION_FETCH_SUCCESS,
        region
    };
}

/**
 * Регион Ip
 *
 * @param url
 * @returns {function(...[*]=)}
 */
export function regionFetchData(url) {
    return (dispatch) => {
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.data)
            .then((region) => dispatch(regionFetchSuccess(region)));
    };
}
