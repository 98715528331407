import React from 'react'
import MetaTags from "react-meta-tags";

const RulesTags = (props) => {
    let   companyName = "Ситилинк";
    let   title = "Мой Дом Ситилинк";
    let   canonical = `https://moidom.citylink.pro/rules`;

    return (
        <MetaTags>
            <title>Правила предоставления доступа к видеокамерам | {title}</title>
            <meta name="description"
                  content={`Настоящие правила регламентируют порядок предоставления доступа к записям 
                  с камер наружного видеонаблюдения, принадлежащим ООО «${companyName}».`}/>
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default RulesTags;