import React, { PureComponent } from 'react'
import {MOBILE} from '../helpers/constants/HeaderConstants'
import '../sass/Header.css'
import '../sass/Rules.css'
import CitylinkRules from './Rules/CitylinkRules'
import RulesTags from "./Header/Metatags/RulesTags";

class Rules extends PureComponent {
  render () {
    return (
      <div>
        <RulesTags/>
        <div className={MOBILE ? 'mobile-wrapper' : 'content-wrapper'}>
          <div className="about-page">
            <CitylinkRules/>
          </div>
        </div>
      </div>
    )
  }
}

export default Rules
