import {ITEMS_FETCH_COUNTERS_SUCCESS} from '../actions/counters';

export function counters(state = [], action) {
    switch (action.type) {
        case ITEMS_FETCH_COUNTERS_SUCCESS:
            return action.counters;

        default:
            return state;
    }
}
