import React, {PureComponent} from 'react'
import {MOBILE, connectionUrl} from '../helpers/constants/HeaderConstants'
import {YMInitializer} from 'react-yandex-metrika'

import '../sass/Footer.css'
import InstallCamerasAdMobile from "./Promo/Citylink/ads/InstallCamerasAdMobile";

const year = new Date().getFullYear()

const isProduction = process.env.NODE_ENV === 'production'

class Footer extends PureComponent {
    componentDidMount() {
        if (isProduction) {
            const script = document.createElement('script')

            script.src = '//code.jivosite.com/widget.js'
            script.setAttribute('jv-id', 'UUyvHk6HbE')
            script.async = true

            document.body.appendChild(script)
        }
    }

    render() {
        const a = true
        return (
            <>
                {
                    MOBILE ?
                        <div className='footer-wrapper-mobile'>
                            <footer className='mobile-footer'>
                                <InstallCamerasAdMobile />
                                <div className='mobile-footer__wrapper'>
                                    <a className="camera-link" href={connectionUrl}>Для организаций</a>
                                    <a className="copyright" href="/" target="_blank"
                                       rel="noopener noreferrer">{year} &copy; Инлаб.Про</a>
                                </div>
                            </footer>
                            {
                                isProduction &&
                                <YMInitializer
                                    accounts={[21801802]}
                                    options={{
                                        clickmap: true,
                                        trackLinks: true,
                                        accurateTrackBounce: true,
                                        webvisor: true
                                    }}
                                />
                            }
                        </div>
                        :
                        <div className='footer-wrapper'>
                            <footer className='App-footer'>
                                <div className='App-footer__wrapper'>
                                    <a className="camera-link" href={connectionUrl}>Для организаций</a>
                                    <a className="copyright" href="/" target="_blank"
                                       rel="noopener noreferrer">{year} &copy; Инлаб.Про</a>
                                </div>
                            </footer>
                            {
                                isProduction &&
                                <YMInitializer
                                    accounts={[21801802]}
                                    options={{
                                        clickmap: true,
                                        trackLinks: true,
                                        accurateTrackBounce: true,
                                        webvisor: true
                                    }}
                                />
                            }
                        </div>
                }
            </>
        )
    }
}

export default Footer
