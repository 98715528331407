import React from 'react'
import MetaTags from "react-meta-tags";

const FaqTags = (props) => {
    let title = `FAQ - часто задаваемые вопросы | «Мой Дом Ситилинк»`;
    let canonical = "https://moidom.citylink.pro/faq";

    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content="Узнайте ответы на часто задаваемые вопросы по проекту «Мой Дом»: вопросы по установке камер и скачиванию записей видео с архива."/>
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default FaqTags;