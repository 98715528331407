import React, {Component} from 'react'
import {getPublicCamerasCities} from '../../helpers/functions/getPublicCamerasCities'
import {mdApi} from "../../index";

/**
 * Список городов публичных камер
 */
class PublicCamerasCitiesList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            citiesList: []
        }
    }

    componentDidMount() {
        const provider = 'citylink';

        getPublicCamerasCities(
            provider,
            this.props.handleButtonChange
        ).then((citiesList) => {
            this.setState({citiesList})
        })
    }

    render() {
        return <ul>
            {this.state.citiesList}
        </ul>
    }
}

export default PublicCamerasCitiesList
