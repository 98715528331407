import React from 'react'
import MetaTags from "react-meta-tags";

const PublicMapTags = (props) => {
    const  {city1, city2, titleProvider, cityUrl} = props;

    let canonical = cityUrl ? `https://moidom.citylink.pro/${cityUrl}` : `https://moidom.citylink.pro`;

    let content

    switch (city1) {
        case 'Петрозаводска':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Мурманска':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Кондопоги':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Медвежьегорска':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Сортавалы':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Костомукши':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Питкяранты':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Суоярви':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Лахденпохье':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Апатитов':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Сегежи':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Надвоиц':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Северодвинска':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Полярных Зорей':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Оленегорска':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Архангельска':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Архангельской области.`
            break

        case 'Санкт-Петербурга':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Ленинградской области.`
            break

        case 'Саранска':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мордовии.`
            break

        case 'Кандалакши':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Красного села':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Ленинградской области.`
            break

        case 'Мги':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Ленинградской области.`
            break

        case 'Тихвина':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Ленинградской области.`
            break

        case 'Надыма':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и в Ямало-Ненецком автономном округе.`
            break

        case 'Пангоды':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и в Ямало-Ненецком автономном округе.`
            break

        case 'Заполярного':
            content = `Выберите камеру прямо на карте, смотрите видео онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

    }

    return (
        <MetaTags>
            <title>Камеры на карте {city1} смотреть онлайн бесплатно {titleProvider}</title>
            <meta name="description"
                  content={content}
            />
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default PublicMapTags;


