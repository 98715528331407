import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {
    itemsFetchData,
} from '../actions/items';
import {MOBILE} from '../helpers/constants/HeaderConstants';
import axios from 'axios';
import InputRange from 'react-input-range';

import '../sass/Archive.css';
import ArchiveScreenTags from "./Header/Metatags/ArchiveScreenTags";
import {mdApi} from "../index";
import {CAMERA_TYPE_NEARBY_CAMERAS, CAMERA_TYPE_PUBLIC, CAMERA_TYPE_USER_CAMERAS} from "./UserCameras/UserCamera";
import {NEARBY_CAMERAS_PATH, USER_CAMERAS_PATH} from "../routes";
import InstallCamerasAd from "./Promo/Citylink/ads/InstallCamerasAd";

const daysPerMonth = ['', 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

class ArchiveScreen extends Component {
    /**
     * Удаляем класс для корректировки футера
     */
    componentWillUnmount() {
        const regex = /\w*\/\d*\/\w*\/\d*\-*\d*/i
        const regex2 = /\w*\/\d*\/\w*/i

        let searchedDiv = document.getElementsByClassName('content');
        let divs = Array.prototype.filter.call(searchedDiv, function (el) {
            return el.nodeName === 'DIV'
        });

        if (divs || divs.length > 0) {
            let classes = divs[0].classList;

            if (!window.location.pathname.match(regex) || !window.location.pathname.match(regex2)) {
                if (classes.contains('content_custom_changes')) {
                    classes.remove('content_custom_changes')
                }
            }
        }
    }

    componentDidMount() {
        this.updateCamera()
            .then(() => {
                let camera = this.state.camera

                let cameraType = this.props.cameraType
                let basePath;

                switch (cameraType) {
                    case CAMERA_TYPE_PUBLIC:
                        basePath = '/pub';
                        break;
                    case CAMERA_TYPE_USER_CAMERAS:
                        basePath = USER_CAMERAS_PATH
                        break;
                    case CAMERA_TYPE_NEARBY_CAMERAS:
                        basePath = NEARBY_CAMERAS_PATH
                        break
                }

                if (this.state._search === undefined) {
                    let date = new Date()
                    let y = date.getFullYear()
                    let m = date.getMonth()
                    let d = date.getDate()

                    let from = `${y}${m}${d}`
                    let to = `${y}${m}${d + 1}`;
                    let _search = `${from}-${to}`

                    let url = `${basePath}/${camera.id}/screen/${from}-${to}`

                    this.props.history.push(url);
                    this.setState({
                        _search
                    })
                }
            })

        // костыль! для корректного отображения футера на страницах
        // /admin/camera/6361/screen/20191001-20191004
        // /admin/pub/88/screen/20191001-20191004
        // /admin/view
        const regex = /\w*\/\d*\/\w*\/\d*\-*\d*/i
        const regex2 = /\w*\/\d*\/\w*/i

        let searchedDiv = document.getElementsByClassName('content');
        let divs = Array.prototype.filter.call(searchedDiv, function (el) {
            return el.nodeName === 'DIV'
        });

        if (divs || divs.length > 0) {
            let classes = divs[0].classList;

            if (window.location.pathname.match(regex) != null || window.location.pathname.match(regex2) != null) {
                if (!classes.contains('content_custom_changes')) {
                    classes.add('content_custom_changes')
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state._search === undefined) {
            return
        }
        if (prevProps.match.params.search !== this.props.match.params.search) {
            this.setState({
                _search: this.props.match.params.search,
                currentMonth: this.getCurrentMonth(),
                countDays: this.getCountDays(),
                displayYear: this.getMaxDefaultDate(),
                _download: this.CalculateDownload(),
                values: {
                    min: this.getMinDefaultTime(),
                    max: this.getMaxDefaultTime(),
                },
            })
            document.getElementsByClassName('begin')[0].value = this.getTimeFromVal(this.getMinDefaultTime());
            document.getElementsByClassName('end')[0].value = this.getTimeFromVal(this.getMaxDefaultTime());
        }
    }

    constructor(props) {
        super(props);

        let route = this.props.match;

        this.state = {
            camera: undefined,
            currentMonth: this.getCurrentMonth(),
            countDays: this.getCountDays(),
            displayYear: this.getCurrentYear(),
            _search: route.params.search,
            _download: this.CalculateDownload(),
            values: {
                min: this.getMinDefaultTime(),
                max: this.getMaxDefaultTime(),
            },
            isYear: false,
            screens: [],
        };

        this.changed = value => {
            if (this.state.isYear) {
                this.setState(prevState => ({
                    isYear: !prevState.isYear
                }));
            }
            if (!value)
                return;

            if ((value.min < 0) ||
                (value.max > 31))
                return;

            document.getElementsByClassName('begin')[0].value = this.getTimeFromVal(value.min);
            document.getElementsByClassName('end')[0].value = this.getTimeFromVal(value.max);

            this.setState({
                values: value,
            });
        }

        this.changedMin = e => {
            if (!e)
                return;

            var time = e.target.value;
            var re = /[0-9]?\d[.][0-9]\d/g;

            if (re.test(time) === true)
                this.setState({values: {...this.state.values, min: this.recoverValFromTime(time)}});
            else
                return;
        }

        this.changedMax = e => {
            if (!e)
                return;

            var time = e.target.value;
            var re = /[0-9]?\d[.][0-9]\d/g;

            if (re.test(time) === true)
                this.setState({values: {...this.state.values, max: this.recoverValFromTime(time)}});
            else
                return;
        }
    }

    getMaxDefaultTime = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search;

            var re = /[\d]{8}-[\d]{8}/g;
            if (re.test(_search) === true) {
                var days = _search.substr(15, 2);
                return parseInt(days, 10);
            }

        }

        var time = new Date();
        return time.getDate();
    }

    getMinDefaultTime = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search;

            var re = /[\d]{8}-[\d]{8}/g;
            if (re.test(_search) === true) {

                var days = _search.substr(6, 2);
                return parseInt(days, 10);
            }

        }

        var time = new Date();
        var _return = time.getDate() - 3;

        return _return < 1 ? 1 : _return;
    }

    getMaxDefaultDate = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search;

            var re = /[\d]{8}-[\d]{8}/g;

            if (re.test(_search) === true) {
                var year = _search.substr(0, 4);
                return year;
            }

        }

        var date = new Date();
        year = date.getFullYear();
        return year;
    }

    getCurrentMonth = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search;
            var re = /[\d]{8}-[\d]{8}/g;
            if (re.test(_search) === true) {
                var month = _search.substr(4, 2);


                return month;
            }
        }

        var date = new Date();
        month = date.getMonth() + 1;

        if (month < 10)
            month = '0' + month;

        return month;
    }

    /**
     * Високосный ли год
     */
    isLeapYear = (year) => {
        for (let i = 2016; i <= year; i += 4) {
            if (parseInt(i) === parseInt(year)) {
                return true
            }
        }

        return false
    }

    getCountDays = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search;
            var re = /[\d]{8}-[\d]{8}/g;
            if (re.test(_search) === true) {
                var year = _search.substr(0, 4);
                var month = _search.substr(4, 2);
                var countDays = parseInt(month, 10);

                if (this.isLeapYear(year) && countDays === 2) {
                    countDays = 29;
                } else {
                    countDays = daysPerMonth[countDays];
                }

                return countDays;
            }
        }

        var date = new Date();
        month = date.getMonth() + 1;

        if (month)
            return daysPerMonth[month];
        else
            return 31;
    }


    getCurrentYear = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search;
            var re = /[\d]{8}-[\d]{8}/g;
            if (re.test(_search) === true) {
                var year = _search.substr(0, 4);
                return year;
            }
        }

        var date = new Date();

        return date.getFullYear();
    }

    getCurrentDefaultMonth = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search;

            var re = /[\d]{8}-[\d]{8}/g;

            if (re.test(_search) === true) {
                var month = _search.substr(4, 2);
                return month;
            }
        }
        return '01';
    }

    CalculateDownload = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search;

            var re = /[\d]{8}-[\d]{8}/g;
            if (re.test(_search) === true) {
                var dayBegin = parseInt(_search.substr(6, 2), 10);
                var dayEnd = parseInt(_search.substr(15, 2), 10);

                return Math.abs(dayBegin - dayEnd) <= 3;
            }
        }
        return true;
    }

    getTimeFromVal = value => {
        if (value < 10)
            var day = '0' + value;
        else
            day = value;

        var month = document.getElementsByClassName('calendar-item current')[0];
        if (month !== undefined)
            month = month.dataset.id;
        else
            month = this.state.currentMonth;

        return day + '.' + month;
    }

    recoverValFromTime = time => {
        var _time = time.split('.');
        return parseInt(_time[0] > 31 ? 31 : _time[0], 10);
    }

    handleSearch = (camera) => {
        var dayBegin = this.state.values.min;
        if (dayBegin < 10)
            dayBegin = '0' + dayBegin;

        var dayEnd = this.state.values.max;
        if (dayEnd < 10)
            dayEnd = '0' + dayEnd;

        var begin = document.getElementsByClassName('begin')[0].value;
        var _begin = begin.split('.');

        var month = _begin[1];

        if (!month)
            month = this.state.currentMonth;

        var _search = "" + this.state.displayYear + month + dayBegin + "-" + this.state.displayYear + month + dayEnd;

        var countDays = parseInt(month, 10);

        if (countDays) {
            countDays = daysPerMonth[countDays]

            if (this.isLeapYear(this.state.displayYear) && month === '02') {
                countDays = 29
            }
        }

        this.setState({
            _search: _search,
            isYear: false,
            share: '',
            copied: false,
            currentMonth: month,
            countDays: countDays
        })

        let cameraId = camera.id
        let cameraType = this.props.cameraType
        let basePath;
        let requestMethod;

        let from = _search.split("-")[0]
        let to = _search.split("-")[1]

        switch (cameraType) {
            case CAMERA_TYPE_PUBLIC:
                basePath = '/pub';
                requestMethod = (...args) => {
                    return mdApi.publicCamerasScreenshotsPreview(...args)
                }
                break;
            case CAMERA_TYPE_USER_CAMERAS:
                basePath = USER_CAMERAS_PATH
                requestMethod = (...args) => {
                    return mdApi.userCamerasScreenshotsPreview(...args)
                }
                break;
            case CAMERA_TYPE_NEARBY_CAMERAS:
                basePath = NEARBY_CAMERAS_PATH
                requestMethod = (...args) => {
                    return mdApi.nearbyCamerasScreenshotsPreview(...args)
                }
                break
        }


        let path = `${basePath}/${cameraId}/screen/${_search}`;
        if (this.props.location) {
            path += this.props.location.search;
        }
        if (this.props.match.params.search === undefined)
            this.props.history.replace(path);
        else
            this.props.history.push(path);

        // let url = camera.src + `screenshots/preview?from=${from}&to=${to}`

        if (requestMethod === undefined) {
            return;
        }

        requestMethod(cameraId, from, to)
            .then(preview => {
                this.setState({
                    screens: preview
                })
            })
            .catch(() => {
                this.setState({
                    screens: []
                })
            })

        // axios.get(url)
        //     .then(response => {
        //         this.setState({
        //             screens: response.data
        //         })
        //     })
        //     .catch(error => {
        //     })

        // axios.get(GET_PUBLIC_SCREEN + '&id=' + camera.id + '&range=' + _search)
        //     .then((response) => {
        //         if (response.status !== 200) {
        //             throw Error(response.statusText);
        //         }
        //         return response;
        //     })
        //     .then((response) => {
        //         if (response.data === '-1')
        //             return;
        //
        //         this.setState({
        //             screens: response.data,
        //         });
        //     });


        return;
    }

    handleCalendar = (event) => {
        if (this.state.isYear) {
            this.setState(prevState => ({
                isYear: !prevState.isYear
            }));
        }
        let calendar = document.getElementsByClassName('calendar-item current');
        if (calendar && calendar[0] !== undefined)
            calendar[0].classList.remove('current');

        var c = event.target.dataset.id;

        if (c === undefined) {
            c = event.target.parentNode.dataset.id;
            event.target.parentNode.classList.add('current');
        } else {
            event.target.classList.add('current');
        }

        var countDays = parseInt(c, 10);

        if (this.isLeapYear(this.state.displayYear) && countDays === 2) {
            countDays = 29
        } else {
            countDays = daysPerMonth[countDays]
        }

        this.setState({currentMonth: c});

        if (countDays)
            this.setState({
                countDays: countDays,
            });

        return;
    }

    handleYear = () => {
        this.setState(prevState => ({
            isYear: !prevState.isYear
        }));
    }

    handleYearItem = year => {
        year = year.i
        this.setState({
            displayYear: year,
            isYear: false
        })
        return null
    }

    fetchPublicCamera = (cameraId) => {
        return mdApi.publicCamerasById(cameraId)
    }

    fetchUserCamera = (cameraId) => {
        return mdApi.userCamerasById(cameraId)
    }

    fetchNearByCamera = (cameraId) => {
        return mdApi.nearbyCamerasById(cameraId)
    }

    updateCamera = () => {
        let cameraId = this.props.match.params.cameraId
        let cameraType = this.props.cameraType;

        if (cameraType === CAMERA_TYPE_PUBLIC) {
            return this.fetchPublicCamera(cameraId)
                .then(camera => this.setState({camera}))
        } else if (cameraType === CAMERA_TYPE_NEARBY_CAMERAS) {
            return this.fetchNearByCamera(cameraId)
                .then(camera => this.setState({camera}))
        } else if (cameraType === CAMERA_TYPE_USER_CAMERAS) {
            return this.fetchUserCamera(cameraId)
                .then(camera => this.setState({camera}))
        }
    }

    downloadScreenshots = (camera, from, to) => {
        let cameraId = camera.id
        let cameraType = this.props.cameraType

        if (cameraType === CAMERA_TYPE_PUBLIC) {
            return mdApi.publicCamerasScreenshotsDownload(cameraId, from, to)
        } else if (cameraType === CAMERA_TYPE_NEARBY_CAMERAS) {
            return mdApi.nearbyCamerasScreenshotsDownload(cameraId, from, to)
        } else if (cameraType === CAMERA_TYPE_USER_CAMERAS) {
            return mdApi.userCamerasScreenshotsDownload(cameraId, from, to)
        }
    }

    render() {
        if (MOBILE) {
            this.props.history.push('/');
            return null;
        }

        let camera = this.state.camera

        if (camera === undefined) {
            return null
        }

        let cameraType = this.props.cameraType
        let basePath;

        switch (cameraType) {
            case CAMERA_TYPE_PUBLIC:
                basePath = '/pub';
                break;
            case CAMERA_TYPE_USER_CAMERAS:
                basePath = USER_CAMERAS_PATH
                break;
            case CAMERA_TYPE_NEARBY_CAMERAS:
                basePath = NEARBY_CAMERAS_PATH
                break
        }

        let isPublic = cameraType === CAMERA_TYPE_PUBLIC

        if (this.state._search === undefined) {
            return null
        }

        let from = this.state._search.split("-")[0]
        let to = this.state._search.split("-")[1]

        //let downloadUrl = camera.src + `screenshots/download?from=${from}&to=${to}`

        const id = this.props.match.params.cameraId;
        const current = this.state.currentMonth;
        const items = this.props.items;
        const months = ['', 'январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];

        const calendar = [];
        const yearsList = [];

        for (var i = 1; i <= 12; i++) {
            var date = new Date();

            var day = date.getDate();
            var dayBegin = day - 3;

            if (day < 10)
                day = '0' + day;

            dayBegin = (dayBegin < 0) ? 1 : dayBegin;

            if (dayBegin < 10)
                dayBegin = '0' + dayBegin;

            var month = date.getMonth() + 1;

            if (month < 10)
                month = '0' + month;

            var monthList = months[i];

            var key = i < 10 ? '0' + i : i;

            var year = date.getFullYear();
            // eslint-disable-next-line
            var currentClass = (current !== 0 && key == current) ? " current" : "";

            calendar.push(<div key={key} data-id={key} id={'calendar_' + key} onClick={this.handleCalendar}
                               className={"screen-item calendar-item" + currentClass + " "}
                               data-search={"" + year + month + dayBegin + "-" + year + month + day + ""}>{monthList}</div>);
        }

        let curYear = new Date().getFullYear()

        if (yearsList.length === 0) {
            for (let i = 2015; i <= curYear; i++) {
                yearsList.push(
                    <div key={i} className="year-switch_item" onClick={() => this.handleYearItem({i})}>{i}</div>
                )
            }

            yearsList.reverse()
        }

        let screens = this.state.screens[id];

        let beginUrl
        let endUrl
        if (screens !== undefined) {
            let transformUrl = (url) => {
                if (url.includes("http")) {
                    return url
                }

                let currentUrl = new URL(location.href)
                return `${currentUrl.protocol}//${currentUrl.hostname}${url}`
            }

            beginUrl = transformUrl(screens.begin);
            endUrl = transformUrl(screens.end);
        } else {
            beginUrl = undefined
            endUrl = undefined
        }

        return (
            <div>
                {
                    camera &&
                    <ArchiveScreenTags cameraTitle={camera.name}/>
                }
                <div className="content-wrapper  wide">
                    <div className="content-archive_tool">
                        <div className="input-form__wrapper">
                            <div className="calendar year">
                                <div><label>Выберите год:</label></div>
                                <div className="calendar__switch">
                                    <div className="year-switch" onClick={() => this.handleYear()}>
                                        {this.state.displayYear}
                                    </div>
                                    {this.state.isYear &&
                                        <div className="year-switch-hidden">
                                            {yearsList}
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="calendar">
                                <label>Выберите месяц:</label>
                                {calendar}
                            </div>
                            <div className="timeline">
                                <label>Выберите диапазон дат:</label>
                                <div className="input-range__wrapper">
                                    <InputRange
                                        draggableTrack
                                        allowSameValues={true}
                                        formatLabel={value => this.getTimeFromVal(value)}
                                        maxValue={this.state.countDays}
                                        minValue={1}
                                        onChange={this.changed}
                                        value={this.state.values}/>
                                </div>
                            </div>
                            <div className="user-buttons screen">
                                <label>Выбранный диапазон просмотра:</label>
                                <div className="begin-wrapper">
                                    <input type="text" key={this.state.currentMonth} className="begin screen"
                                           defaultValue={this.getTimeFromVal(this.state.values.min)}
                                           onKeyUp={this.changedMin}/>
                                    <input type="text" className="pseudo-input screen" key={this.state.displayYear}
                                           disabled defaultValue={this.state.displayYear}/>
                                </div>
                                <div className="end-wrapper">
                                    <input key={this.state.currentMonth} className="end screen"
                                           defaultValue={this.getTimeFromVal(this.state.values.max)}
                                           onKeyUp={this.changedMax}/><input className="pseudo-input screen"
                                                                             key={this.state.displayYear} disabled
                                                                             defaultValue={this.state.displayYear}/>
                                </div>

                                <button className="search" onClick={() => this.handleSearch(camera)}>Искать запись
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="video-player archive" key={this.state._search}>
                        {
                            <div className="screen-content-block">
                                {
                                    beginUrl !== undefined && endUrl !== undefined ?
                                        <>
                                            <img className="screen-value begin" width={"400"} height={"230"}
                                                 src={beginUrl} alt="Первый скриншот диапазон"/>
                                            <img className="screen-value begin" width={"400"} height={"230"}
                                                 src={endUrl} alt="Последний скриншот диапазона"/>
                                        </> :
                                        <div className="screen-value" style={{
                                            width: '800px',
                                            height: '230px',
                                            textAlign: 'center',
                                            fontSize: 'x-large'
                                        }}>
                                            Снимки за выбранный период не найдены
                                        </div>
                                }
                                <div className="screen-description">
                                    <div className="screen-description-padding">
                                        Камера делает снимок каждые 10 минут.
                                    </div>

                                    <div>
                                        Следуйте простой инструкции для просмотра всех
                                    </div>
                                    <div className="screen-description-padding">
                                        снимков за интересующий вас
                                        период:
                                    </div>

                                    <div className="screen-description-double-padding">
                                        <div className="main-item">
                                            <div className="circle-number-wrapper">
                                                <div className="circle-number">1</div>
                                            </div>
                                            выберите временной интервал;
                                        </div>
                                        <div className="main-item">
                                            <div className="circle-number-wrapper">
                                                <div className="circle-number">2</div>
                                            </div>
                                            нажмите «Искать запись»;
                                        </div>
                                        <div className="main-item">
                                            <div className="circle-number-wrapper">
                                                <div className="circle-number">3</div>
                                            </div>
                                            затем нажмите «Скачать».
                                        </div>
                                    </div>

                                    <div>
                                        Снимки за выбранный период автоматически скачаются в виде архива.
                                    </div>
                                </div>
                            </div>
                            /* screenshot */
                        }

                        {
                            (
                                <>
                                    <div className="video-player-info">
                                        <span className="video-player_name">{camera.name}</span>
                                        <Link className="video-player_link"
                                              to={`${basePath}/${camera.id}`}>Онлайн</Link>
                                        <Link className="video-player_link"
                                              to={`${basePath}/${camera.id}/archive`}>Архив</Link>
                                        {
                                            isPublic && camera.incident !== 0 &&
                                            <Link className="items-grid__item_arcLink screen"
                                                  to={`/public/videos/${camera.id}`}>
                                                Происшествия
                                            </Link>
                                        }
                                        {
                                            this.state._download &&
                                            <span>
                                    <a className="video-player_link"
                                       style={{cursor: 'pointer'}}
                                       onClick={() => {
                                           this.downloadScreenshots(camera, from, to)
                                       }}>
                                        Скачать
                                    </a>
                                    </span>
                                        }
                                    </div>
                                    <InstallCamerasAd/>
                                </>
                            )}
                    </div>
                </div>
            </div>
        )
    }
}

ArchiveScreen.propTypes = {
    fetchData: PropTypes.func.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    cameraType: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        items: state.items,
        hasErrored: state.itemsHasErrored,
        isLoading: state.itemsIsLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (url) => {
            dispatch(itemsFetchData(url))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArchiveScreen));
