import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import {menuItems as menuItemsList} from "../../../helpers/constants/HeaderConstants";
import {getActiveItem} from "../../../helpers/functions/Header/getActiveItem";

export class MobileCitylink extends Component {
  constructor (props) {
    super(props)

    this.provider = 'citylink'
    this.activeItem = getActiveItem(this.provider)

    this.state = {
      activeItem: this.activeItem
    }
  }

  componentDidMount () {
    this.setState({
      menuItems: this.menuItemsList(this.activeItem)
    })
  }

  menuItemsList = (active) => {
    let menuItems = []

    // настраиваем вывод пунктов меню в мобильной версии
    Object.keys(menuItemsList[this.provider]).map((itemKey) => {
      const item = menuItemsList[this.provider][itemKey]

      let urlTo = item.url

      // актуальный пункт выводим сверху
      if (item.url === active.url) {
      // if (item.url === this.props.activePageKey) {
        menuItems.unshift(
          <div className="burger-items__wrap" key={item.key}>
            {/*<a href="# " onClick={() => this.props.handleBurgerMenuClick(item)}*/}
            {/*   className={this.props.checkActivePage(item.url) ? 'active' : ''}>{item.name}</a>*/}
            <NavLink
              to={urlTo}
              onClick={() => this.props.handleBurgerMenuClick(item)}
              isActive={() => this.props.checkActivePage(item.url)}
            >
              {item.name}
            </NavLink>
          </div>
        )
        return null
      }

      // остальные пункты по-порядку
      menuItems.push(
          <div className="burger-items__wrap" key={item.key}>
        {/*<a href="# " onClick={() => this.props.handleBurgerMenuClick(item)}*/}
        {/*   className={this.props.checkActivePage(item.url) ? 'active' : ''}>{item.name}</a>*/}
        <NavLink
          to={urlTo}
          onClick={() => this.props.handleBurgerMenuClick(item)}
          isActive={() => this.props.checkActivePage(item.url)}
        >
          {item.name}
        </NavLink>
      </div>)
    })

    return menuItems
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.pathname !== prevProps.pathname || this.props.isAuthorised !== prevProps.isAuthorised) {
      const activeItem = getActiveItem(this.provider)

      this.setState({
        activeItem: activeItem,
        menuItems: this.menuItemsList(activeItem)
      })
    }
  }

  render () {
    return (
      <div className="mobile-header_bottom">
        <div className="burger-menu-wrapper">
          <div className="menu-burger">
            <div className={this.props.displayMobileMenu ? 'left-wrapper left-wrapper__display-items' : 'left-wrapper'}>
              <div className={this.props.displayMobileMenu ? 'burger-btn change' : 'burger-btn'}
                   onClick={() => this.props.handleToggleDisplayBurgerMenu()}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
              {
                !this.props.displayMobileMenu &&
                <div className="menu-burger__active">{this.state.activeItem.name}</div>
              }
              {
                this.props.displayMobileMenu &&
                <div className="burger-items">
                  {this.state.menuItems}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileCitylink
