import axios from 'axios';

import {HOST_TO_OLD_API} from '../helpers/constants/HeaderConstants'

export const ITEMS_FETCH_DATA_SUCCESS = 'ITEMS_FETCH_DATA_SUCCESS';
export const ITEMS_UPDATE_DATA_SUCCESS = 'ITEMS_UPDATE_DATA_SUCCESS';
export const ITEMS_IS_LOADING = 'ITEMS_IS_LOADING';
export const ITEMS_HAS_ERRORED = 'ITEMS_HAS_ERRORED';
export const SHARE_LINK = 'https://' + window.location.hostname + '/share/';

export const GET_SHARE_LINK = HOST_TO_OLD_API + '/api/?mode=getShareLink';

export const GET_TRUBA_URL = HOST_TO_OLD_API + '/api/?mode=getTruba';
export const GET_PDD_URL = HOST_TO_OLD_API + '/api/?mode=getSosed';
export const GET_SOSED_URL = HOST_TO_OLD_API + '/api/?mode=getSosed';

export const GET_SHARE_URL = HOST_TO_OLD_API + '/api/?mode=setShare';
export const GET_SEARCH_STREET_URL = HOST_TO_OLD_API + '/api/?mode=getStreetSearch';
export const GET_SOSED_SEND_URL = HOST_TO_OLD_API + '/api/?mode=setSosed';
export const GET_SOSED_COMMENT_URL = HOST_TO_OLD_API + '/api/?mode=addComment';
export const SET_VIEW_TRUBA_URL = HOST_TO_OLD_API + '/api/?mode=setView';


export function itemsHasErrored(bool) {
    return {
        type: ITEMS_HAS_ERRORED,
        hasErrored: bool
    };
}

export function itemsIsLoading(bool) {
    return {
        type: ITEMS_IS_LOADING,
        isLoading: bool
    };
}

export function itemsFetchDataSuccess(items) {
    return {
        type: ITEMS_FETCH_DATA_SUCCESS,
        items
    };
}


export function itemsUpdateDataSuccess(items) {
    return {
        type: ITEMS_UPDATE_DATA_SUCCESS,
        items
    };
}

export function itemsFetchData(url) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(itemsIsLoading(false));

                return response;
            })
            .then((response) => response.data)
            .then((items) => dispatch(itemsFetchDataSuccess(items)))
            .catch(() => dispatch(itemsHasErrored(true)));
    };
}


export function itemsUpdateData(url) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(itemsIsLoading(false));

                return response;
            })
            .then((response) => response.data)
            .then((items) => dispatch(itemsUpdateDataSuccess(items)))
            .catch(() => dispatch(itemsHasErrored(true)));
    };
}
