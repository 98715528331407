import React, {Component} from 'react'
import '../../../sass/AttentionPopUp.css'

import AttentionPopUpBrowser from "./AttentionPopUpBrowser";
import AttentionPopUpMobile from "./AttentionPopUpMobile";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {MOBILE} from '../../../helpers/constants/HeaderConstants'

/**
 * Pop-up обёртка (transition + выбор компонента версии моб/браузер)
 */
export class AttentionPopUpWrapper extends Component {
    render() {
        return (
            <ReactCSSTransitionGroup
                component="div"
                className="attention_popup_wrapper"
                transitionName="attention-popup-transition"
                transitionAppear={true}
                transitionEnter={true}
                transitionLeave={true}
                transitionAppearTimeout={300}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}
            >
                {
                    this.props.showPopUp &&
                    (MOBILE ?
                        <AttentionPopUpMobile
                            textFirst={this.props.textFirst}
                            textSecond={this.props.textSecond}
                            handleClosePopUp={this.props.handleClosePopUp}
                        />
                        :
                        <AttentionPopUpBrowser
                            textFirst={this.props.textFirst}
                            textSecond={this.props.textSecond}
                            handleClosePopUp={this.props.handleClosePopUp}
                        />)
                }
            </ReactCSSTransitionGroup>
        )
    }
}

export default AttentionPopUpWrapper;
