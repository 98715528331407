import React, {Component} from 'react'
import NotificationErrorsBlock from './NotificationErrorsBlock'
import NotificationSuccessBlock from './NotificationSuccessBlock'

import '../../sass/Notifications.css';

/**
 * Блок оповещений
 */
class NotificationBlock extends Component {
    render() {
        return <div className="notification-block">
            {
                this.props.showBlock ?
                    (
                        this.props.errorMessage ?
                            <NotificationErrorsBlock
                                text={this.props.errorMessage}
                            />
                            :
                            <NotificationSuccessBlock
                                text={this.props.successMessage}
                            />
                    )
                    :
                    null
            }
        </div>
    }
}

export default NotificationBlock
