import React from 'react'
import MetaTags from "react-meta-tags";

const TrubaVideoTags = (props) => {
    const videoTitle = props.videoName;
    return (
        <MetaTags>
            <title>{videoTitle} - Смотреть видео с камер города бесплатно</title>
            <meta name="description"
                  content="Смотрите интересные видео с камер видеонаблюдения Петрозаводска и других городов Карелии. Смотрите!"
                  />
            <meta property="og:title" content="Мой Дом"/>
        </MetaTags>
    )
}

export default TrubaVideoTags;