import React from 'react'
import MetaTags from "react-meta-tags";

const SensorsTags = (props) => {
    let title = '';
    let canonical = "https://moidom.citylink.pro/sensors"

    return (
        <MetaTags>
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default SensorsTags;