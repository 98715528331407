import React, {Component} from 'react';
import {BROWSER, MOBILE, connectionUrl, currentProviderTitleFormatted} from '../../helpers/constants/HeaderConstants';

import '../../sass/MyCameras.css';
import MyCamerasTags from "../Header/Metatags/MyCamerasTags";

class UnauthorizedUserCameras extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <MyCamerasTags titleProvider={currentProviderTitleFormatted(true)}/>
                {
                    BROWSER &&
                    <div>
                        <div className="content-wrapper">
                            <ul className="items-grid">
                                <li className="items-grid__item">
                                    <a href={connectionUrl} className="items-grid__item_outerLink">
                                        <span className="informer cameras">Подключите видеонаблюдение «Мой Дом» или войдите в личный кабинет</span>
                                        <span className="button cameras">Подключить</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {
                    MOBILE &&
                    <div>
                        <div className="mobile-wrapper">
                            <ul className="items-grid">
                                <li className="items-grid__item">
                                    <a href={connectionUrl} className="items-grid__item_outerLink">
                                        <span className="informer cameras">Подключите видеонаблюдение «Мой Дом» или войдите в личный кабинет</span>
                                        <span className="button cameras mobile-cameras">Подключить</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default UnauthorizedUserCameras
