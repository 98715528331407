import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { itemsFetchData, GET_SOSED_URL } from '../actions/items'
import {currentProviderTitleFormatted, MOBILE} from '../helpers/constants/HeaderConstants'
import axios from 'axios'

import '../sass/Tools.css'
import '../sass/Archive.css'
import SosedTags from "./Header/Metatags/SosedTags";

class Sosed extends Component {
  componentDidMount () {
    this.props.fetchData(GET_SOSED_URL)

    axios.get(GET_SOSED_URL + '&my=1')
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => {
        this.setState({
          myItems: response.data,
        })
      })
  }

  constructor (props) {
    super(props)

    this.state = {
      isInstruction: false,
      myItems: [],
    }
  }

  handleInstruction = () => {
    this.setState({ isInstruction: !this.state.isInstruction })
  }

  render () {
    if (MOBILE) {
      this.props.history.push('/')
    }

    let items = []

    if (this.props.items.length !== 0) {
      Object.keys(this.props.items).reverse().map((item) => {
        items.push(
          <li className="items-grid__item" key={item}>
            <Link className="items-grid__item_link" to={`/sosed/${item}`}>
              <img src={this.props.items[item].img} alt={this.props.items[item].name}/>
              <span className="items-name">{this.props.items[item].name}</span>
              <span className="items-user">{this.props.items[item].userName}</span>
              <span className="items-date">{this.props.items[item].date}</span>
              <span className="items-views">{this.props.items[item].views}</span>
            </Link>
          </li>
        )
      })
    }

    let myItems = []

    if (this.state.myItems.length !== 0) {
      Object.keys(this.state.myItems).reverse().map((item) => {
        myItems.push(
          <li className="items-grid__item" key={item}>
            <Link className={this.state.myItems[item].userStatus + ' items-grid__item_link'} to={`/my/${item}`}
                  title={this.state.myItems[item].userReason}>
              <img src={this.state.myItems[item].img} alt={this.state.myItems[item].name}/>
              <span className="items-name">{this.state.myItems[item].name}</span>
              <span className="items-user">{this.state.myItems[item].userName}</span>
              <span className="items-date">{this.state.myItems[item].date}</span>
              <span className="items-views">{this.state.myItems[item].views}</span>
            </Link>
          </li>
        )
      })
    }

    return (
      <div>
        <SosedTags titleProvider={currentProviderTitleFormatted()} />
        <div className="content-wrapper wide">
          <div className="content-truba_tool sosed">
            <div className="content-text">
              <div className="content-text__upper">
                <p>Дворовые камеры часто фиксируют различные ДТП, инциденты и правонарушения, а бывает, что нужно найти
                  свидетелей случившегося.
                  Для решения этой задачи клиентам «Ситилинка» доступен удобный инструмент создания обращения к соседям
                  — «Ищу свидетелей».</p>
                <p>Описывайте происшествие на видеозаписи и просите соседей написать в комментариях, что им известно о
                  случившемся.
                  Ниже размещены Ваши обращения и обращения от соседей. Смотрите видеозаписи и оставляйте комментарии —
                  может помочь любая информация!</p>
              </div>
              <div className="content-text__bottom"></div>
            </div>
          </div>
          <div>
            {
              this.props.items !== undefined && this.props.items !== -1 &&
              <div className="sosed-wrapper">
                <div className="sosed-wrapper_header">Обращения соседей</div>
                {
                  this.props.items.length !== 0 &&
                  <ul className="items-grid tools">
                    {items}
                  </ul>
                }
              </div>
            }
            {this.props.isLoading && <div className="loading">Загрузка</div>}
            {this.props.hasErrored && <div className="error">Ошибка загрузки обращений</div>}

            {
              this.props.items === -1 &&
              <div className="sosed-wrapper">
                <div className="sosed-wrapper_header">Обращения соседей</div>
                Обращений пока нет
              </div>
            }
            {
              this.state.myItems !== undefined &&
              this.state.myItems !== -1 &&
              <div className="sosed-wrapper my-sosed">
                <div className="sosed-wrapper_header">Мои обращения
                  <button
                    className="sosed-create_button"
                    onClick={() => this.handleInstruction()}>Создать</button>
                  {
                    this.state.isInstruction &&
                    <div className="share-wrapper sosed">
                      <div className="share-wrapper_close" onClick={() => this.handleInstruction()}></div>
                      <div className="sosed-instruction__wrapper">Чтобы создать обращение к соседям выполните следующие
                        шаги:
                        <p>1. Выберите камеру, заснявшую происшествие и перейдите во вкладку «Архив».</p>
                        <p>2. Выберите необходимые дату и время для видеозаписи, которую Вы хотите отправить соседям
                          вместе
                          с обращением. Нажмите кнопку «Искать запись».</p>
                        <p>3. Страница обновится, и Вы увидите сбоку информационный блок вместе с кнопкой «Создать
                          обращение». Нажмите на эту кнопку и далее следуйте простым инструкциям.</p>
                      </div>
                    </div>
                  }
                </div>
                {
                  this.state.myItems.length !== 0 &&
                  <ul className="items-grid tools">
                    {myItems}
                  </ul>
                }
              </div>
            }
            {
              this.state.myItems === -1 &&
              <div className="sosed-wrapper my-sosed">
                <div className="sosed-wrapper_header">Мои обращения
                  <button
                    className="sosed-create_button"
                    onClick={() => this.handleInstruction()}
                  >Создать</button>
                  {
                    this.state.isInstruction &&
                    <div className="share-wrapper sosed">
                      <div className="share-wrapper_close" onClick={() => this.handleInstruction()}></div>
                      <div className="sosed-instruction__wrapper">Чтобы создать обращение к соседям выполните следующие
                        шаги:
                        <p>1. Выберите камеру, заснявшую происшествие и перейдите во вкладку «Архив».</p>
                        <p>2. Выберите необходимые дату и время для видеозаписи, которую Вы хотите отправить соседям
                          вместе
                          с обращением. Нажмите кнопку «Искать запись».</p>
                        <p>3. Страница обновится, и Вы увидите сбоку информационный блок вместе с кнопкой «Создать
                          обращение». Нажмите на эту кнопку и далее следуйте простым инструкциям.</p>
                      </div>
                    </div>
                  }
                </div>
                Обращений пока нет
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

Sosed.propTypes = {
  fetchData: PropTypes.func.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    items: state.items,
    hasErrored: state.itemsHasErrored,
    isLoading: state.itemsIsLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (url) => {dispatch(itemsFetchData(url))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sosed)
