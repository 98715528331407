/**
 * Редюсер списка публичных камер
 */
import {
    PUBLIC_CAMS_LIST_FETCH_DATA,
    PUBLIC_CAMS_LIST_UPDATE_DATA,
    PUBLIC_CAMS_LIST_HAS_ERRORED,
    PUBLIC_CAMS_LIST_IS_LOADING
} from '../actions/publicCamsList'

export function itemsHasErrored(state = false, action) {
    switch (action.type) {
        case PUBLIC_CAMS_LIST_HAS_ERRORED:
            return action.hasErrored;

        default:
            return state;
    }
}

export function itemsIsLoading(state = false, action) {
    switch (action.type) {
        case PUBLIC_CAMS_LIST_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function publicCamsList(state = [], action) {
    switch (action.type) {
        case PUBLIC_CAMS_LIST_FETCH_DATA:
            return action.items ? action.items : []

        case PUBLIC_CAMS_LIST_UPDATE_DATA:
            return Object.assign({}, state, action.items)

        default:
            return state
    }
}
