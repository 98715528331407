import React, {Component} from 'react';
import {BROWSER, MOBILE} from '../../helpers/constants/HeaderConstants';
import PropTypes from "prop-types";

import "../../sass/Components/Select.css";

export default class Select extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const selectElem = <select
            className="custom_select__select"
            name="sort"
            onChange={e => this.props.onChange(e.target.value)}
            value={this.props.initValue}
        >
            {
                this.props.values.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    );
                })
            }
        </select>;

        return <>
            {
                BROWSER &&
                <label className="custom_select">
                    {this.props.label}
                    {selectElem}
                </label>
            }
            {
                MOBILE &&
                <label className="custom_select custom_select_mobile">
                    {this.props.label}
                    {selectElem}
                </label>
            }
        </>
    }
}

Select.propTypes = {
    // подпись для селекта
    label: PropTypes.string.isRequired,
    // callback для выбора
    onChange: PropTypes.func.isRequired,
    // инициализирующее значение
    initValue: PropTypes.string.isRequired,
    // список из пар value:label
    values: PropTypes.array.isRequired
}