import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {BROWSER, connectionUrl, MOBILE} from '../../helpers/constants/HeaderConstants';
import axios from 'axios';

import '../../sass/UrCameras.css'
import {mdApi} from "../../index";
import {USER_CAMERAS_PATH} from "../../routes";
import "../../sass/SearchBar.css";
import Select from "../CustomComponents/Select";

class AuthorizedUserCameras extends Component {
    componentDidMount() {
        mdApi.billingStatus()
            .then(billing => {
                const cid = billing?.contract_id

                const billingFailed = cid === undefined || cid === null

                this.setState({
                    blocked: billing.is_blocked,
                    cid,
                    name: billing.name,
                    sum: billing.balance,
                    debt: billing.debt,
                    date: billing.payed_until,
                    billingFailed
                }, () => {
                    this.updateCameras()
                    if (billingFailed) {
                        alert("Произошла ошибка")
                    }
                });
            })
            .catch(() => {
                alert("Произошла ошибка")
                this.setState({
                    billingFailed: true
                })
            })
            .finally(() => {
                this.setState({
                    loaded: true
                })
            })
    }

    updateCameras = () => {
        return this.fetchCameras()
            .then(cameras => {
                this.setState({cameras})
            })
    }

    handleSortBy = (sortBy) => {
        this.setState({
            sortBy: sortBy
        }, this.updateCameras)
    }

    fetchCameras = () => {
        let sortBy = this.state.sortBy;
        if (sortBy === "default") {
            sortBy = null;
        }
        return mdApi.userCamerasAll(sortBy)
    }

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            billingFailed: false,
            cameras: [],
            blocked: false,
            isLegalEntity: mdApi.userRoles.includes(mdApi.ROLE_LEGAL_ENTITY),
            cid: 0,
            name: '',
            sum: 0,
            debt: 0,
            date: '',
            sortBy: "default"
        }
    }

    render() {
        const loaded = this.state.loaded;
        const billingFailed = this.state.billingFailed

        let cameras;

        if (this.state.cameras.length === 0) {
            cameras = [
                <li className="items-grid__item">
                    <div className="no-cameras">
                        <span/>
                    </div>
                </li>
            ]
        } else {
            cameras = this.state.cameras.map((camera) => {
                let cameraId = camera.id
                let cameraName = camera.name

                return <li className="items-grid__item" key={cameraId}>
                    <Link className="items-grid__item_link" to={`${USER_CAMERAS_PATH}/${cameraId}`}>
                        <img src={camera.img} alt={cameraName}/>
                        <span className="items-name">{cameraName}</span>
                    </Link>
                    <Link className="items-grid__item_arcLink" to={`${USER_CAMERAS_PATH}/${cameraId}/archive`}>
                        Архив
                    </Link>
                    {
                        !MOBILE &&
                        <Link className="items-grid__item_arcLink screen"
                              to={`${USER_CAMERAS_PATH}/${cameraId}/screen`}>
                            Снимки
                        </Link>
                    }
                </li>
            });
        }

        let payedUntil = this.state.date

        if (!loaded) {
            return null
        }

        const sortOptions = [
            {
                value: "default",
                label: 'По умолчанию'
            },
            {
                value: "name",
                label: 'По названию'
            }
        ];

        return (
            <div>
                {
                    BROWSER &&
                    <div>
                        <div className="content-wrapper wide">
                            <div className="urcameras ur">
                                <span className="ur-info">№ договора: <strong>{this.state.cid}</strong></span>
                                <span className="ur-info">Наименование: <strong>{this.state.name}</strong></span>
                                <span className="ur-info">Баланс: <span
                                    className={this.state.sum >= 0 ? 'price positive' : 'price negative'}>{this.state.sum} руб.</span></span>
                                <span
                                    className="ur-info payed">{payedUntil !== undefined ? 'Оплачено до ' + this.state.date : ''}</span>
                            </div>
                            {
                                this.state.blocked &&
                                <div className="blocked-page">
                                    <h3>Уважаемый абонент Ситилинка!</h3>
                                    <p>
                                        Задолженность на договоре № {this.state.cid} составляет {this.state.debt} руб.,
                                        поэтому камеры недоступны для просмотра.
                                    </p>
                                    <h3>Сделайте это прямо сейчас одним из удобных для Вас способом:</h3>
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://citylink.pro/petrozavodsk/card/" className="payment card">Банковской
                                            картой</a>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://citylink.pro/petrozavodsk/phones/"
                                           className="payment phone">Со
                                            счёта телефона</a>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://citylink.pro/petrozavodsk/wallets/"
                                           className="payment emoney">Электронные
                                            деньги</a>
                                    </p>

                                    <p>Так же Вы можете воспользоваться <a target="_blank" rel="noopener noreferrer"
                                                                           href="https://citylink.pro/refill">другими
                                        способами пополнения счёта</a>.</p>
                                </div>
                            }
                            <div className="search-bar">
                                <Select
                                    label={"Сортировка:"}
                                    onChange={this.handleSortBy}
                                    initValue={this.state.sortBy}
                                    values={sortOptions}
                                />
                            </div>
                            <ul className="items-grid">
                                {cameras}
                                <li className="items-grid__item">
                                    <a target="_blank" rel="noopener noreferrer" href={connectionUrl}
                                       className="items-grid__item_outerLink">
                                        <span className="informer cameras">Установить дополнительные камеры видеонаблюдения</span>
                                        <span className="button cameras">Установить</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {
                    MOBILE &&
                    <div>
                        <div className="mobile-wrapper wide ur">
                            <div className="urcameras ur">
                            <span
                                className={this.state.date !== '' ? 'ur-info hasDate' : ''}>№ договора: <strong>{this.state.cid}</strong></span>
                                <span className="ur-info">Наименование: <strong>{this.state.name}</strong></span>
                                <span className="ur-info">Баланс: <span
                                    className={this.state.sum >= 0 ? 'price positive' : 'price negative'}>{this.state.sum} руб.</span></span>
                                <span
                                    className="ur-info payed">{this.state.date !== '' ? 'Оплачено до ' + this.state.date : ''}</span>
                            </div>
                            {
                                this.state.blocked &&
                                <div className="blocked-page">
                                    <h3>Уважаемый абонент Ситилинка!</h3>
                                    <p>
                                        Задолженность на договоре № {this.state.cid} составляет {this.state.debt} руб.,
                                        поэтому
                                        камеры недоступны для просмотра.
                                    </p>
                                    <h3>Сделайте это прямо сейчас одним из удобных для Вас способом:</h3>
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://citylink.pro/petrozavodsk/card/" className="payment card">Банковской
                                            картой</a>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://citylink.pro/petrozavodsk/phones/"
                                           className="payment phone">Со счёта
                                            телефона</a>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://citylink.pro/petrozavodsk/wallets/"
                                           className="payment emoney">Электронные
                                            деньги</a>
                                    </p>
                                    <p>Так же Вы можете воспользоваться <a target="_blank" rel="noopener noreferrer"
                                                                           href="https://citylink.pro/refill">другими
                                        способами
                                        пополнения счёта</a>.</p>
                                </div>
                            }
                            <div className="search-bar">
                                <Select
                                    label={"Сортировка:"}
                                    onChange={this.handleSortBy}
                                    initValue={this.state.sortBy}
                                    values={sortOptions}
                                />
                            </div>
                            <ul className="items-grid">
                                {cameras}
                                <li className="items-grid__item">
                                    <a target="_blank" rel="noopener noreferrer" href={connectionUrl}
                                       className="items-grid__item_outerLink">
                                    <span
                                        className="informer cameras">Установить дополнительные камеры видеонаблюдения</span>
                                        <span className="button cameras">Установить</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default AuthorizedUserCameras;
