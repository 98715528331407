import {menuItems} from '../../constants/HeaderConstants'

/**
 * Активный урл
 *
 * @param provider
 */
export const getActiveItem = (provider) => {
    let list = menuItems[provider]
    let res = {}

    list.forEach((item) => {
        if (window.location.pathname.match(item.url) || (window.location.pathname === '/' && item.mainPage)) {
            res = item
            return null
        }

        // костыль для просмотра публичной камеры
        let publicCameraMatch = window.location.pathname.match(/pub\/\d{1,}/);

        if (publicCameraMatch !== null && item.key === 'publist') {
            res = item
            return null
        }

        // костыль для просмотра камеры рядом
        let nearbyCameraMatch = window.location.pathname.match(/camera\/\d{1,}/);

        if (nearbyCameraMatch !== null && item.key === 'nearby-cameras') {
            res = item
            return null
        }

        return null
    })

    return res
}