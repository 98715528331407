import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

export default class FaqItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    toggle = () => {
        this.setState({visible: !this.state.visible});
    }

    componentDidMount() {
        let opened = this.props.opened
        if (opened !== undefined && opened) {
            this.setState({
                visible: Boolean(opened)
            })
        }
    }

    render() {
        let id = this.props.id === undefined ? Math.floor(Math.random()*Number.MAX_SAFE_INTEGER) : this.props.id
        return (
            <div className="faq-item" id={id}>
                <div className={(this.state.visible ? "open " : "") + "faq-item-title"}
                     onClick={this.toggle}>{this.props.title}</div>
                {this.state.visible && <div className="faq-item-answer">{this.props.descr}</div>}
            </div>
        );
    }
}

FaqItem.propTypes = {
    opened: PropTypes.bool,
    id:PropTypes.string
}