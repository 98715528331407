import React, {Component} from 'react'

/**
 * Pop-up мобильной версии
 */
export class AttentionPopUpMobile extends Component {
    render() {
        return (
            <div className="mobile-popup attention-popup-modal">
                <span onClick={() => this.props.handleClosePopUp()} className="balloon-close"></span>
                <div className="outer">
                    <span className="inner">
                        <span className="text-first">
                            {this.props.textFirst}
                        </span>
                        <span className="text-second">
                            {this.props.textSecond}
                        </span>
                    </span>
                </div>
            </div>
        )
    }
}

export default AttentionPopUpMobile;
