import React, {Component} from 'react';
import installCamerasLogo from '../../../../images/citylink/promo_banners/install_cameras_ad/install_cameras_logo.svg'

export default class InstallCamerasAdMobile extends Component {
    render() {
        return (
            <div className="footer-block">
                <div className="footer-ads_block">
                    <span className="footer-ads_block-text">Установите современные<br/>
                        камеры видеонаблюдения</span>
                    <a href="https://camera.citylink.pro/?utm_source=moidom&utm_medium=banner&utm_campaign=vn&utm_content=btn">
                        <input className="footer-ads_block-button" type="button" value="Узнать подробнее"/>
                    </a>
                </div>
            </div>
        )
    }
}