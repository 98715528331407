import React, { Component } from 'react'
import { MOBILE } from '../../helpers/constants/HeaderConstants'

/**
 * Контейнер мобильной версии страницы Faq Ситилинк
 */
export class CitylinkRules extends Component {
  render () {
    return (
      <div className="page">
        <strong className="fl-r ">УТВЕРЖДАЮ</strong><br/>
        <strong className="fl-r ">Директор ООО "Ситилинк" </strong><br/>
        <strong className="fl-r ">П.Н. Гречнев</strong><br/>
        {!MOBILE && <strong className="fl-r ">__________________________</strong>}
        {
          !MOBILE && <div>
            <br/>
            <br/>
          </div>
        }
        <br/>
        <p className="clearfix"><strong>г. Петрозаводск</strong>
          {MOBILE && <br/>}
          <strong className="fl-r ">"01" января 2017 г.</strong></p>

        <h2>Правила предоставления доступа к видеокамерам.</h2>

        <p className="clearfix">Настоящие Правила регламентируют порядок предоставления доступа лицам к записям
          с
          камер наружного видеонаблюдения, принадлежащим ООО «Ситилинк», в том числе к архиву (срок хранения
          видеозаписей - 7 суток).</p>
        <p className="clearfix">Доступ к видеозаписям камер наружного видеонаблюдения осуществляется в следующем
          порядке:</p>

        <p className="clearfix">1) Физическое лицо самостоятельно получает код доступа к камере на ресурсе <a
          rel="noopener noreferrer"
          href="/code">«Мой дом»</a> по номеру телефона: <br/>

          - заполняет адрес камеры, номер телефона, указывает в комментарии причину запроса;<br/>
          - получает SMS с кодом;<br/>
          - вводит код и получает доступ к записям с камер.
        </p>

        <p className="clearfix">2) Физическое лицо лично обращается в офис ООО «Ситилинк» (г. Петрозаводск, ул.
          Чапаева, д. 44) с просьбой предоставить доступ: <br/>
          <p className="clearfix">
            - заполняет заявление, <a rel="noopener noreferrer"
                                      href="https://moidom.citylink.pro/documents/zayavlenye_predostavlenie_dostupa.doc">бланк</a> которого
            предоставляет сотрудник ООО «Ситилинк»;<br/>
            - предоставляет документ, удостоверяющий его личность;<br/>
            - сотрудник ООО «Ситилинк» предоставляет код доступа к видеокамерам.
          </p>
        </p>

        <p className="clearfix">3) Физическое лицо обращается в ООО «Ситилинк» путем направления сообщения по
          электронной почте:<br/>
        </p>
        <p className="clearfix">
          - заполняет направленный ему бланк заявления и отправляет его скан вместе со сканом своего паспорта по
          электронной почте. Или пишет заявление от руки (без бланка) и отправляет его скан по электронной
          почте. Вместо сканов допустимо отправить фотографии<br/><br/>

          - сотрудник ООО «Ситилинк», сверив паспортные данные, направляет в ответ по электронной почте код
          доступа к записям камер наружного видеонаблюдения.</p>

        <p className="clearfix">Заполнив <a rel="noopener noreferrer"
                                            href="https://moidom.citylink.pro/documents/zayavlenye_predostavlenie_dostupa.doc">бланк
          заявления</a>, физическое лицо подтверждает, что ознакомлено с настоящими правилами, а также дает свое
          согласие на обработку его персональных данных ООО «Ситилинк» в соответствии с Федеральным законом «О
          персональных данных» для целей, указанных в заявлении.</p>
        <p className="clearfix">Обработка персональных данных осуществляется следующими способами: сбор, запись,
          систематизация, уточнение, использование, обезличивание, блокирование, удаление, уничтожение либо
          путем
          совершения иных действий (операций) с персональными данными, совершаемых с использованием средств
          автоматизации или без использования оных.</p>
      </div>
    )
  }
}

export default CitylinkRules


