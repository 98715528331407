import {cookies, mdApi} from "../index";

export default class MoidomWebApi {
    ROLE_LEGAL_ENTITY = "ROLE_LEGAL_ENTITY"

    static COOKIE_REFRESH_NAME = "Authorization_WEB_REFRESH"

    static #appUrl = "";
    static #apiUrl = "";
    static #appOrigin = "";

    userLogin = "";
    userName = "";
    userRoles = [];
    isAuthorized = false;
    isInitialized = false;

    onSessionUpdate = undefined
    onSessionClear = undefined

    onRequestStarted = undefined;
    onRequestFinished = undefined;

    constructor(appUrl, apiUrl) {
        MoidomWebApi.#appUrl = appUrl
        MoidomWebApi.#apiUrl = apiUrl

        let url = new URL(appUrl)
        url.origin
        MoidomWebApi.#appOrigin = url.origin
    }

    #updateSession = (session) => {
        this.userLogin = session.login
        this.userName = session.name
        this.userRoles = session.roles
        this.sessionExpiresAt = session.expires_at

        this.isInitialized = true
        this.isAuthorized = true

        if (this.onSessionUpdate !== undefined) {
            this.onSessionUpdate(session)
        }
    }

    #clearSession = () => {
        this.userLogin = ''
        this.userName = ''
        this.userRoles = []
        this.sessionExpiresAt = undefined

        this.isInitialized = false
        this.isAuthorized = false

        if (this.onSessionClear !== undefined) {
            this.onSessionClear()
        }
    }

    /**
     * @param {RequestInfo | URL} uri
     * @param {RequestInit | undefined} config
     * @returns
     */
    fetch = async (uri, config) => {

        if (this.onRequestStarted !== undefined) {
            this.onRequestStarted()
        }

        return fetch(uri, config)
            .finally(() => {
                if (this.onRequestFinished !== undefined) {
                    this.onRequestFinished()
                }
            })
    }

    /**
     * Осуществление запроса
     *
     * @param uri
     * @param config
     * @returns {Promise<Response>}
     */
    request = async (uri, config = {}) => {
        if (config === undefined || config === null) {
            config = {}
        }

        let response = await this.fetch(uri, {
            ...config,
            credentials: "include",
            mode: 'cors'
        })

        if (this.isAuthorized) {
            if (response.status === 401) {
                let newSession = await mdApi.refreshSession()

                if (newSession.ok) {
                    this.#updateSession(newSession)

                    return this.request(uri, config)
                } else {
                    this.#clearSession()

                    return response
                }
            }

            if (response.status === 403) {
                return response
            }

        } else if (!this.isInitialized) {
            if (cookies.get(MoidomWebApi.COOKIE_REFRESH_NAME) !== undefined) {
                if (response.status === 401) {
                    let newSession = await mdApi.refreshSession()

                    if (newSession.ok) {
                        this.#updateSession(newSession)

                        return this.request(uri, config)
                    } else {
                        this.#clearSession()

                        return response
                    }
                }
            }
        }

        return response
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////
    makeLoginByCode = async (code) => {
        return this.loginByAuthCode(code)
            .then((session) => {
                this.#updateSession(session)

                return session
            })
            .catch((response) => {
                this.#clearSession()

                throw response
            })
    }

    makeLogout = () => {
        return mdApi.logout()
            .then(() => {
                this.#clearSession()
            })
            .catch(() => {
            })
    }

    makeSessionRefresh = () => {
        return this.refreshSession()
            .then(session => {
                this.#updateSession(session)
            })
            .catch(() => {
                this.#clearSession()
            })
    }

    checkSession = async () => {
        return mdApi.sessionInfo()
            .then(session => {
                this.#updateSession(session)

                return session
            })
            .catch(response => {
                this.#clearSession()

                throw response
            })
            .finally(() => {
            })
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Авторизация по логин-пароль
     * p.s Если авторизация успешна - её данные будут сохранены
     *
     * @param oAuthPassportCode
     * @returns {Promise<unknown>}
     */
    // authorize = async (oAuthPassportCode) => {
    //     return new Promise((resolve, reject) => {
    //         this.login(oAuthPassportCode)
    //             .then(response => {
    //
    //                 let responseParsed = response.json()
    //                     .then(data => {
    //                         this.#token = data.access_token
    //                         localStorage.setItem('authorization',this.#token)
    //                     })
    //                 this.isAuthorized = true
    //
    //                 this.init()
    //
    //                 if (this.onAuthorizationSuccessful !== undefined) {
    //                     this.onAuthorizationSuccessful()
    //                 }
    //                 resolve()
    //             })
    //             .catch(error => {
    //                 if (this.onAuthorizationFailed !== undefined) {
    //                     this.onAuthorizationFailed()
    //                 }
    //                 reject(error)
    //             })
    //     })
    // }

    /**
     * Отправка запроса на авторизацию используя логин-пароль
     * p.s Осуществляет простой запрос к API
     *
     * @param oAuthPassportCode
     * @returns {Promise<Response>}
     */
    // login = async (oAuthPassportCode) => {
    //     let uri = `${MoidomWebApi.#apiHost}/web/api/login?code=${oAuthPassportCode}`
    //     let config = {
    //         method: 'GET',
    //         credentials: "include",
    //         mode: 'cors',
    //     }
    //
    //     let response = await this.fetch(uri, config)
    //
    //     if (response.ok) {
    //         return Promise.resolve(response)
    //     } else {
    //         let responseParsed = await response.json()
    //         return Promise.reject(responseParsed.message)
    //     }
    // }

    /**
     * Завершение текущей сессии
     *
     * @returns {Promise<void>}
     */
    logout = async () => {
        return this.request(`${MoidomWebApi.#apiUrl}/logout`)
    }

    sessionInfo = async () => {
        let uri = `${MoidomWebApi.#apiUrl}/session`

        let response = await this.fetch(uri, {
            credentials: "include"
        })

        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    refreshSession = async () => {
        let uri = `${MoidomWebApi.#apiUrl}/session/refresh`

        let response = await this.fetch(uri, {
            credentials: "include",
            method: 'POST'
        })

        if (!response.ok) {
            throw response
        }

        return response.json()
    }

    billingStatus = async () => {
        let uri = `${MoidomWebApi.#apiUrl}/billing/status`

        let response = await this.fetch(uri, {
            credentials: "include"
        })
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////
    requestSmsCode = async (phone, houses, comment) => {
        let uri = `${MoidomWebApi.#apiUrl}/request_auth_sms`

        let data = {
            phone,
            houses,
            comment
        }

        let config = {
            body: JSON.stringify(data),
            method: 'POST'
        }

        let response = await this.request(uri, config)

        if (!response.ok) {
            throw response
        }

        return response
    }

    loginByAuthCode = async (code) => {
        let uri = `${MoidomWebApi.#apiUrl}/login_by_code`

        let data = {
            code
        }

        let config = {
            body: JSON.stringify(data),
            method: 'POST'
        }

        let response = await this.request(uri, config)

        if (!response.ok) {
            throw response
        }

        return response.json()
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     *
     * @returns {Promise<Array> | Promise<Object>}
     */
    cities = async () => {
        let uri = `${MoidomWebApi.#apiUrl}/cities/`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        // if desired list is not array - just return it as it is
        if(!Array.isArray(parsedResponse)){
            return parsedResponse
        }

        // https://redmine.citylink.pro/issues/28799
        // The best(and quick) way to get rid of 3 cities everywhere in project, that are not required yet
        //1. необходимо убрать страницы Кандалакши, Заполярного и Пангод с Моего Дома. Там камер нет, они сейчас пустые висят
        parsedResponse = parsedResponse.filter(city => {
            return ![
                'kndl',
                'zpl',
                'png'
            ].includes(city.key)
        })

        return parsedResponse
    }

    /**
     *
     * @param cityKey {string}
     * @returns {Promise<Object>}
     */
    cityByKey = async (cityKey) => {
        let uri = `${MoidomWebApi.#apiUrl}/cities/${cityKey}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /**
     *
     * @param cityId {number}
     * @returns {Promise<Object>}
     */
    cityById = async (cityId) => {
        let uri = `${MoidomWebApi.#apiUrl}/cities/${cityId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * @returns {Promise<Array>}
     */
    presenceCities = async () => {
        let uri = `${MoidomWebApi.#apiUrl}/addresses/presence_cities`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /**
     * @param cityId {Number}
     * @returns {Promise<Array>}
     */
    presenceStreets = async (cityId) => {
        if (cityId === null || cityId === 0) {
            return []
        }

        let uri = `${MoidomWebApi.#apiUrl}/addresses/presence_streets?city_id=${cityId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /**
     *
     * @param streetId {Number}
     * @returns {Promise<Array>}
     */
    presenceHouses = async (streetId) => {
        let uri = `${MoidomWebApi.#apiUrl}/addresses/presence_houses?street_id=${streetId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /**
     * @returns {Promise<Array>}
     */
    accessibleCamerasCities = async () => {
        let uri = `${MoidomWebApi.#apiUrl}/addresses/accessible_cameras_cities`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /**
     * @param cityId {Number}
     * @returns {Promise<Array>}
     */
    accessibleCamerasStreets = async (cityId) => {
        if (cityId === null || cityId === 0) {
            return []
        }

        let uri = `${MoidomWebApi.#apiUrl}/addresses/accessible_cameras_streets?city_id=${cityId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /**
     *
     * @param streetId {Number}
     * @returns {Promise<Array>}
     */
    accessibleCamerasHouses = async (streetId) => {
        let uri = `${MoidomWebApi.#apiUrl}/addresses/accessible_cameras_houses?street_id=${streetId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////

    publicCamerasAll = async (search = undefined, pad = 1) => {
        let uri = `${MoidomWebApi.#apiUrl}/public_cameras/`

        let parameters = new URLSearchParams()

        if (search !== null && search !== "" && search !== undefined) {
            parameters.append('search', search)
        }

        if (pad !== null && pad !== undefined && pad !== 0) {
            parameters.append('pad', `${pad}`)
        }

        if (parameters.keys().next() !== null) {
            uri = uri + "?" + parameters.toString()
        }

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    publicCamerasByCityKey = async (cityKey, search = undefined, pad = undefined) => {
        let uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cityKey}`

        let parameters = new URLSearchParams()

        if (search !== null && search !== "" && search !== undefined) {
            parameters.append('search', search)
        }

        if (pad !== null && pad !== undefined && pad !== 0) {
            parameters.append('pad', `${pad}`)
        }

        if (parameters.keys().next() !== null) {
            uri = uri + "?" + parameters.toString()
        }

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    publicCamerasById = async (cameraId) => {
        let uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    publicCamerasRequestDownloadUrl = async (cameraId, from, to, filename) => {
        let uri;

        if (filename !== undefined) {
            uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/request_download_url?from=${from}&to=${to}&filename=${filename}`
        } else {
            uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/request_download_url?from=${from}&to=${to}`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    publicCamerasRequestPaymentUrl = async (cameraId, redirectUri) => {
        let uri;

        if (redirectUri !== undefined) {
            uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/request_payment_url?redirect=${redirectUri}`
        } else {
            uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/request_payment_url`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    publicCamerasScreenshotsPreview = async (cameraId, from, to) => {
        let uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/screenshots/preview?from=${from}&to=${to}`

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    publicCamerasScreenshotsDownload = async (cameraId, from, to) => {
        let uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/screenshots/download?from=${from}&to=${to}`

        window.open(uri, '_blank')

        return Promise.resolve()
    }

    publicCamerasRequestPlaylistUrl = async (cameraId,from,to) => {
        let uri;
        if(from === null || to === null){
            uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/request_playlist_url`
        }else{
            /**
             *
             * @param date {Date}
             */
            const formatDate = (date) => {
                return Math.floor(date.getTime()/1000)
            }

            let fromFormatted = formatDate(from);
            let toFormatted = formatDate(to);

            uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/request_playlist_url?from=${fromFormatted}&to=${toFormatted}`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        const responseParsed = await response.json()

        return responseParsed.url
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////

    nearbyCamerasAll = async () => {
        let uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    nearbyCamerasById = async (cameraId) => {
        let uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    nearbyCamerasRequestDownloadUrl = async (cameraId, from, to, filename) => {
        let uri;

        if (filename !== undefined) {
            uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}/request_download_url?from=${from}&to=${to}&filename=${filename}`
        } else {
            uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}/request_download_url?from=${from}&to=${to}`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    nearbyCamerasRequestPaymentUrl = async (cameraId, redirectUri) => {
        let uri;

        if (redirectUri !== undefined) {
            uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}/request_payment_url?redirect=${redirectUri}`
        } else {
            uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}/request_payment_url`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    nearbyCamerasScreenshotsPreview = async (cameraId, from, to) => {
        let uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}/screenshots/preview?from=${from}&to=${to}`

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    nearbyCamerasScreenshotsDownload = async (cameraId, from, to) => {
        let uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}/screenshots/download?from=${from}&to=${to}`

        window.open(uri, '_blank')

        return Promise.resolve()
    }

    nearbyCamerasRequestPlaylistUrl = async (cameraId,from,to) => {
        let uri;
        if(from === null || to === null){
            uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}/request_playlist_url`
        }else{
            /**
             *
             * @param date {Date}
             */
            const formatDate = (date) => {
                return Math.floor(date.getTime()/1000)
            }

            let fromFormatted = formatDate(from);
            let toFormatted = formatDate(to);

            uri = `${MoidomWebApi.#apiUrl}/nearby_cameras/${cameraId}/request_playlist_url?from=${fromFormatted}&to=${toFormatted}`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        const responseParsed = await response.json()

        return responseParsed.url
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////

    userCamerasAll = async (sortBy) => {
        let searchOptions = new URLSearchParams()
        if (sortBy !== undefined && sortBy !== null) {
            searchOptions.append('sort_by', sortBy)
        }

        let uri;
        if (searchOptions.keys().next() !== null && searchOptions.keys().next().value !== undefined) {
            uri = `${MoidomWebApi.#apiUrl}/user_cameras/?` + searchOptions.toString()
        } else {
            uri = `${MoidomWebApi.#apiUrl}/user_cameras/`
        }

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            throw response
        }

        return parsedResponse
    }

    userCamerasById = async (cameraId) => {
        let uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    userCamerasRequestDownloadUrl = async (cameraId, from, to, filename) => {
        let uri;

        if (filename !== undefined) {
            uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}/request_download_url?from=${from}&to=${to}&filename=${filename}`
        } else {
            uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}/request_download_url?from=${from}&to=${to}`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    userCamerasRequestPaymentUrl = async (cameraId, redirectUri) => {
        let uri;

        if (redirectUri !== undefined) {
            uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}/request_payment_url?redirect=${redirectUri}`
        } else {
            uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}/request_payment_url`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    userCamerasScreenshotsPreview = async (cameraId, from, to) => {
        let uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}/screenshots/preview?from=${from}&to=${to}`

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        return await response.json()
    }

    userCamerasScreenshotsDownload = async (cameraId, from, to) => {
        let uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}/screenshots/download?from=${from}&to=${to}`

        window.open(uri, '_blank')

        return Promise.resolve()
    }

    userCamerasRequestPlaylistUrl = async (cameraId,from,to) => {
        let uri;
        if(from === null || to === null){
            uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}/request_playlist_url`
        }else{
            /**
             *
             * @param date {Date}
             */
            const formatDate = (date) => {
                return Math.floor(date.getTime()/1000)
            }

            let fromFormatted = formatDate(from);
            let toFormatted = formatDate(to);

            uri = `${MoidomWebApi.#apiUrl}/user_cameras/${cameraId}/request_playlist_url?from=${fromFormatted}&to=${toFormatted}`
        }

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        const responseParsed = await response.json()

        return responseParsed.url
    }

    userWatchCameraOnMap = async (cameraId) => {
        let uri = `${MoidomWebApi.#apiUrl}/public_cameras/${cameraId}/view`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            throw response
        }

        return parsedResponse
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////
    smarthomeDevicesAll = async () => {
        let uri = `${MoidomWebApi.#apiUrl}/smarthome/devices/`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    smarthomeDevicesById = async (deviceId) => {
        let uri = `${MoidomWebApi.#apiUrl}/smarthome/devices/${deviceId}`

        let response = await this.request(uri)
        let parsedResponse = await response.json()
        if (!response.ok) {
            return Promise.reject({message: parsedResponse.message, code: response.status})
        }

        return parsedResponse
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////
    frameByIdentity = async frameIdentity => {
        let uri = `${MoidomWebApi.#appOrigin}/frames/${frameIdentity}`

        let response = await this.request(uri)
        if (!response.ok) {
            throw response
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return await response.json()
        } else {
            throw response
        }
    }

    frameLogin = async (frameIdentity, credential) => {
        let uri = `${MoidomWebApi.#appOrigin}/frames/${frameIdentity}/login`

        let data = new FormData()
        data.append('credential', credential)

        let config = {
            method: 'post',
            body: data
        }

        let response = await this.request(uri, config)
        if (!response.ok) {
            throw response
        }

        return Promise.resolve()
    }
}
