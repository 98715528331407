import Cookies from 'universal-cookie'
import {AxiosPostSender} from "./helpers/constants/AxiosPostSender";
import {HOST} from "./helpers/constants/HeaderConstants";

const cookies = new Cookies()

const oauthCookieName = 'oauth_center'
const getOauthCookie = () => cookies.get(oauthCookieName)

// const refreshUrl = "http://moidom1.citylink.pro/api/?mode=refreshAuthUserToken"
const refreshUrl = HOST + "/api/?mode=refreshAuthUserToken"

/**
 * Воркер авторизации
 */
export function setAuthWorker() {
    // получаем значение авторизации
    const token = getOauthCookie()

    // нет - ничего не делаем
    // ждём пока юзер захочет авторизоваться
    if (token === undefined) {
        return
    }

    // обновляем токен
    sendRefresh(token)

    // вешаем обновлялку токена
    // запуск каждые 4 минуты
    window.authWorkerTimeout = setInterval(() => {
            sendRefresh()
        },
        4 * 60 * 1000
    );
}

/**
 * Рефреш токена
 *
 * @param token
 */
function sendRefresh(token = null) {
    if (!token) {
        token = getOauthCookie()
    }

    // есть - рефрешим токен
    // шлём запросы на проверку и обновление
    AxiosPostSender(
        refreshUrl,
        {
            token: token
        },
        (response) => {
            if (response.data.error !== undefined) {
                if (response.data.error === "Токен актуален") {
                    return;
                }

                throw Error(response.data.error);
            }

            if (response.data['token'] === undefined) {
                return;
            }

            // удаляем старую куку
            cookies.remove(oauthCookieName)

            const expire = new Date(response.data['expire'] * 1000)

            // обновляем токен в куке
            cookies.set(
                oauthCookieName,
                response.data['token'],
                {
                    path: '/',
                    expires: expire,
                    sameSite: 'lax',
                    domain: '',
                    secure: false,
                    httpOnly: false
                }
            )
        },
        (errorMessage) => {
            throw Error(errorMessage)
        }
    )
}


