import React from 'react'
import MetaTags from "react-meta-tags";

const CameraPublicWithPromoTags = (props) => {
    const {street, city1, city2, cameraID} = props;

    let canonical = `https://moidom.citylink.pro/pub/${cameraID}`;
    let content
    
    switch (city1) {
        case 'Петрозаводска':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Мурманска':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Кондопоги':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Медвежьегорска':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Сортавалы':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Костомукши':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Питкяранты':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Суоярви':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Лахденпохье':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Апатитов':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Сегежи':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Надвоиц':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Карелии.`
            break

        case 'Северодвинска':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Полярных Зорей':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Оленегорска':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Архангельска':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Архангельской области.`
            break

        case 'Санкт-Петербурга':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Ленинградской области.`
            break

        case 'Саранска':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мордовии.`
            break

        case 'Кандалакши':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

        case 'Красного села':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Ленинградской области.`
            break

        case 'Мги':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Ленинградской области.`
            break

        case 'Тихвина':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Ленинградской области.`
            break

        case 'Надыма':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и в Ямало-Ненецком автономном округе.`
            break

        case 'Пангоды':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и в Ямало-Ненецком автономном округе.`
            break

        case 'Заполярного':
            content = `${street} — смотрите видео с камеры онлайн и скачивайте архив записей с камер видеонаблюдения в ${city2} и Мурманской области.`
            break

    }

    return (
        <MetaTags>
            <title>{street} - Смотреть бесплатные камеры {city1} онлайн</title>
            <meta name="description"
                  content={content}
            />
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default CameraPublicWithPromoTags;


