import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from './store/configureStore';
import PropTypes from 'prop-types';
import Root from './routes';
import {unregister} from './registerServiceWorker';
import {setAuthWorker} from './authWorker';
import MoidomWebApi from "./lib/MoidomWebApi";
import Cookies from "universal-cookie/lib";

// продление авторизации oauth
//setAuthWorker();

const store = configureStore();
unregister();

export const mdApi = new MoidomWebApi(process.env.REACT_APP_APP_HOST + process.env.REACT_APP_APP_URL_PREFIX, process.env.REACT_APP_APP_API_URL)
export const cookies = new Cookies()


mdApi.checkSession()
    .finally(() => {
        ReactDOM.render(
            <Provider store={store}>
                <Root/>
            </Provider>,
            document.getElementById("root")
        );
    })
    .catch(()=>{})
    .then(()=>{})

Provider.propTypes = {
    store: PropTypes.object.isRequired
}