import React from 'react'
import MetaTags from "react-meta-tags";

const TrubaListTags = (props) => {
    const {category, titleProvider} = props;
    let title = `Видео с камер Петрозаводска и Карелии бесплатно | Мой дом Ситилинк`;
    let content = "Смотрите интересные видео с уличных камер города бесплатно и делитесь в соцсетях.";
    switch (category) {
        case 'dtp':
            title = "Видео с ДТП и авариями | «Мой дом Ситилинк»";
            content = "Смотрите интересные видео ДТП и аварий с уличных камер города бесплатно.";
            break;
        case 'dtpin':
            title = "Видео с ДТП и авариями во дворах | «Мой дом Ситилинк»";
            content = "Смотрите интересные видео ДТП и аварий во дворах с уличных камер города бесплатно.";
            break;
        case 'dtpout':
            title = "Видео с ДТП и авариями на перекрёстках | «Мой дом Ситилинк»";
            content = "Смотрите интересные видео ДТП и аварий на перекрёстках и пешеходных переходах с уличных камер города.";
            break;
        case 'vandal':
            title = "Видео вандализма, хулиганства, снятые на камеры «Мой дом Ситилинк»";
            content = "Смотрите интересные видео вандализма, хулиганства с уличных камер города.";
            break;
        case 'fight':
            title = "Видео драк, разборок на дорогах, снятые на камеры «Мой дом Ситилинк»";
            content = "Смотрите интересные видео драк с уличных камер города.";
            break;
        case 'theft':
            title = "Видео краж, угонов, воровства, снятые на камеры «Мой дом Ситилинк»";
            content = "Смотрите интересные видео краж, угонов, воровства с уличных камер города.";
            break;
        case 'other':
            title = "Интересные видео снятые на камеры «Мой дом Ситилинк»";
            content = "Видео с уличных камер города. Смотрите онлайн бесплатно.";
            break;
    }

    let canonical = `https://moidom.citylink.pro/videos`;

    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content={content}/>
            }
            <meta property="og:title" content="Мой Дом"/>
            <link rel="canonical" href={canonical} />
        </MetaTags>
    )
}

export default TrubaListTags;