import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {itemsFetchData, itemsUpdateData, GET_TRUBA_URL} from '../actions/items';
import {countersFetchData, GET_VIDEOS_COUNTERS} from '../actions/counters';
import {BROWSER, currentProviderTitleFormatted, MOBILE} from '../helpers/constants/HeaderConstants';

import '../sass/Tools.css';
import TrubaListTags from "./Header/Metatags/TrubaListTags";

const isActivePage = (path) => {
    return window.location.pathname.match(path);
}

class TrubaList extends Component {
    componentDidMount() {
        let cameraId = this.props.match.params.cameraId;

        window.page = 2;

        const _this = this;
        let tag = _this.props.match.params.category;

        let allowed = ['dtp', 'dtpin', 'dtpout', 'vandal', 'theft', 'fight', 'other'];
        if (tag && allowed.indexOf(tag) === -1) {
            _this.props.history.push('/404');
            return;
        }

        this.props.fetchData(GET_TRUBA_URL + (tag ? '&tag=' + tag : '') + (cameraId !== undefined ? '&camera=' + cameraId : ''));
        this.props.fetchCounters(GET_VIDEOS_COUNTERS);

        let timer;

        window.onscroll = function () {

            if (!document.getElementsByClassName('truba-wrapper').length)
                return;

            let scrollHeight, totalHeight;
            scrollHeight = document.body.scrollHeight - 100;
            totalHeight = window.scrollY + window.innerHeight;

            let pageCounter = 12;

            switch (tag) {
                case 'dtp':
                    pageCounter = _this.props.counters.dtp;
                    break;
                case 'dtpin':
                    pageCounter = _this.props.counters.dtpin;
                    break;
                case 'dtpout':
                    pageCounter = _this.props.counters.dtpout;
                    break;
                case 'vandal':
                    pageCounter = _this.props.counters.vandal;
                    break;
                case 'fight':
                    pageCounter = _this.props.counters.fight;
                    break;
                case 'theft':
                    pageCounter = _this.props.counters.theft;
                    break;
                case 'other':
                    pageCounter = _this.props.counters.other;
                    break;
                default:
                    pageCounter = _this.props.counters.all;
            }

            if (totalHeight >= scrollHeight) {
                if (window.page <= Math.ceil(pageCounter / 12) && _this.props.match.params.cameraId === undefined) {
                    if (timer) clearTimeout(timer);
                    timer = setTimeout(() => {
                        _this.props.updateData(GET_TRUBA_URL + '&page=' + window.page + (_this.props.match.params.category ? '&tag=' + _this.props.match.params.category : ''));
                        ++window.page;
                    }, 1000);


                }
            }

            if (window.scrollY > 500)
                _this.setState({
                    'scroll': true,
                });
            else
                _this.setState({
                    'scroll': false,
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.category !== this.props.match.params.category) {
            this.props.fetchData(GET_TRUBA_URL + (this.props.match.params.category ? '&tag=' + this.props.match.params.category : ''));
            window.page = 2;
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            scroll: false,
        }
    }

    handleUpList = () => {
        window.scrollTo(0, 0);
        return;

    }

    render() {
        if (this.props.region !== 'citylink' && false) {
            this.props.history.push('/');
        }

        if (MOBILE && !this.props.match.path.match('public/videos'))
            this.props.history.push('/');

        let items = [];

        if (this.props.items.length !== 0) {// eslint-disable-next-line
            Object.keys(this.props.items).reverse().map((item) => {
                items.push(<li className="items-grid__item" key={item}>
                    <Link className="items-grid__item_link" to={`/video/${item}`}>
                        <img src={this.props.items[item].img} alt={this.props.items[item].name}/>
                        <span className="items-name">{this.props.items[item].name}</span>
                        <span className="items-date">{this.props.items[item].date}</span>
                        <span className="items-views">{this.props.items[item].views}</span>
                    </Link>
                </li>);
            });
        }
        let linker = [];

        if (this.props.match.params.category) {
            if (this.props.match.params.category.match('dtp'))
                linker.push(<div className="content-dtp__linker" key={"dtp"}>
                    <NavLink to="/videos/dtp">Все</NavLink>
                    <NavLink to="/videos/dtpin">ДТП во дворах
                        <span>{this.props.counters.dtpin}</span>
                    </NavLink>
                    <NavLink to="/videos/dtpout">ДТП на перекрестках
                        <span>{this.props.counters.dtpout}</span>
                    </NavLink>
                    <Link to="/pdd">Разбор ДТП
                        <span>{this.props.counters.pdd}</span>
                    </Link>
                </div>);
        }

        return (
            <div>
                <TrubaListTags category={this.props.match.params.category}  titleProvider={currentProviderTitleFormatted()}/>
                {BROWSER &&
                <div className="content-wrapper wide">
                    <div className="content-truba_tool">
                        {this.state.scroll && <div className="upper" onClick={this.handleUpList}></div>}
                        <div className="content-text">
                            <div className="content-text__upper">
                                Здесь хранятся видеозаписи ДТП, инцидентов и правонарушений, попавших в поле зрения
                                камер проекта «Мой дом».
                            </div>
                            <div className="content-text__bottom">
                                <NavLink exact to="/videos">Все</NavLink>
                                <NavLink to="/videos/dtp" isActive={() => isActivePage('dtp')}>ДТП
                                    <span>{this.props.counters.dtp}</span>
                                </NavLink>
                                <NavLink to="/videos/vandal">Вандализм
                                    <span>{this.props.counters.vandal}</span>
                                </NavLink>
                                <NavLink to="/videos/theft">Кражи
                                    <span>{this.props.counters.theft}</span>
                                </NavLink>
                                <NavLink to="/videos/fight">Драки
                                    <span>{this.props.counters.fight}</span>
                                </NavLink>
                                <NavLink to="/videos/other">Прочее
                                    <span>{this.props.counters.other}</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {linker}
                    <div className="truba-wrapper">
                        {this.props.items.length !== 0 &&
                        <ul className="items-grid tools">
                            {items}
                        </ul>}
                        {this.props.isLoading && <div className="loading">Загрузка</div>}
                        {this.props.hasErrored && <div className="error">Ошибка загрузки происшествий</div>}
                    </div>
                </div>
                }
                {MOBILE &&
                <div className="mobile-wrapper">
                    {this.props.isLoading && <div className="loading">Загрузка</div>}
                    {this.props.hasErrored && <div className="error">Ошибка загрузки камер</div>}
                    {this.props.items.length !== 0 &&
                    <ul className="items-grid">
                        {items}
                    </ul>}
                </div>}
            </div>
        );
    }
}

TrubaList.propTypes = {
    fetchData: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    fetchCounters: PropTypes.func.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    region: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        items: state.items,
        counters: state.counters,
        hasErrored: state.itemsHasErrored,
        isLoading: state.itemsIsLoading,
        region: state.region
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (url) => {
            dispatch(itemsFetchData(url))
        },
        fetchCounters: (url) => {
            dispatch(countersFetchData(url))
        },
        updateData: (url) => {
            dispatch(itemsUpdateData(url))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrubaList);
