import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { MOBILE } from '../helpers/constants/HeaderConstants';
import {MetaTags} from "react-meta-tags";

import '../sass/Header.css';
import '../sass/NotFound.css';

class NotFound extends PureComponent {
  render() {
      return (
          <div>
              <MetaTags>
                  <title>404 Not Found</title>
                  <meta name="robots" content="noindex"/>
              </MetaTags>
              <div className="content-wrapper">
                  <div className="error-page page-404">
                      <Link to="/">На главную</Link>
                  </div>
              </div>
          </div>
      );
  }
}

export default NotFound;
