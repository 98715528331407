import React, {Component} from 'react'
import {Link, NavLink} from "react-router-dom";
import {
    code,
} from "../../helpers/constants/HeaderConstants";
import {MobileCitylink} from './Citylink/MobileCitylink'
import {AppContext, rolesToPermissions, USER_CAMERAS_PATH} from "../../routes";
import {mdApi} from "../../index";
import {PASSPORT_AUTH_URL} from "./BrowserContainer";

// import {Route} from "react-router";

export class MobileContainer extends Component {
    constructor(props) {
        super(props);

        MobileContainer.contextType = AppContext
    }

    redirectToPassportAuth = () => {
        window.location.href = PASSPORT_AUTH_URL
    }

    render() {
        let isAuthorised = this.props.isAuthorised
        const isEnter = this.props.isEnter
        let userName = this.props.userName === undefined ? '' : this.props.userName.slice(0, 13) + '...'
        const handleEnterList = this.props.handleEnterList
        const handleExitBtn = this.props.handleExitBtn

        let {superUser, admin, seo, any} = rolesToPermissions(mdApi.userRoles)

        return (
            <div>
                <header className="mobile-header">
                    <div className="mobile-header_top">
                        <ul className="links links-left">
                            <li className="list__item">
                                <NavLink to='/faq'>FAQ</NavLink>
                            </li>
                        </ul>
                        <ul className="links links-right">
                            {
                                isAuthorised &&
                                <>
                                    <li className="list__item name">
                                        <a href=' #'>{userName}</a>
                                    </li>
                                    <li className="list__item entrance">
                                        <a href="# "
                                           onClick={() => handleExitBtn()}>(Выход)</a>
                                    </li>
                                </>
                            }
                            {
                                !isAuthorised &&
                                <li className="list__item entrance">
                                    <a className="auth" href="# " onClick={() => handleEnterList()}>Вход</a>
                                    {
                                        isEnter &&
                                        <div className="enter-list">
                                            <a className="enter-list_item" href="# " onClick={this.redirectToPassportAuth}>По логину</a>
                                            <Link className="enter-list_item"
                                                  to='/code'>По
                                                коду
                                                доступа</Link>
                                        </div>
                                    }
                                </li>
                            }
                        </ul>
                    </div>

                    <div className="mobile-header-logo">
                        <Link to="/" className="logo-link"></Link>
                    </div>
                    <MobileCitylink
                        displayMobileMenu={this.props.displayMobileMenu}
                        activePageKey={this.props.activePageKey}
                        pathname={window.location.pathname}
                        handleToggleDisplayBurgerMenu={this.props.handleToggleDisplayBurgerMenu}
                        handleBurgerMenuClick={this.props.handleBurgerMenuClick}
                        checkActivePage={this.props.checkActivePage}
                        isAuthorised={this.props.isAuthorised}
                    />
                </header>
            </div>
        )
    }
}

export default MobileContainer;


