import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FacebookShareButton, GooglePlusShareButton, TwitterShareButton, VKShareButton, OKShareButton, MailruShareButton } from 'react-share';
import { FacebookIcon, GooglePlusIcon, TwitterIcon, VKIcon, OKIcon, MailruIcon } from 'react-share';
import axios from 'axios';

import { itemsFetchData, GET_TRUBA_URL, GET_SHARE_LINK } from '../actions/items';
import VideoPlayer from '../components/VideoPlayer';
import ErrorBlock from '../components/ShareVideo/ErrorBlock';
import { BROWSER, MOBILE } from '../helpers/constants/HeaderConstants';

class ShareVideo extends PureComponent {
    componentDidMount() {
        let trubaId = this.props.match.params.trubaId;

        axios.get(GET_SHARE_LINK + '&share=' + trubaId)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                this.setState({
                    myItems: response.data,
                });
            });
        this.props.fetchData(GET_TRUBA_URL);
    }

    componentDidUpdate(prevProps) {
        if (this.props.items !== prevProps.items)
            this.generateRandom(this.props.match.params.trubaId);

        if (this.props.match.params.trubaId !== prevProps.match.params.trubaId)
            this.generateRandom(this.props.match.params.trubaId);
    }

    constructor(props) {
        super(props);

        this.state = {
            isShare: false,
            myItems: [],
            randomItems: [],
        }
    }


    handleShare = () => {
        this.setState({ isShare: !this.state.isShare });
    }

    generateKey = (pre) => {
        return `truba_${pre}`;
    }

    generateRandom = (id) => {
        let _items = this.props.items

        let randomProperty = (obj) => {
            let keys = Object.keys(obj);
            return keys[keys.length * Math.random() << 0];
        };

        let randomItems = [];
        let length = Object.keys(_items).length;

        if (length) {
            length = length > 6 ? 6 : length - 1;

            let i = 0;
            while (i < length - 1) {
                let _randomItem = randomProperty(_items);

                if (_randomItem !== id && randomItems.indexOf(_randomItem) < 0) {
                    randomItems.push(_randomItem);
                    i++;
                }
            }
        }

        let _randomItems = [];

        randomItems.forEach((item) => {
            _randomItems.push(<li className="items-grid__item" key={item}>
                <Link onClick={this.handleOther} className="items-grid__item_link" to={`/video/${item}`}>
                    <img src={this.props.items[item].img} alt={this.props.items[item].name} />
                    <span className="items-name truba-mode">{this.props.items[item].name.replace('&#171;', '"').replace('&#187;', '"')}</span>
                </Link>
            </li>);
        });

        this.setState({ randomItems: _randomItems });
    }

    render() {
        const id = this.props.match.params.trubaId;
        const shareUrl = 'https://' + window.location.hostname + '/share/' + id;

        let _items = this.props.items;
        _items = this.state.myItems;

        return (
            <div>
                {
                    BROWSER &&
                    <div className="content-wrapper">
                        {
                            _items === -1 ? (
                                <ErrorBlock />
                            )
                                :
                                (
                                    <div className={this.state.randomItems.length === 0 ? "video-player single" : "video-player"}>
                                        {
                                            id && _items[id] !== undefined &&
                                            <VideoPlayer
                                                width={'770'}
                                                height={'433'}
                                                source={_items[id].src}
                                                audio={false}
                                                poster={_items[id].img}
                                                type={_items[id].type}
                                                isArchive={true}
                                            />
                                        }
                                        {
                                            id && _items[id] !== undefined && (
                                                <div className="video-player-info">
                                                    <span className="video-player_name">{_items[id].name}</span>
                                                    <div className="video-player-counters">
                                                        <span className="video-player_date">{_items[id].date}</span>
                                                        <span className="video-player_views">{_items[id].views}</span>
                                                    </div>
                                                    <div className="video-player-links">
                                                        {_items[id].download && <a className="video-player_link" href={_items[id].download}>Скачать</a>}
                                                        {!_items[id].download && <span className="video-player_link">Видео обрабатывается...</span>}
                                                        {_items[id].download && <a className="video-player_link" href="# " onClick={() => this.handleShare()}>Поделиться</a>}

                                                        {this.state.isShare &&
                                                            <div className="social-wrapper">
                                                                <VKShareButton
                                                                    url={shareUrl}
                                                                    title={_items[id].name}
                                                                    image={_items[id].img}
                                                                    windowWidth={660}
                                                                    windowHeight={460}
                                                                    className="social-wrapper__item">
                                                                    <VKIcon
                                                                        size={32}
                                                                        round />
                                                                </VKShareButton>
                                                                <FacebookShareButton
                                                                    url={shareUrl}
                                                                    quote={_items[id].name}
                                                                    className="social-wrapper__item">
                                                                    <FacebookIcon
                                                                        size={32}
                                                                        round />
                                                                </FacebookShareButton>
                                                                <TwitterShareButton
                                                                    url={shareUrl}
                                                                    title={_items[id].name}
                                                                    className="social-wrapper__item">
                                                                    <TwitterIcon
                                                                        size={32}
                                                                        round />
                                                                </TwitterShareButton>
                                                                <GooglePlusShareButton
                                                                    url={shareUrl}
                                                                    className="social-wrapper__item">
                                                                    <GooglePlusIcon
                                                                        size={32}
                                                                        round />
                                                                </GooglePlusShareButton>
                                                                <OKShareButton
                                                                    url={shareUrl}
                                                                    title={_items[id].name}
                                                                    image={_items[id].img}
                                                                    windowWidth={660}
                                                                    windowHeight={460}
                                                                    className="social-wrapper__item">
                                                                    <OKIcon
                                                                        size={32}
                                                                        round />
                                                                </OKShareButton>
                                                                <MailruShareButton
                                                                    url={shareUrl}
                                                                    title={_items[id].name}
                                                                    className="social-wrapper__item">
                                                                    <MailruIcon
                                                                        size={32}
                                                                        round />
                                                                </MailruShareButton>
                                                            </div>}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                        }
                        {this.props.items.length !== 0 &&
                            <ul className="items-grid right-column">
                                {this.state.randomItems}
                            </ul>}
                    </div>
                }
                {
                    MOBILE &&
                    <div className="mobile-wrapper">
                        {
                            _items === -1 ? (
                                <ErrorBlock />
                            )
                            :
                            (
                                <div className="mobile-video-player">
                                    {
                                    id && _items[id] !== undefined &&
                                        <VideoPlayer width={'350'}
                                            height={'197'}
                                            source={_items[id].src}
                                            audio={_items[id].audio}
                                            poster={_items[id].img}
                                            type={_items[id].type}
                                            isArchive={false} />
                                    }
                                    {
                                    id && _items[id] !== undefined && (
                                        <div className="video-player-info">
                                            <span className="video-player_name">{_items[id].name}</span>
                                            <div className="video-player-counters">
                                                <span className="video-player_date">{_items[id].date}</span>
                                                <span className="video-player_views">{_items[id].views}</span>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                            )
                        }
                    </div>
                }
            </div>
        );
    }
}

ShareVideo.propTypes = {
    fetchData: PropTypes.func.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        items: state.items,
        hasErrored: state.itemsHasErrored,
        isLoading: state.itemsIsLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (url) => { dispatch(itemsFetchData(url)) }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareVideo));
