import axios from 'axios';
import {HOST_TO_OLD_API} from "../helpers/constants/HeaderConstants";

export const ITEMS_FETCH_COUNTERS_SUCCESS = 'ITEMS_FETCH_COUNTERS_SUCCESS';
export const GET_VIDEOS_COUNTERS = HOST_TO_OLD_API + '/api/?mode=getVideosCounters';
export const GET_PUBLIC_COUNTERS = HOST_TO_OLD_API + '/api/?mode=getPublicCounters';

export function countersFetchSuccess(counters) {
    return {
        type: ITEMS_FETCH_COUNTERS_SUCCESS,
        counters
    };
}

export function countersFetchData(url) {
    return (dispatch) => {
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.data)
            .then((counters) => dispatch(countersFetchSuccess(counters)));
    };
}
