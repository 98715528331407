import {matchPath} from "react-router";

/**
 * Проверка активного экнеша (для подсветки активного пункта меню)
 *
 * @param path
 * @returns {boolean|RegExpMatchArray}
 */
export const isActivePage = (path) => {
  const cities = ["/", "/kg", "/mg", "/st", "/ks", "/ptk", "/suo", "/ol", "/pz", "/ldh", "/sg", "/ndv", "/mrsk", "/sd", "/ap", "/pz", "/arh"];

  let match;

  //Required for "public cameras" tab link correct activation

  match = matchPath(window.location.pathname, {
    path: path,
    exact: false,
    strict: false
  })

  if (path === "pub") {
    if (cities.includes(window.location.pathname)) {
      return true;
    } else {
      match = matchPath(window.location.pathname, {
        path: "/(pub?)(.*)",
        exact: false,
        strict: false
      })
    }
  }

  return match !== null;
}
