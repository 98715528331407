import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';

import {fetchData as fetch, GET_QUICK_ACCESS_CODE_FOR_UR_DATA} from '../actions/quickAccessForUr';
import VideoPlayer from '../components/VideoPlayer';
import {BROWSER, MOBILE} from '../helpers/constants/HeaderConstants';

import '../sass/QuickAccessForUr.css'

/**
 * Быстрый доступ для юр лиц по коду
 */
class QuickAccessForUr extends PureComponent {
    componentDidMount() {
        const codeUrl = this.props.match.params.codeUrl;

        if (this.props.quickAccessUrl.length === 0 && codeUrl !== '') {
            this.props.fetchData(GET_QUICK_ACCESS_CODE_FOR_UR_DATA + '&url=' + codeUrl);
        }
    }

    render() {
        let itemsList = []
        let name = ''

        if (this.props.quickAccessUrl.name) {
            name = this.props.quickAccessUrl.name.trim()
        }

        if (this.props.quickAccessUrl.cams && this.props.quickAccessUrl.length !== 0) {
            itemsList = Object.keys(this.props.quickAccessUrl.cams).map((id) => {
                const item = this.props.quickAccessUrl.cams[id]

                return <div className={MOBILE ? 'mobile-video-player' : 'video-player'}
                            key={"item-" + item.id}>
                    <VideoPlayer
                        id={'camera' + id}
                        width={MOBILE ? '350' : '770'}
                        height={MOBILE ? '197' : '433'}
                        source={item.src}
                        audio={item.audio}
                        poster={item.img}
                        type={'application/x-mpegURL'}
                        isArchive={true}
                        notAutoPlay={true}
                    />
                </div>
            });
        }

        return (
            <div>
                {
                    BROWSER &&
                    <div className="content-wrapper quick-access-for-ur">
                        <div className="header">{name}</div>

                        <div className="list">
                            {itemsList}
                        </div>
                    </div>
                }
                {
                    MOBILE &&
                    <div className="mobile-wrapper quick-access-for-ur">
                        <div className="header">{name}</div>

                        <div className="list">
                            {itemsList}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

QuickAccessForUr.propTypes = {
    fetchData: PropTypes.func.isRequired,
    quickAccessUrlHasErrored: PropTypes.bool.isRequired,
    quickAccessUrlIsLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        quickAccessUrl: state.quickAccessUrl,
        quickAccessUrlHasErrored: state.quickAccessUrlHasErrored,
        quickAccessUrlIsLoading: state.quickAccessUrlIsLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (url) => {
            dispatch(fetch(url))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickAccessForUr));
