import {GET_REGION_FETCH_SUCCESS} from '../actions/region';

/**
 * Редюсер региона Ip
 *
 * @param state string default = 'none'
 * @param action
 * @returns {{set: function(*): void, enumerable: boolean, get: function(): *}|{set: function(*): void, enumerable: boolean, get: function(): *}|string|VTTRegion|{set: function(*): void, enumerable, get: function(): *}}
 */
export function region(state = 'none', action) {
    switch (action.type) {
        case GET_REGION_FETCH_SUCCESS:
            return action.region;

        default:
            return state;
    }
}
