import axios from 'axios';
import {HOST_TO_OLD_API} from "../helpers/constants/HeaderConstants";

export const POINTS_FETCH_DATA_SUCCESS = 'POINTS_FETCH_DATA_SUCCESS';
export const POINTS_UPDATE_DATA_SUCCESS = 'POINTS_UPDATE_DATA_SUCCESS';
export const POINTS_IS_LOADING = 'POINTS_IS_LOADING';
export const POINTS_HAS_ERRORED = 'POINTS_HAS_ERRORED';
export const GET_PUBLIC_URL = HOST_TO_OLD_API + '/api/?mode=getPublicCams';
export const GET_SENSORS_URL = 'https://moidom.karelia.pro/razor_test/api.php?mode=list';
export const GET_SENSOR_DATA_URL = 'https://moidom.karelia.pro/razor_test/api.php?mode=one&id=';

export function pointsHasErrored(bool) {
    return {
        type: POINTS_HAS_ERRORED,
        hasErrored: bool
    };
}

export function pointsIsLoading(bool) {
    return {
        type: POINTS_IS_LOADING,
        isLoading: bool
    };
}

export function pointsFetchDataSuccess(points) {
    return {
        type: POINTS_FETCH_DATA_SUCCESS,
        points
    };
}


export function pointsUpdateDataSuccess(points) {
    return {
        type: POINTS_UPDATE_DATA_SUCCESS,
        points
    };
}

export function pointsFetchData(url) {
    return (dispatch) => {
        dispatch(pointsIsLoading(true));
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(pointsIsLoading(false));

                return response;
            })
            .then((response) => response.data)
            .then((points) => dispatch(pointsFetchDataSuccess(points)))
            .catch(() => dispatch(pointsHasErrored(true)));
    };
}


export function pointsUpdateData(url) {
    return (dispatch) => {
        dispatch(pointsIsLoading(true));
        axios.get(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }
                dispatch(pointsIsLoading(false));

                return response;
            })
            .then((response) => response.data)
            .then((points) => dispatch(pointsUpdateDataSuccess(points)))
            .catch(() => dispatch(pointsHasErrored(true)));
    };
}
