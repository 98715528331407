import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'

import {itemsFetchData} from '../actions/items'
import {regionFetchData} from '../actions/region'
import {isActivePage} from '../helpers/headerActivePage'
import BrowserContainer, {PASSPORT_LOGOUT_URL} from './Header/BrowserContainer'
import MobileContainer from './Header/MobileContainer'
import {
    BROWSER,
    MOBILE,
    HOST,
} from '../helpers/constants/HeaderConstants'

import '../sass/Header.css'
import {cookies, mdApi} from "../index";
import MoidomWebApi from "../lib/MoidomWebApi";
import {AppContext} from "../routes";

require('typeface-roboto')
require('typeface-roboto-condensed')

export const SESSION_UPDATED = "session.updated";
export const SESSION_CLEARED = "session.cleared";

class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isEnter: false,
            displayMobileMenu: false,
            isAuthorized: false,
            userName: '',
            activePageKey: '',
            userLevel: '',
            showProjectInAction: true,
            error: ''
        }

        this.handleEnterList = this.handleEnterList.bind(this)
        this.handleToggleDisplayBurgerMenu = this.handleToggleDisplayBurgerMenu.bind(this)
        this.handleBurgerMenuClick = this.handleBurgerMenuClick.bind(this)
        this.checkActivePage = this.checkActivePage.bind(this)
        this.handleExitBtn = this.handleExitBtn.bind(this)
        this.getError = this.getError.bind(this)

        Header.contextType = AppContext
    }

    componentDidMount() {
        mdApi.onSessionUpdate = this.updateSessionInfo
        mdApi.onSessionClear = this.clearSessionInfo

        mdApi.checkSession()
            .catch(() => {
                //Это не требуется поскольку cookie не доступны из под JS(выставлен флаг COOKIE_HTTP_ONLY)
                // if (cookies.get(MoidomWebApi.COOKIE_REFRESH_NAME) !== undefined) {
                //     mdApi.makeSessionRefresh()
                //         .then()
                //         .catch()
                // }
            })
    }

    clearSessionInfo = () => {
        this.setState({
            isAuthorized: false,
            userName: undefined,
            userLevel: undefined
        }, () => {
            const ctx = this.context
            ctx.dispatchEvent(SESSION_CLEARED)
        })
    }

    updateSessionInfo = session => {
        this.setState({
            isAuthorized: true,
            userName: session.name,
            userLevel: 0
        },()=>{
            const ctx = this.context
            ctx.dispatchEvent(SESSION_UPDATED)
        })
    }

    /**
     * Получаем текст ошибки авторизации
     */
    getError() {
        // смотрим наличие ошибки в гете
        const params = window
            .location
            .search
            .replace('?', '')
            .split('&')
            .reduce(
                (p, e) => {
                    const a = e.split('=');
                    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                    return p;
                },
                {}
            );

        if (params['error'] !== undefined) {
            this.setState({
                error: params['error']
            })
        } else {
            this.setState({
                error: ''
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.location.search !== this.props.location.search) {
        //     this.getError()
        // }
    }

    handleEnterList = () => {
        this.setState({
            isEnter: true,
        })

        let _this = this

        setTimeout(function () {
            _this.setState({
                isEnter: false,
            })
        }, 10000)
    }

    /**
     * Toggle меню при нажатии
     */
    handleToggleDisplayBurgerMenu = () => {
        this.setState({displayMobileMenu: !this.state.displayMobileMenu})
    }

    /**
     * Скрытие меню, смена урл
     *
     * @param item
     */
    handleBurgerMenuClick(item) {
        this.setState({
            displayMobileMenu: false
        })

        // если авторизован - может понадобиться переход в админку
        const url = this.state.isAuthorized ? item.urlForAdmin : item.url

        if (url.indexOf('admin') === -1) {
            this.props.history.push(url)
            return
        }

        window.location.replace(HOST + url)
    }

    /**
     * Проверка активной страницы из списка
     *
     * @param paths array of string
     */
    checkActivePagesList(paths) {
        // проверить, если массив - перебрать иначе как обычно
        // paths.map((path, i) => {
        // })
    }

    /**
     * Сохраняем ключ активной страницы
     *
     * @param path
     */
    checkActivePage(path) {
        return isActivePage(path)
    }

    /**
     * Выход
     */
    handleExitBtn() {
        return mdApi.makeLogout()
            .then(() => {
                location.href = PASSPORT_LOGOUT_URL
            })
            .catch()
        // const oauthCenter = cookies.get(OAUTH_COOKIE_NAME)
        //
        // // прекращаем авторизацию
        // if (oauthCenter) {
        //     clearInterval(window.authWorkerTimeout)
        //
        //     window.location.replace(OAUTH_EXIT_URL)
        // }
    }

    render() {
        return (
            <div>
                {/*{browserName === 'Safari' && osName === 'Linux' && fullBrowserVersion <= 5 && <CheckBrowserVersion/>}*/}
                {/*{*/}
                {/*    this.state.error !== '' &&*/}
                {/*    <NotificationBlock*/}
                {/*        showBlock={this.state.error !== ''}*/}
                {/*        errorMessage={this.state.error}*/}
                {/*    />*/}
                {/*}*/}
                {
                    BROWSER &&
                    <BrowserContainer
                        isAuthorised={this.state.isAuthorized}
                        isEnter={this.state.isEnter}
                        userLevel={this.state.userLevel}
                        userName={this.state.userName}
                        checkActivePage={this.checkActivePage}
                        handleEnterList={this.handleEnterList}
                        handleExitBtn={this.handleExitBtn}
                        // showProjectInAction={this.props.region === 'citylink'}
                        showProjectInAction={true}
                        hasItems={Object.keys(this.props.items).length > 0}
                    />
                }
                {
                    MOBILE &&
                    <MobileContainer
                        isAuthorised={this.state.isAuthorized}
                        isEnter={this.state.isEnter}
                        userLevel={this.state.userLevel}
                        userName={this.state.userName}
                        displayMobileMenu={this.state.displayMobileMenu}
                        activePageKey={this.state.activePageKey}
                        handleEnterList={this.handleEnterList}
                        handleExitBtn={this.handleExitBtn}
                        handleToggleDisplayBurgerMenu={this.handleToggleDisplayBurgerMenu}
                        handleBurgerMenuClick={this.handleBurgerMenuClick}
                        checkActivePage={this.checkActivePage}
                    />
                }
            </div>
        )
    }
}

Header.propTypes = {
    fetchData: PropTypes.func.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    region: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    return {
        items: state.items,
        hasErrored: state.itemsHasErrored,
        isLoading: state.itemsIsLoading,
        region: state.region
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (url) => {
            dispatch(itemsFetchData(url))
        },
        getRegion: (url) => {
            dispatch(regionFetchData(url))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
