import React from 'react'
import MetaTags from "react-meta-tags";

const ArchiveScreenTags = (props) => {
    const  {cameraTitle} = props;

    let title = `${cameraTitle} - Сделать фото с камеры видеонаблюдения`;
    let content = `${cameraTitle}. Сделайте снимок с камер видеонаблюдения за последние 10 дней.`;

    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description"
                  content={content}/>
            <meta property="og:title" content="Мой Дом"/>
        </MetaTags>
    )
}

export default ArchiveScreenTags;