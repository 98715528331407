/**
 * Экшен для значения поисковой строки списка публичных камер
 * @type {string}
 */
export const PUBLIC_CAMS_SEARCH_FETCH_DATA = 'PUBLIC_CAMS_SEARCH_FETCH_DATA'
export const PUBLIC_CAMS_SEARCH_UPDATE_DATA = 'PUBLIC_CAMS_SEARCH_UPDATE_DATA'

/**
 * Экшен получения значения поисковой строки списка публичных камер
 *
 * @returns {function(...[*]=)}
 */
export function searchValueFetchData () {
  return (dispatch) => {
    dispatch(searchValueFetchDataSuccess())
  }
}

/**
 * Генератор экшена получения значения поисковой строки списка публичных камер
 */
export function searchValueFetchDataSuccess () {
  return {
    type: PUBLIC_CAMS_SEARCH_FETCH_DATA
  }
}

/**
 * Экшен обновления значения поисковой строки списка публичных камер
 *
 * @param value
 * @returns {function(...[*]=)}
 */
export function searchValueUpdateData (value) {
  return (dispatch) => {
    dispatch(searchValueUpdateDataSuccess(value))
  }
}

/**
 * Генератор экшена обновления значения поисковой строки списка публичных камер
 *
 * @param searchValue
 * @returns {{searchValue: *, type: string}}
 */
export function searchValueUpdateDataSuccess (searchValue) {
  return {
    type: PUBLIC_CAMS_SEARCH_UPDATE_DATA,
    searchValue
  }
}
